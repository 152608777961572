import { UILayout } from 'bora-material-ui'
import React from 'react'

class TicketRowInfoItemContainer extends React.Component {
  render() {
    const { children, isFirst, dataTestId } = this.props

    return (
      <UILayout
        width={isFirst && '100%'}
        minWidth={isFirst ? 'auto' : '70px'}
        j-flex-center={!isFirst}
        center
        margin="0 0 10px 0"
        data-testid={dataTestId}
      >
        {children}
      </UILayout>
    )
  }
}

export default TicketRowInfoItemContainer
