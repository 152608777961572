import React from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIIcon, UIButton } from 'bora-material-ui'

import GeneralLabel from './GeneralLabel'
import { THEME } from 'bora-material-ui/themes'

const xsStyles = {
  width100: { width: '100%' },
  right0: { right: '0px', top: '0px' },
  margin0: { margin: '0' },
  rowFlexCenter: { 'j-flex-center': true, row: true },
}

class Directions extends React.PureComponent {
  static propTypes = {
    labelFrom: PropTypes.string.isRequired,
    labelDestination: PropTypes.string.isRequired,
    nextRouteClean: PropTypes.func,
  }

  static contextTypes = {
    muiTheme: PropTypes.any,
  }

  render() {
    const { muiTheme } = this.context
    const { nextRouteClean, labelFrom, labelDestination } = this.props

    return (
      <UILayout column width="50%" sm={xsStyles.width100} data-testid="destination">
        <UILayout position="relative" center whiteSpace="nowrap" sm={xsStyles.rowFlexCenter}>
          {/* Remove round trip button */}
          <UILayout
            position="absolute"
            top={muiTheme.name === THEME.SAAREMAA ? '0' : '5px'}
            display-if={nextRouteClean}
            width="auto"
            sm={xsStyles.right0}
          >
            <UIButton id="removeButton" click={nextRouteClean} type="circle" width="40px" height="40px">
              <UIIcon type="iconClose" fill="white" width="17px" height="17px" />
            </UIButton>
          </UILayout>

          <UILayout sm={xsStyles.margin0} column center width="auto" position="relative">
            <GeneralLabel label={labelFrom} />
          </UILayout>

          <UILayout column center j-flex-center margin="0" width="auto">
            <UIIcon
              fill={muiTheme.palette.dateColor || '#2c4684'}
              type="iconChevron"
              margin="0 6px"
              {...muiTheme.ids.directionsChevron}
            />
          </UILayout>

          <UILayout column center width="auto" position="relative">
            <GeneralLabel label={labelDestination} />
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

export default Directions
