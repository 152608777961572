import moduleStyles from '../../../../../../Modal/Modals.module.scss'
import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'
import messages from '../../../../../../../consts/messages'
import React from 'react'
import { usePaymentButtonCooldown } from './usePaymentButtonCooldown'
import { getCurrentReservationId } from '../../../../../../../services/reservation/selectors'
import { connect } from 'react-redux'

export const EcommerceButton = ({ disabled, isLoading, code, handlePaymentClick, locale, reservationId }) => {
  const payButtonBlocked = usePaymentButtonCooldown({ reservationId, locale })

  console.log('EcommerceButton blocked = ', payButtonBlocked)

  return (
    <UILayout margin="3px" width="auto" blink={disabled && isLoading} className={moduleStyles.formDirection}>
      <UIButton
        className={moduleStyles.endMargin10px}
        width="300px"
        borderRadius="6px"
        data-testid="payment-method"
        disabled={disabled || payButtonBlocked}
        height="58px"
        click={handlePaymentClick(code)}
      >
        <UILayout j-flex-center center height="100%">
          <UIIcon type="iconECommercePaymentMethod_admaritime" fill="white" />
          <UIText
            className={moduleStyles.startMargin1rem}
            font="ADPortsGroupBold, sans-serif"
            translate={messages.payWithCardButtonText}
            textTransform="uppercase"
            color="white"
            size={locale === 'ar' ? '18px' : '22px'}
            xs={{ size: '16px' }}
          />
        </UILayout>
      </UIButton>
    </UILayout>
  )
}

const mapStateToProps = (state) => {
  const reservationId = getCurrentReservationId(state)

  return {
    reservationId,
  }
}

export default connect(mapStateToProps)(EcommerceButton)
