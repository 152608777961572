import { propEq, reduce, filter, findIndex, isEmpty, allPass } from 'ramda'
import moment from 'moment'
import { allPropsFilled } from './PassengersHeap.util'
import { isMaritime } from '../../../../utils/maritimeStyleUtils'

const FormFields = isMaritime
  ? ['firstName', 'lastName', 'birthday', 'gender', 'shouldSaveData']
  : ['firstName', 'lastName', 'birthday', 'citizenship', 'gender', 'optionalInfo']

const makePassengersMap = reduce(
  (all, val) => ({
    ...all,
    [val.seqN]: val,
  }),
  {}
)

export const getCitizenshipIndexByCode = (code) => findIndex(propEq('code', code))

const mapInitialValues = ({ values = {} } = {}, reservation = {}, seqNum, citizenshipList = [], tempPassenger) => {
  const { passengers = [] } = values
  const { items = [], guests = [] } = reservation

  const passengersMap = makePassengersMap(passengers)

  const guestTransform = (guest) => {
    const obj = { ...guest }
    items.forEach((item) => {
      if (item.ownerSeqNs.includes(obj.seqN)) {
        obj.type = item.priceCategoryTranslation || item.type
      }
    })
    return obj
  }

  const resulting = guests.map(guestTransform).map((guest) => {
    const card = passengersMap[guest.seqN] || {}

    let bDayObject = {}
    if (guest.ageInYears && guest.birthday) {
      const d = new Date(guest.birthday)
      const m = moment(d)
      bDayObject = {
        date: m.date() - 1,
        month: m.month(),
        year: moment().year() - m.year(),
      }
    }

    const guestCitizenship = guest.citizenship

    const guestData = {
      ...guest,
      optionalInfo: guest.comments || '',
      citizenship: getCitizenshipIndexByCode(guestCitizenship)(citizenshipList),
      gender: guest.gender || 'M',
      ...(guest.ageInYears ? { birthday: bDayObject } : {}),
    }

    FormFields.forEach((formField) => {
      guestData[formField] = card[formField] || guestData[formField]
    })

    return guestData
  })

  const sameSeqNum = propEq('seqN', seqNum)
  const passengersFromReservation = filter(sameSeqNum)(resulting)
  const passengersFromReservationWithFilledNames = filter(allPass([allPropsFilled, sameSeqNum]))(resulting)
  if (isEmpty(passengersFromReservationWithFilledNames) && tempPassenger) return [tempPassenger]

  return passengersFromReservation
}

export { mapInitialValues, FormFields }
