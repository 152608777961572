import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import { getMaritimeStyle } from '../../../../../../../utils/maritimeStyleUtils'
import { getStyle } from '../../../../../../../utils/liinilaevadThemeUtils'

const xsStylesRow = {
  rowInfo: { column: true, 'flex-start': true },
}

const capitalizeEveryWord = (string) => {
  if (string === ' ' || !string) {
    return ''
  }
  return string.split(' ').reduce((acc, word) => {
    const lowerCaseWord = word.toLowerCase()
    const capitalizedWord = lowerCaseWord[0].toUpperCase() + lowerCaseWord.slice(1)
    // eslint-disable-next-line no-param-reassign
    acc += `${capitalizedWord} `
    return acc
  }, '')
}

const RowInfo = ({ label, value, editMode, showIcon, id, style, shouldCapitalize, testId = '' }) => (
  <UILayout {...style} center xs={xsStylesRow.rowInfo} data-testId={testId}>
    <UIText
      color={getMaritimeStyle('#9DA7BF', getStyle('#A8A8A8', '#bfc3c7'))}
      width={getMaritimeStyle('140px', getStyle('140px', '120px'))}
      size={getStyle('18px', '16px')}
      font={getStyle('SourceSansPro, Sans-Serif')}
      align="start"
      {...(window.brandProps.muiTheme.ids.Confirmation &&
        window.brandProps.muiTheme.ids.Confirmation.rowText && {
          ...window.brandProps.muiTheme.ids.Confirmation.rowText,
        })}
    >
      {label}
    </UIText>
    <UIText
      data-testid={id}
      color={getMaritimeStyle('#2D3955', '#000')}
      size={getStyle('18px', '16px')}
      align="start"
      {...(window.brandProps.muiTheme.ids.Confirmation &&
        window.brandProps.muiTheme.ids.Confirmation.rowText && {
          ...window.brandProps.muiTheme.ids.Confirmation.rowText,
        })}
      font={getMaritimeStyle('ADPortsGroupBold, sans-serif')}
    >
      {shouldCapitalize ? capitalizeEveryWord(value) : value}
    </UIText>
    <UIIcon type="iconLock" margin="0 0 0 10px" display-if={editMode && showIcon} />
  </UILayout>
)

RowInfo.propTypes = {
  label: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  editMode: PropTypes.bool,
  showIcon: PropTypes.bool,
  shouldCapitalize: PropTypes.bool,
  testId: PropTypes.string,
}

RowInfo.defaultProps = {
  editMode: false,
  showIcon: false,
  shouldCapitalize: false,
}

export default RowInfo
