import React from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIText } from 'bora-material-ui'
import { Input } from '../../../../../formComponents'

import { translate } from '../../../../Common/Translator'

import messages from '../../../../../consts/messages'
import { warningSign } from '../../../../../consts/stuff'

const trimErrorMessage = (inputValue) => inputValue.replace(`${warningSign} `, '').trim()

export const LocalTravellerIDRow = (props) => {
  const {
    localIDValid,
    changeReduxFormField,
    localIDValue: { status, localID },
    checkIdNumber,
    disabledWhileFetching,
    handleDisabling,
  } = props

  const localIDPlaceholder = translate(messages.localTravellerIDPlaceholder.id)
  const checkLocalIDTitle = translate(messages.checkLocalID.id)

  return (
    <UILayout center j-flex-start sms={{ column: true, 'flex-center': true }}>
      <Input
        data-testid="input-local-id"
        meta={{}}
        icon="iconSmartIdColored"
        input={{
          value: localID,
          onChange: (value = '') => {
            changeReduxFormField({
              localID: value.trim(),
              status: 'not-checked',
            })
            if (disabledWhileFetching) {
              handleDisabling(false)
            }
          },
          onFocus: () => {
            if (localID.includes(warningSign)) {
              changeReduxFormField({ localID: trimErrorMessage(localID), status: 'not-checked' })
            }
          },
        }}
        placeholder={localIDPlaceholder}
        wrapperInputStyles={{
          height: 'auto',
          bgColor: 'white',
          borderRadius: '4px',
          boxShadow: '0 24px 24px 0 rgba(0, 0, 0, 0.06), 0 0 24px 0 rgba(0, 0, 0, 0.22)',
          padding: '0 0 0 30px',
          position: 'static',
          margin: '0 10px 0 0',
        }}
        fieldStyles={{
          height: '44px',
          boxShadow: 'none',
          padding: '0',
        }}
        style={{
          boxShadow: 'none',
          borderRadius: '4px',
          padding: '0',
        }}
        hintStyle={{ display: 'none' }}
        hideLabel
        hideStar
        underlineShow={false}
        wrapperStyles={{
          position: 'relative',
          padding: '20px 0 0 0',
          boxShadow: 'none',
          margin: '0',
        }}
        errorStyles={{ display: 'none' }}
        inputStyle={status === 'not-exist' && { color: '#b42a2a' }}
      />
      <UILayout
        center
        j-flex-center
        padding="16px 0 0 0"
        margin="0 10px"
        maxWidth="110px"
        cursor="pointer"
        data-testid="check-local-id"
        style={{ pointerEvents: localIDValid && !disabledWhileFetching ? '' : 'none' }}
        onClick={localIDValid && !disabledWhileFetching ? () => checkIdNumber(localID) : undefined}
      >
        <UIText
          color={localIDValid && !disabledWhileFetching ? '#0eb1bc' : '#94a8b2'}
          fontSize="18px"
          weight="bold"
          textDecoration="underline"
          transform="uppercase"
          text={checkLocalIDTitle}
        />
      </UILayout>
    </UILayout>
  )
}

LocalTravellerIDRow.propTypes = {
  localIDValue: PropTypes.object.isRequired,
  changeReduxFormField: PropTypes.func.isRequired,
  checkIdNumber: PropTypes.func.isRequired,
  localIDValid: PropTypes.bool.isRequired,
  disabledWhileFetching: PropTypes.bool,
  handleDisabling: PropTypes.func,
}
