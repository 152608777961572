import { mergeDeepRight } from 'ramda'
import UIElements from '../../../consts/UIElements'

const THEMES_STYLES = {
  KIHNU: {
    color: '#000000',
  },
  SEAJETS: {
    color: '#000000',
  },
  POSIDONIA: {
    color: '#000000',
  },
}

const defaultLabelStyles = {
  color: 'white',
  textTransform: 'uppercase',
}

export const labelStyles = mergeDeepRight(defaultLabelStyles, THEMES_STYLES[UIElements.APP_NAME] || {})
