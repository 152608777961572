import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, getFormValues } from 'redux-form'
import { bindActionCreators } from 'redux'
import cn from 'classnames'
import style from './PromoCodeForm.module.scss'

import { UIButton, UIIcon } from 'bora-material-ui'
import { PromoCodeField } from '../../../formComponents'
import messages from '../../../consts/messages'
import { compose, curry } from 'ramda'
import { connect } from 'react-redux'
import { applyPromoCodeToReservation } from '../../../services/reservation/actions/reservation'
import { getCurrentReservationId, getCurrentReservationPromotion } from '../../../services/reservation/selectors'
import { getGavdosStyle } from '../../../utils/gavdosStyleUtils'

export const FORM = 'promoCode'

export const PromoCodeForm = (
  { formValues: { code = '' }, applyPromoCode, reservationPromotion, reservationId },
  { intl }
) => {
  const codeEdited = code.toUpperCase() !== reservationPromotion
  const codeAppliedAndNotChanged = reservationPromotion && !codeEdited
  const iconType = codeAppliedAndNotChanged ? 'iconCheck' : 'selectTicketArrow'
  const fillColor = codeAppliedAndNotChanged ? '#44944a' : getGavdosStyle('#4266bd', '#0eb1bc')

  const handleSend = () => applyPromoCode(code)
  // const handleRemove = () => {}

  return (
    <div className={cn(style.wrapper, { [style.disabled]: !reservationId })} data-testid="promo-code-field">
      <div className={cn(style.label)}>{intl.formatMessage(messages.promocodeDescr)}</div>
      <div className={cn(style.fields)} display-if={!codeAppliedAndNotChanged}>
        <PromoCodeField disabled={!reservationId} name="code" placeholder=" " />
        <UIButton
          background={fillColor}
          disabled={!code}
          color="#fff"
          width="40px"
          height="45px"
          className={cn(style.button)}
          click={handleSend}
        >
          <div className={cn(style.buttonWrapper, { [style.checked]: codeAppliedAndNotChanged })}>
            <UIIcon fill="white" type={iconType} />
          </div>
        </UIButton>
      </div>
      <div className={cn(style.fields)} display-if={codeAppliedAndNotChanged}>
        <div className={cn(style.codeAppliedWrapper)}>
          <div className={cn(style.codeApplied)}>{code}</div>
          {/* <div className={cn(style.codeRemove)} onClick={handleRemove}>
            <UIIcon type="iconClose" fill="#b42a2a" width="15px" height="15px" />
          </div> */}
        </div>
      </div>
    </div>
  )
}

PromoCodeForm.contextTypes = {
  intl: PropTypes.any,
}

PromoCodeForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  reservationId: PropTypes.number,
  reservationPromotion: PropTypes.string,
  applyPromoCode: PropTypes.func.isRequired,
}

const enhance = compose(
  reduxForm({
    form: FORM,
    destroyOnUnmount: false,
  }),
  connect(
    (state) => ({
      reservationPromotion: getCurrentReservationPromotion(state),
      reservationId: getCurrentReservationId(state),
      formValues: getFormValues(FORM)(state) || {},
    }),
    curry(bindActionCreators)({
      applyPromoCode: applyPromoCodeToReservation,
    })
  )
)

export default enhance(PromoCodeForm)
