import React from 'react'
import PropTypes from 'prop-types'
import { UILayout } from 'bora-material-ui'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import { compose, defaultTo, find, head, propOr, propEq } from 'ramda'
import { getLocale } from '../../../../services/user-selections'
import { getFirstPayedReservation } from '../../../../services/reservation/selectors'
import { isMaritime } from '../../../../utils/maritimeStyleUtils'
import { getStyle } from '../../../../utils/liinilaevadThemeUtils'
import moduleStyles from '../../../SalesStatic/Common/Content.module.scss'
import cn from 'classnames'

const parseData = compose(head, ReactHtmlParser)

const styles = {
  termsContentWrapperXs: { width: '100%' },
}

const containerStyle = (lang) => ({
  marginTop: '2rem',
  paddingRight: getStyle('2.5rem'),
  direction: lang === 'ar' ? 'rtl' : 'ltr',
})

const ConditionsOfTicketsReturn = ({ mainTicketTermsContent, additionalTermsContent, locale }) => (
  <UILayout
    className={cn('without-padding-and-margin-for-printing', moduleStyles.contentRoot)}
    column
    justify="space-around"
    data-testid="terms-container"
    margin={getStyle('0 20px')}
    padding="0px 0px 20px 0px"
    style={containerStyle(locale)}
  >
    <UILayout data-testid="main-ticket-terms-content" display-if={mainTicketTermsContent} width="100%">
      {mainTicketTermsContent}
    </UILayout>
    <UILayout
      className="without-padding-and-margin-for-printing"
      padding="20px 0px 0px 0px"
      column
      center
      j-flex-center
    >
      <UILayout
        className={moduleStyles.additionalTermsContent}
        data-testid="additional-terms-content"
        display-if={additionalTermsContent}
        xs={styles.termsContentWrapperXs}
      >
        {additionalTermsContent}
      </UILayout>
    </UILayout>
  </UILayout>
)

ConditionsOfTicketsReturn.propTypes = {
  mainTicketTermsContent: PropTypes.string.isRequired,
  additionalTermsContent: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  const locale = getLocale()(state)
  let reservation = getFirstPayedReservation(state)
  if (Object.keys(reservation).length === 0) {
    reservation = state.reservation.current
  }
  const searchTermByLang = compose(defaultTo({}), find(propEq('language', locale)), propOr([], 'terms'))
  const { ticketTerms = '', additionalTicketTerms = '' } = searchTermByLang(reservation)
  const textStyle = 'font-size: 13px; font-family: ADPortsGroup, sans-serif;'

  return {
    mainTicketTermsContent: parseData(
      `<div class="schedules" style="${isMaritime ? textStyle : ''}">${ticketTerms}</div>`
    ),
    additionalTermsContent: parseData(
      `<div class="schedules" style="${isMaritime ? textStyle : ''}">${additionalTicketTerms.replace(
        /style=/gi,
        'data-style='
      )}</div>`
    ),
    locale,
  }
}

export default connect(mapStateToProps)(ConditionsOfTicketsReturn)
