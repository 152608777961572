import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { isValid, reduxForm } from 'redux-form'
import { UILayout, UIText } from 'bora-material-ui'
import { SignInButton } from '../components/Buttons'
import InputFieldWithIcon from '../components/Fields/InputFieldWithIcon'
import { translate } from '../../Common/Translator'
import messages from '../../../consts/messages'
import { actions, selectors } from '../provider'
import MessageBox from '../../Common/components/MessageBox'
import { isMaritime } from '../../../utils/maritimeStyleUtils'
import { getParameterByName } from '../../../utils/strings'

const styles = {
  wrapperStyles: { margin: '0' },
  width100MinHeight566: { width: '100%', minHeight: '566px' },
}

const xsStyles = {
  padding20: { padding: '20px' },
  margin0WidthAuto: { margin: '0', width: 'auto' },
}

class RecoveryMaritime extends React.Component {
  state = { isSendButtonClicked: false, showingMessage: false }

  handleRecovery = ({ recoveryMail, signPasswordRecovery, signSecondPasswordRecovery }) => {
    const resetToken = getParameterByName('hash')
    if (resetToken) {
      const requestBody = {
        token: resetToken,
        newPassword: signPasswordRecovery,
        confirmedNewPassword: signSecondPasswordRecovery,
      }
      this.props.resetPassword(requestBody)
    } else {
      this.props.recover({
        userName: recoveryMail,
        successMessage: translate(messages.recoveryLinkSent),
      })
    }
    this.setState({ isSendButtonClicked: true })
  }

  static getDerivedStateFromProps(props) {
    const { authError, authMessage } = props
    if (authError || authMessage) {
      return { isSendButtonClicked: false, showingMessage: true }
    } else if (!authError || !authMessage) {
      return { showingMessage: false }
    }
    return null
  }

  render() {
    const { isRecoveryAvailable, handleSubmit, authMessage, authError } = this.props
    const { isSendButtonClicked = false, showingMessage } = this.state
    const resetToken = getParameterByName('hash')

    return (
      <Fragment>
        <UILayout padding="30px" column center width="100%" xs={xsStyles.padding20}>
          <UILayout column j-flex-center center>
            <UILayout center j-flex-center margin="10px 0 40px 0">
              <UILayout row center j-flex-center shrink="0" width="auto">
                <UIText
                  align="center"
                  size="22px"
                  weight="bold"
                  margin="0px"
                  lineHeight="1"
                  color="#2D3955"
                  font="ADPortsGroupBold, sans-serif"
                  textTransform="uppercase"
                >
                  {translate(messages.signForgotPassHeaderTitle)}
                </UIText>
              </UILayout>
            </UILayout>

            <MessageBox margin="0 0 30px 0" text={authMessage || authError} type={authError ? 'error' : 'info'} />

            <UILayout column>
              <InputFieldWithIcon
                display-if={!resetToken}
                required
                icon={isMaritime ? '' : 'iconEmail'} // 'iconFieldEmail'
                name="recoveryMail"
                additionalWrapperStyles={styles.wrapperStyles}
              />
              <InputFieldWithIcon
                display-if={resetToken}
                required
                name="signPasswordRecovery"
                icon="iconFieldLock"
                type="password"
              />
              <InputFieldWithIcon
                display-if={resetToken}
                required
                name="signSecondPasswordRecovery"
                icon="iconFieldLock"
                type="password"
              />
              <SignInButton
                translate={messages.send}
                margin="32px 0px 0px 0px"
                disabled={
                  (isSendButtonClicked && !showingMessage) ||
                  (!isSendButtonClicked && showingMessage) ||
                  !isRecoveryAvailable
                }
                onClick={handleSubmit(this.handleRecovery)}
                height="64px"
                size="18px"
              />
            </UILayout>
          </UILayout>
        </UILayout>
      </Fragment>
    )
  }
}

const isRecoveryFormValid = isValid('recoveryForm')

export default connect(
  (state) => ({
    isRecoveryAvailable: isRecoveryFormValid(state),
    authError: selectors.getError(state),
    authMessage: selectors.getMessage(state),
  }),
  actions
)(reduxForm({ form: 'recoveryForm' })(RecoveryMaritime))
