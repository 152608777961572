import { UICheckbox } from 'bora-material-ui'
import { pathOr } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { change, reduxForm } from 'redux-form'
import messages from '../../../../consts/messages'
import { translate } from '../../../Common/Translator'

class SaveDataCheckbox extends React.Component {
  render() {
    const { saveDataCheckboxEnabled, shouldSaveDataValues, checkboxStyles, change, seqN } = this.props
    return (
      <UICheckbox
        name="shouldSaveData"
        onCheck={(event, value) => {
          change(seqN, value)
        }}
        checked={shouldSaveDataValues[seqN]}
        disabled={!saveDataCheckboxEnabled}
        labelStyle={checkboxStyles.label}
        iconStyle={checkboxStyles.labelIcon}
        label={translate(messages.saveDataLabel)}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  shouldSaveDataValues: pathOr(false, ['form', 'shouldSaveDataForm', 'values'])(state),
})

const mapDispatchToProps = {
  change,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'shouldSaveDataForm', destroyOnUnmount: false })(SaveDataCheckbox))
