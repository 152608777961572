import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { partial, compose, propEq, find, pathOr, prop } from 'ramda'
import { reduxForm, change, getFormValues } from 'redux-form'

import { selectors } from '../../provider'
import { changeItemQtty } from '../../../../services/user-selections'

import { getItems } from '../../../../services/reservation/selectors'

import setInitialValues from './setInitialValues'
import { isMaritime } from '../../../../utils/maritimeStyleUtils'

export default ({ iconType, vehicleType, areVehiclesActive, getInitialFormValues, getInputType, setInputType }) => {
  const vehicleTypeLowerCase = vehicleType.toLowerCase()
  const formName = `${vehicleTypeLowerCase}sForm`
  const typeIsActive = `${vehicleTypeLowerCase}IsActive`

  const initialValues = (state) => {
    const vehicleFormValues = getFormValues('ticketsForm')(state) || {}
    const vehicle = Object.keys(vehicleFormValues).find(
      (key) => vehicleFormValues[key].priceCategorySubType === vehicleType
    )
    if (vehicleFormValues[`${vehicleTypeLowerCase}IsActive`] && vehicle) {
      const editReservation = selectors.getEditReservation(state)

      const spSeqNum = state.selectedSailPackageIndexToEdit + 1
      const sailRefIdFromEditReservation = compose(
        pathOr(0, ['sailRefs', 0, 'sailRefId']),
        find(propEq('seqN', spSeqNum)),
        prop('sailPackages')
      )(editReservation)
      // const sailRefId = selectors.getSelectedSailRefId()(state)

      const values = setInitialValues(editReservation, vehicleType, sailRefIdFromEditReservation)

      if (values) {
        return values
      }
    }
    return {}
  }

  const isAdded = (items) => Array.isArray(items) && items.some((item) => item.priceCategorySubType === vehicleType)

  const mapStateToProps = (state, { editMode }) => {
    const items = getItems(state)
    const { formMayBeClosed, vehicleManuallyTicket, plateNumberVehicle = {} } = getFormValues(formName)(state) || {}

    const defaultValues = !formMayBeClosed
      ? null
      : {
          ...getInitialFormValues(state),
          calculatedTicketObj: {},
        }

    return {
      formName,
      iconType,
      isVehicleActive: areVehiclesActive(state),
      isVehicleAdded: isAdded(items),
      plateNumberVehicle,
      isManuallyTicket: Boolean(vehicleManuallyTicket),
      inputType: getInputType(state),
      initialValues: editMode ? initialValues(state) : defaultValues,
    }
  }

  const mapDispatchToProps = partial(bindActionCreators, [
    {
      changeReduxFormField: (field, value) => change(formName, field, value),
      changeIsActive: (value = false) => change('ticketsForm', typeIsActive, value),
      changeItemQtty,
      setInputType,
    },
  ])

  const formSettings = {
    form: formName,
    destroyOnUnmount: isMaritime,
    enableReinitialize: true,
  }

  return compose(connect(mapStateToProps, mapDispatchToProps), reduxForm(formSettings))
}
