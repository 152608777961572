import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import cn from 'classnames'
import style from './TicketPending.module.scss'
import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'
import { reservationStatus } from '../../../../services/reservation/api'
import { allowedStatuses } from './PaymentResults'
import { translate } from '../../../Common/Translator'
import messages from '../../../../consts/messages'
import { isMaritime } from '../../../../utils/maritimeStyleUtils'

const { ticketPendingRetries = 10 } = window.brandProps

const INTERVAL = 5000
const RETRY_LIMIT = ticketPendingRetries

// eslint-disable-next-line no-self-assign
const reloadPage = () => window.location.reload()

const TicketPending = ({ reservationToken }) => {
  const [pending, setPending] = useState(true)
  useEffect(() => {
    let retry = 0

    const interval = setInterval(async () => {
      retry += 1
      const status = await reservationStatus(reservationToken)

      if (allowedStatuses.includes(status)) {
        clearInterval(interval)
        reloadPage()
      }

      if (retry >= RETRY_LIMIT) {
        clearInterval(interval)
        setPending(false)
      }
    }, INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [reservationToken, setPending])

  if (isMaritime) {
    return (
      <UILayout column bgColor="white">
        <UILayout bgColor="#2D3955" padding="35px 0" center j-flex-center>
          <UIText font="ADPortsGroupBold, sans-serif" size="24px" text={translate(messages.paymentPendingHeading)} />
        </UILayout>
        <UILayout padding="40px 0" width="55%" margin="0 auto">
          <UIText
            color="#2D3955"
            size="18px"
            text={pending ? translate(messages.paymentPendingText) : translate(messages.paymentPendingErrorText)}
          />
        </UILayout>
        <UILayout j-flex-center padding="0 0 35px 0">
          <UIButton click={reloadPage} background="#13c5bd" width="150px" height="57px" borderRadius="6px">
            <UILayout height="100%" j-flex-center center>
              <UIText
                font="ADPortsGroupBold, sans-serif"
                size="16px"
                textTransform="uppercase"
                text={translate(messages.paymentPendingButtonReload)}
              />
            </UILayout>
          </UIButton>
        </UILayout>
      </UILayout>
    )
  }

  return (
    <section className={style.wrapper}>
      <div className={style.heading}>{translate(messages.paymentPendingHeading)}</div>
      <div className={cn(style.text, { [style.error]: !pending })}>
        {pending ? translate(messages.paymentPendingText) : translate(messages.paymentPendingErrorText)}
      </div>
      <UIIcon
        className={style.icon}
        height="100px"
        width="100px"
        display-if={!pending}
        type="sadFace"
        fill="cornflowerblue"
      />
      <div className={cn(style.spinner, { [style.hide]: !pending })}>
        <div className={style.ldsRipple}>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className={style.actions}>
        <button className={style.reloadButton} onClick={reloadPage}>
          {translate(messages.paymentPendingButtonReload)}
        </button>
      </div>
    </section>
  )
}

TicketPending.propTypes = {
  reservationToken: func.string,
}

export { TicketPending }
