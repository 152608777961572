import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, isValid } from 'redux-form'

import { UILayout, UIText } from 'bora-material-ui'

import LoginSectionTitle from '../LoginSectionTitle'
import InputFieldWithIcon from '../Fields/InputFieldWithIcon'
import { SignInButton } from '../Buttons'
import { showMessage } from '../../../../actions'
import messages from '../../../../consts/messages'
import { getMaritimeStyle, isMaritime } from '../../../../utils/maritimeStyleUtils'
import MessageBox from '../../../Common/components/MessageBox'
import { selectors } from '../../provider'
import { login, password } from '../../../../utils/validate'
import { getLocale } from '../../../../services/user-selections'

class EmailMethod extends React.Component {
  componentWillUnmount() {
    this.props.showMessage('')
  }

  handleAuth = (values) => {
    const { signMailOrPhoneNumber, signPassword } = values

    this.props.handleAuth({
      source: 'emailOrPhoneNumber',
      userName: signMailOrPhoneNumber,
      password: signPassword,
    })
  }

  render() {
    const { isLoginAvailable, goToRecover, handleSubmit, isFetching, message, authMessage, authError, isArabic } =
      this.props

    return (
      <UILayout data-testid="login-right-email" column>
        <LoginSectionTitle title="Email" />

        <MessageBox margin="0 0 30px 0" text={authMessage || authError} type={authError ? 'error' : 'info'} />

        <UILayout display-if={message === 'signup'} column>
          <UILayout
            bgColor={getMaritimeStyle('#D1F7D1', '#ebf4fa')}
            border={getMaritimeStyle('1px solid #218F21', '1px solid #4266bd')}
            borderRadius={getMaritimeStyle('6px', '3px')}
            padding="16px"
          >
            <UIText
              color={getMaritimeStyle('#218F21', '#79909b')}
              size={getMaritimeStyle('18px', '14px')}
              font={getMaritimeStyle('ADPortsGroupBold, sans-serif', 'Roboto')}
              align={getMaritimeStyle('center', 'left')}
              lineHeight={getMaritimeStyle('auto', '18px')}
              translate={messages.signUpSuccess}
            />
          </UILayout>
          <UIText
            display-if={!isMaritime}
            color="#79909b"
            size="18px"
            font="Roboto"
            align="left"
            padding="22px 16px"
            translate={messages.checkInbox}
          />
        </UILayout>

        <UILayout column center margin="0 0 20px 0" display-if={message !== 'signup'}>
          <InputFieldWithIcon
            required
            validate={[login]}
            // errorStyles={{ margin: isArabic ? '6px -34px 0px 0px' : '6px 0 0 -34px' }}
            name="signMailOrPhoneNumber"
            icon={`${isMaritime ? '' : 'iconEmail'}`} // 'iconFieldEmail'
          />
          <InputFieldWithIcon
            required
            validate={[password]}
            errorStyles={{ margin: isArabic ? '6px -34px 0px 0px' : '6px 0 0 -34px' }}
            name="signPassword"
            icon={`${isMaritime ? 'iconFieldLock' : 'iconPassword'}`}
            type="password"
          />
          <UIText
            display-if={!isMaritime}
            margin="20px 0 0 0"
            size="18px"
            color="#000000"
            cursor="pointer"
            onClick={goToRecover}
            textDecoration="underline"
            translate={messages.signForgotPass}
          />
        </UILayout>

        <SignInButton
          margin="25px 0 0 0"
          height="64px"
          size="18px"
          translate={messages.enter}
          onClick={handleSubmit(this.handleAuth)}
          disabled={isFetching || !isLoginAvailable}
          display-if={message !== 'signup'}
        />
      </UILayout>
    )
  }
}

EmailMethod.propTypes = {
  goToRecover: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isLoginAvailable: PropTypes.bool.isRequired,
  handleAuth: PropTypes.func.isRequired,
  message: PropTypes.any.isRequired,
  showMessage: PropTypes.func.isRequired,
}

const isLoginFormValid = isValid('loginForm')

const mapStateToProps = (state) => ({
  isLoginAvailable: isLoginFormValid(state),
  message: state.message,
  isFetching: state.auth.isFetching,
  authError: selectors.getError(state),
  authMessage: selectors.getMessage(state),
  isArabic: getLocale(state) === 'ar',
})

export default connect(mapStateToProps, { showMessage })(
  reduxForm({
    form: 'loginForm',
    touchOnChange: true,
  })(EmailMethod)
)
