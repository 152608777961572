import { mergeDeepRight } from 'ramda'
import UIElements from '../../../consts/UIElements'

const THEMES_STYLES = {
  KIHNU: {
    border: 'solid 2px #000000',
  },
}

const defaultTransparentButtonStyles = {
  width: '100%',
  height: '56px',
  'border-radius': '4px',
  border: 'solid 2px #ffffff',
  xs: { width: '100%' },
  background: 'transparent',
  margin: '10px 0px 0px 0px',
}

export const transparentButtonStyles = mergeDeepRight(
  defaultTransparentButtonStyles,
  THEMES_STYLES[UIElements.APP_NAME] || {}
)
