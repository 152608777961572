import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIButton, UIIcon, UIText, UIFormattedNumber, UIDivider } from 'bora-material-ui'
import { getStyle, isLiinilaevad } from '../../../../../utils/liinilaevadThemeUtils'

class VehicleTicket extends React.PureComponent {
  render() {
    const { muiTheme } = this.context
    const {
      showCloseButton = false,
      onClose,
      price,
      description,
      margin,
      iconType,
      xs,
      currency,
      hideZeroPrice = false,
    } = this.props

    const shouldShowPrice = !(hideZeroPrice && price <= 0)

    return (
      <UILayout
        data-testid="vehicle-ticket"
        center
        padding="8px 15px"
        bgColor="white"
        minHeight="90px"
        borderRadius="4px"
        position="relative"
        margin={margin}
        xs={xs}
        boxShadow="0 24px 24px 0 rgba(0, 0, 0, 0.06), 0 0 24px 0 rgba(0, 0, 0, 0.22)"
      >
        <UILayout column center width="auto">
          <UIIcon type={`${iconType}${isLiinilaevad ? 'LL' : ''}`} height="15px" margin="0 0 3px 0" />
          <UIText display-if={shouldShowPrice} size="28px" color={getStyle('#000000', '#0eb1bc')} xs={{ size: '24px' }}>
            <UIFormattedNumber
              value={price}
              style="currency"
              currency={currency || muiTheme.defaultCurrency || 'EUR'}
            />
          </UIText>
        </UILayout>
        <UIDivider vertical width="3px" margin="0 20px" style={{ minHeight: '70px' }} xs={{ margin: '0 10px' }} />
        <UIText margin="0px 5px" color="#000000" size="20px" width="100%" xs={{ size: '16px' }}>
          {description}
        </UIText>
        <UILayout
          width="auto"
          position="absolute"
          right="-15px"
          top="-15px"
          height="30px"
          xs={{ right: '-8px', top: '-8px', height: '28px' }}
        >
          <UIButton
            type="circle"
            background="#d0021b"
            display-if={showCloseButton}
            click={onClose}
            xs={{ width: '28px', height: '28px' }}
          >
            <UIIcon type="iconClose" fill="white" />
          </UIButton>
        </UILayout>
      </UILayout>
    )
  }
}

VehicleTicket.contextTypes = {
  muiTheme: PropTypes.object.isRequired,
}

VehicleTicket.propTypes = {
  onClose: PropTypes.any.isRequired,
  price: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  showCloseButton: PropTypes.any.isRequired,
  margin: PropTypes.any,
  iconType: PropTypes.any,
  hideZeroPrice: PropTypes.bool,
}

VehicleTicket.defaultProps = {
  margin: '0',
  iconType: '',
}

export default VehicleTicket
