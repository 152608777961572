import { translate } from '../../../../Common/Translator'
import messages from '../../../../../consts/messages'
import React, { useState } from 'react'
import moduleStyles from './DriverPassengerDetails.module.scss'
import { UIIcon, UIText } from 'bora-material-ui'
import { connect } from 'react-redux'
import { HeapWrap, PassengerWrap } from '../PassengersHeap'
import DriverForm from './DriverForm'
import { isValid } from 'redux-form'
import { FORM_ID } from './DriverFromConstants'

const style = getComputedStyle(document.body)

const colorErrorBase = style.getPropertyValue('--color-error-base')
const fontSizeStrong = style.getPropertyValue('--fs-base-strong')
const fontBold = style.getPropertyValue('--font-bold')
const colorPrimaryBase = style.getPropertyValue('--color-primary-base')

export const DriverPassengerDetails = ({ isDriverFormValid }) => {
  const [expanded] = useState(true)
  // const toggleExpanded = () => setExpanded((val) => !val)

  return (
    <HeapWrap data-testid="driver-details-heap" className={moduleStyles.root}>
      <React.Fragment>
        <PassengerWrap onClick={() => {}} id="drive-details-wrap" className={moduleStyles.wrapper}>
          <UIIcon width="25px" align="right" display-if={isDriverFormValid} type="userOk" />
          <UIText
            width="100%"
            display-if={isDriverFormValid}
            align="start"
            font={fontBold}
            margin="5px 0 0 5px"
            size={fontSizeStrong}
            color={colorPrimaryBase}
            text={translate(messages.vehicleDriverData)}
          />
          <UIText
            width="100%"
            display-if={!isDriverFormValid}
            align="start"
            margin="0 0 -5px 0"
            font={fontBold}
            size={fontSizeStrong}
            color={colorErrorBase}
            text={translate(messages.pleaseFillInPassengerData)}
          />
          {/* <UIIcon type="iconChevronDown" flipV={expanded} /> */}
        </PassengerWrap>
        {expanded && <DriverForm />}
      </React.Fragment>
    </HeapWrap>
  )
}

const mapStateToProps = (state) => {
  return { isDriverFormValid: isValid(FORM_ID)(state) }
}

export default connect(mapStateToProps)(DriverPassengerDetails)
