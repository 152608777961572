import { UIIcon, UILayout } from 'bora-material-ui'
import React from 'react'
import messages from '../../../../../../../consts/messages'
import { translate } from '../../../../../../Common/Translator'
import InfoPopup from '../../../../../../Promotions/containers/WaterTaxiBar/InfoPopup'
import Overlay from '../../../../../../Promotions/containers/WaterTaxiBar/Overlay'

class OnlineChargesInfoIcon extends React.Component {
  state = {
    shouldShowInfoPopup: false,
  }

  onIconClick = () => {
    this.setState((prevState) => ({
      shouldShowInfoPopup: !prevState.shouldShowInfoPopup,
    }))
  }

  render() {
    const { shouldShowInfoPopup } = this.state

    return (
      <UILayout width="auto" position="relative">
        <UILayout
          onClick={this.onIconClick}
          margin="0 10px 4px 10px"
          width="auto"
          cursor="pointer"
          xs={{ margin: '0 0 4px 0' }}
        >
          <UIIcon type="iconQuestionMark_admaritime" />
        </UILayout>
        <Overlay display-if={shouldShowInfoPopup} onClick={this.onIconClick} />
        <InfoPopup
          display-if={shouldShowInfoPopup}
          top="0"
          left="40px"
          xsTop="40px"
          xsLeft="-25px"
          title={translate(messages.onlineChargesModalTitle)}
          description={translate(messages.onlineChargesModalDescription)}
        />
      </UILayout>
    )
  }
}

export default OnlineChargesInfoIcon
