/* eslint-disable react/style-prop-object */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, map, pick, reduce, difference, keys, sum, pathOr } from 'ramda'
import purify from 'dompurify'

import { UIFormattedNumber, UILayout, UIText } from 'bora-material-ui'
import { THEME } from 'bora-material-ui/themes'

import { selectors } from '../../../../provider'
import {
  selectIntermediatePrice,
  selectIntermediateCategories,
  isRoundTrip as isRt,
  isLaaksaareRoute,
  getLocale,
} from '../../../../../../services/user-selections/'

import { getBookingConditionsForRoute, getPriceReadidenss } from '../../../../../../services/reservation/selectors'

import messages from '../../../../../../consts/messages'

import styled, { keyframes } from 'styled-components'
import { getMaritimeStyle, isMaritime } from '../../../../../../utils/maritimeStyleUtils'
import { getStyle, isLiinilaevad } from '../../../../../../utils/liinilaevadThemeUtils'
import { isMobile } from '../../../../../Common/components/AppBar'

const animationLoader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  position: relative;
  margin: 4px 10px 0 -35px;

  &:after {
    content: ' ';
    display: flex;
    width: 15px;
    height: 15px;
    margin: 1px;
    border-radius: 50%;
    border: 4px solid #298426;
    border-color: #298426 transparent #298426 transparent;
    animation: ${animationLoader} 1.2s linear infinite;
  }
`

const objFromItems = compose(
  reduce(
    (result, { priceCategory, price }) => ({
      ...result,
      [priceCategory]: price,
    }),
    {}
  ),
  map(pick(['priceCategory', 'price']))
)

const defaultStyle = isMaritime
  ? { 'j-flex-center': true }
  : isLiinilaevad
  ? { 'j-flex-space-between': false, 'j-flex-center': true }
  : { 'j-flex-space-between': true }

const xsStyles = {
  totalPrice: { column: 'true', margin: '0 0 8px 0' },
}

const TotalPrice = (props, { muiTheme, intl = false }) => {
  const {
    totalPrice,
    currencyCode,
    styles = defaultStyle,
    isPriceReady,
    reservationFee = false,
    toPayLater = false,
    isLaaksaare = false,
    outOfBookingBoundary = false,
    isConfirmPage = false,
    totalPriceMaritime,
    isArabic,
  } = props

  const initialPriceColor = getMaritimeStyle(
    '#2D3955',
    muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.tripText
      ? muiTheme.ids.Confirmation.tripText.color
      : '#2c4684'
  )

  const labelColor = muiTheme.ids.Confirmation ? muiTheme.palette.alternateTextColor : '#bfc3c7'
  const highlight = isPriceReady ? initialPriceColor : '#298426'
  const LoaderComponent = isPriceReady ? () => null : Loader

  const getColor = (color, defaultColor) => (window.brandProps.theme === THEME.LIINILAEVAD ? color : defaultColor)
  const getSize = (size, defaultSize) => (window.brandProps.theme === THEME.LIINILAEVAD ? size : defaultSize)
  const getFont = () => window.brandProps.theme === THEME.LIINILAEVAD && 'SourceSansPro, Sans-Serif'

  const reservationFeeMessage =
    isLaaksaare && !outOfBookingBoundary ? messages.reservationOnlyFeeLaak : messages.reservationOnlyFee
  let label = `${isArabic ? ':' : ''}${intl.formatMessage(
    reservationFee ? reservationFeeMessage : messages.totalToPay
  )}${isArabic ? '' : ':'}`

  if (toPayLater && !isLaaksaare) label = intl.formatMessage(messages.reservationOnlyToPayLater)

  const html = { __html: purify.sanitize(label) }

  return (
    <UILayout
      deepPure
      center
      flex-wrap
      column
      size="20px"
      color="#79909b"
      {...styles}
      data-testid="total-to-pay"
      xs={xsStyles.totalPrice}
    >
      <UIText
        size={getMaritimeStyle(
          isConfirmPage || isMobile ? '18px' : '12px',
          getSize(isConfirmPage || isMobile ? '18px' : '12px', '20px')
        )}
        color={getColor('#000000', labelColor)}
        font={getFont()}
        weight={getStyle(isConfirmPage && 'bold')}
        textTransform={getStyle(isConfirmPage && 'uppercase')}
        {...(muiTheme.ids.Confirmation &&
          muiTheme.ids.Confirmation.rowText && { ...muiTheme.ids.Confirmation.rowText, textTransform: 'uppercase' })}
        font={getMaritimeStyle('ADPortsGroupBold, sans-serif')}
      >
        <span dangerouslySetInnerHTML={html}></span>
      </UIText>
      <UIText
        color={getColor(muiTheme.palette.primary4Color, toPayLater ? '#bfc3c7' : highlight)}
        size={getSize('24px', '28px')}
        weight="bold"
        font={muiTheme.secondFontFamily}
      >
        <UILayout center row>
          <LoaderComponent />
          <UIFormattedNumber
            value={isMaritime && !isConfirmPage ? totalPriceMaritime : totalPrice}
            style="currency"
            currency={currencyCode || muiTheme.defaultCurrency || 'EUR'}
          />
        </UILayout>
      </UIText>
    </UILayout>
  )
}

TotalPrice.propTypes = {
  totalPrice: PropTypes.string.isRequired,
  intermediatePrice: PropTypes.number.isRequired,
  styles: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  reservationFee: PropTypes.bool.isRequired,
  toPayLater: PropTypes.bool.isRequired,
  isLaaksaare: PropTypes.bool.isRequired,
  outOfBookingBoundary: PropTypes.bool,
  isConfirmPage: PropTypes.bool,
}

TotalPrice.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
}

export default connect((state) => {
  const isRoundTrip = isRt(state)
  const items = selectors.getItems(state) || []
  const priceMap = objFromItems(items)
  const intermediateCategories = selectIntermediateCategories(state)
  const intermediatePrice = selectIntermediatePrice(state) * (isRoundTrip ? 2 : 1)
  const totalPriceFromState = selectors.getTotalPrice(state)

  const isPriceReady = getPriceReadidenss(state)
  const isLaaksaare = isLaaksaareRoute(state)

  const diff = difference(keys(priceMap), intermediateCategories)
  const price = compose(
    sum,
    map((key) => priceMap[key])
  )(diff)
  const totalPrice = isPriceReady ? totalPriceFromState : price + intermediatePrice

  const currentReservation = selectors.getCurrentReservation(state)
  const totalPriceMaritime = pathOr(0, ['paymentInfo', 'totalPrice', 'amountWithoutTaxes'])(currentReservation)

  const locale = getLocale(state)
  const isArabic = locale === 'ar'

  return {
    totalPrice,
    totalPriceMaritime,
    isPriceReady,
    currencyCode: selectors.getCurrency(state),
    isLaaksaare,
    outOfBookingBoundary: getBookingConditionsForRoute(state),
    isArabic,
  }
})(TotalPrice)
