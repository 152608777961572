import React from 'react'
import { UILayout, UIDivider, UIIncDecButton } from 'bora-material-ui'
import moduleStyles from '../../../Modal/Modals.module.scss'

const xsStyles = {
  column: { column: true },
  paddingV20: { padding: '20px 0' },
  borderNone: { border: 'none', borderBottom: 'none' },
  paymentBottom: { borderBottom: 'solid 1px #bfc3c7' },
  wrapper: { padding: '96px 30px 16px 30px', column: true },
  width100margin10: { width: '100%', margin: '10px' },
  width67marginR20: { width: '67px', margin: '0 20px 0 0' },
  marginR24: { margin: '0 24px 0 0', xs: { margin: '0' } },
}

const IncDecLoading = () => {
  const Field = ({ style }) => (
    <UILayout
      height="44px"
      bgColor="#fefefe"
      margin="0 0 15px 0"
      boxShadow="rgba(0, 0, 0, 0.04) 0px 11px 6px 0px, rgba(0, 0, 0, 0.02) 0px 6px 4px 0px"
      {...style}
    />
  )

  return (
    <UILayout blink width="100%" column>
      <UILayout center xs={xsStyles.column}>
        <Field style={xsStyles.marginR24} />
        <UILayout width="auto" j-flex-end xs={xsStyles.width100margin10}>
          <UIIncDecButton disabled initValue={0} />
        </UILayout>
      </UILayout>
    </UILayout>
  )
}

function DateTimeLoading() {
  const Sail = () => (
    <UILayout
      height="53px"
      bgColor="#fefefe"
      margin="0 0 15px 0"
      boxShadow="0 24px 24px 0 rgba(0, 0, 0, 0.06), 0 0 24px 0 rgba(0, 0, 0, 0.22)"
    />
  )

  return (
    <UILayout
      className={moduleStyles.formDirection}
      blink
      height="700px"
      padding="125px 20px 0px 20px"
      xs={xsStyles.wrapper}
    >
      <UILayout
        maxWidth="320px"
        height="324px"
        bgColor="#fefefe"
        boxShadow="0 24px 24px 0 rgba(0, 0, 0, 0.06), 0 0 24px 0 rgba(0, 0, 0, 0.22)"
      />
      <UILayout column style={{ paddingInlineStart: '20px' }} xs={xsStyles.paddingV20}>
        <Sail />
        <Sail />
        <Sail />
      </UILayout>
    </UILayout>
  )
}

function SelectTicketsLoading() {
  const Field = ({ style }) => (
    <UILayout
      height="44px"
      bgColor="#fefefe"
      margin="0 0 15px 0"
      boxShadow="rgba(0, 0, 0, 0.04) 0px 11px 6px 0px, rgba(0, 0, 0, 0.02) 0px 6px 4px 0px"
      {...style}
    />
  )

  return (
    <UILayout blink height="700px" padding="125px 20px 0px 20px" xs={xsStyles.wrapper}>
      <UILayout width="100%" column>
        <UILayout center margin="0 0 30px 0" xs={xsStyles.column}>
          <Field style={xsStyles.marginR24} />
          <UILayout width="auto" j-flex-end xs={xsStyles.width100margin10}>
            <UIIncDecButton disabled initValue={0} />
          </UILayout>
        </UILayout>
      </UILayout>

      <UILayout xs={0} width="auto" height="150px" margin="0 19px 62px 19px">
        <UIDivider vertical width="3px" height="100%" visible />
      </UILayout>

      <UILayout column width="100%" xs={xsStyles.paddingV20}>
        <Field />
        <Field />
        <UILayout>
          <Field style={xsStyles.width67marginR20} />
          <Field />
        </UILayout>
        <Field />
      </UILayout>
    </UILayout>
  )
}

function ConfirmPayLoading() {
  return (
    <UILayout column blink height="700px" padding="20px 20px" xs={xsStyles.wrapper}>
      <UILayout
        width="100%"
        height="80%"
        column
        border="solid 1px #bfc3c7"
        padding="0 16px"
        borderRadius="4px"
        xs={xsStyles.borderNone}
      >
        <UILayout height="135px" borderBottom="solid 1px #bfc3c7" />
        <UILayout height="245px" borderBottom="solid 1px #bfc3c7" />
        <UILayout height="135px" xs={xsStyles.paymentBottom} xl={xsStyles.borderNone} />
      </UILayout>
    </UILayout>
  )
}

export { DateTimeLoading, SelectTicketsLoading, ConfirmPayLoading, IncDecLoading }
