import React from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIText } from 'bora-material-ui'

const VehicleParam = ({ label, value }) => (
  <UILayout j-flex-center center>
    <UILayout j-flex-end>
      <UIText align="right" size="16px" color="#79909b">{`${label}:`}</UIText>
    </UILayout>
    <UILayout margin="0px 0px 0px 5px">
      <UIText size="16px" color="#000000">
        {value}
      </UIText>
    </UILayout>
  </UILayout>
)

VehicleParam.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default VehicleParam
