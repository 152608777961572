import React, { PropTypes, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { change, reduxForm } from 'redux-form'
import {
  compose,
  contains,
  pathOr,
  propOr,
  update,
  has,
  reduce,
  find,
  map,
  prop,
  uniqBy,
  filter,
  reject,
  propEq,
} from 'ramda'

import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import { LocalTravellerIDRow } from './components/LocalTravellerIDRow'
import { changeItemQtty, getLocale, getSelectedRoute } from '../../../../services/user-selections'
import { localIDValidator } from '../../../../utils/validate'
import { getLocalTravellerInfo, promoteResidentDiscount } from '../../../../services/user/actions'
import { selectors } from '../../provider'
import { getGuests } from '../../../../services/reservation/selectors'
import { LocalDiscountCheckBox } from './LocalDiscountCheckBox'
import { getAlternativeResidentsPriceCategory } from '../../../../services/routes/routes/reducers'
import { getDescriptionAttributeValueByCodeAndLocale } from '../../../../services/pricing/reducers/selectors'

const isLocalIdEmpty = propEq('localID', '')

const getLocalsFromCurrentReservation = compose(
  map((localID) => ({
    localID,
    status: 'exist',
  })),
  map(prop('personalIdentificationNumber')),
  filter(has('personalIdentificationNumber')),
  propOr([], 'guests')
)

function getPriceCategoryTranslation(reservationItems = [], id = '', residentsMap = {}) {
  const seqNum = residentsMap[id]
  const app = compose(
    propOr('', ['priceCategoryTranslation']),
    find(({ ownerSeqNs = [] }) => ownerSeqNs.includes(seqNum))
  )
  return app(reservationItems)
}

const newLocals = (localIDs, id) => localIDs.filter((local) => local.localID !== id)
const getCorrectTicket = (ticketsFormValues, item) => {
  const key = Object.keys(ticketsFormValues).find((key) => {
    const { localID, code, personalIdentificationNumbers = [] } = ticketsFormValues[key]
    if (!localID && code && code !== 'LOCAL_TRAVELLER') {
      return contains(item.localID, personalIdentificationNumbers)
    }
    return item.localID === localID
  })
  if (key) {
    return ticketsFormValues[key]
  }
  return null
}

const mergeLocalIds = ({ localIDs: fLocalIds = [] }, { localIDs: initialLocalIds = [] }) => {
  const idCodesFromForm = fLocalIds.map(prop('localID'))

  const initialValuesIdMap = reduce(
    (acc, val) => ({
      ...acc,
      [val.localID]: { ...val },
    }),
    {}
  )(initialLocalIds)

  const rawList = [...fLocalIds, ...initialLocalIds.filter(({ localID }) => idCodesFromForm.includes(localID))]
  const emptyIds = filter(isLocalIdEmpty)(fLocalIds)
  const notEmptyIds = reject(isLocalIdEmpty)(rawList)
  return [
    ...uniqBy(prop('localID'))(notEmptyIds).map((i) => ({
      ...i,
      ...(initialValuesIdMap[i.localID] && { ...initialValuesIdMap[i.localID] }),
    })),
    ...emptyIds,
  ]
}

const LocalTravellerIDForm = (props) => {
  const [disabledWhileFetching, setDisabledWhileFetching] = useState(false)
  const {
    changeReduxFormField,
    formValues,
    initialValues,
    checkPersonalIdAction,
    localTravellerInfo = {},
    residentTitles = {},
    currentReservationItems,
    guests = [],
    editMode,
    ticket,
    ticketsFormValues,
    alternativeResidentPriceCategory = '',
    alternativeResidentPriceCategoryDesc = '',
    promoteResidentDiscount,
    shouldShowLocalDiscountCheckbox = true,
  } = props

  let formValuesLocal = formValues

  if (editMode) {
    formValuesLocal = { ...formValues, localIDs: mergeLocalIds(formValues, initialValues) }
  }

  const { localIDs = [] } = formValuesLocal

  const makeResidentMap = compose(
    reduce((acc, { personalIdentificationNumber: code, seqN }) => ({ ...acc, [code]: seqN }), {}),
    filter(has('personalIdentificationNumber'))
  )
  const residents = makeResidentMap(guests)

  useEffect(() => {
    if (!localIDs.some((item) => item.status === 'not-checked')) {
      setDisabledWhileFetching(false)
    }
  }, [localIDs])

  const disablingCallback = (flag) => {
    setDisabledWhileFetching(flag)
  }

  return (
    <UILayout column data-testid="local-traveller-info">
      {localIDs.map((item, index) => {
        const levelOfItem = propOr('', 'level')(localTravellerInfo[item.localID])
        const backupRegistryUsed = propOr(false, 'backupRegistry')(localTravellerInfo[item.localID])
        const localIDFoundTitle =
          residentTitles[levelOfItem] || getPriceCategoryTranslation(currentReservationItems, item.localID, residents)

        const dispatchResidentDiscountPromotion = (checked = false) =>
          editMode
            ? changeReduxFormField(
                'localTravellerIDForm',
                'localIDs',
                update(
                  index,
                  {
                    ...item,
                    priceCategory: checked ? alternativeResidentPriceCategory : undefined,
                    backupRegistryUsed,
                  },
                  localIDs
                )
              )
            : promoteResidentDiscount({
                newCategory: alternativeResidentPriceCategory,
                seqNum: residents[item.localID],
                residentID: item.localID,
                checked,
                backupRegistryUsed,
              })

        const isResidentInCurrentReservation = Boolean(item.reservationSeqNum)
        const localDiscountCheckBoxInitialValue = editMode
          ? item.priceCategory === alternativeResidentPriceCategory
          : false

        return (
          <div>
            <UILayout>
              <UILayout center j-flex-start display-if={item.status !== 'exist'}>
                <LocalTravellerIDRow
                  index={index}
                  localIDValue={item}
                  checkIdNumber={(localID) => {
                    setDisabledWhileFetching(true)
                    if (editMode && item.resident) {
                      checkPersonalIdAction({ ...ticket, index, count: ticket.count + 1, type: 'check' })
                    } else {
                      checkPersonalIdAction({ localID, index, count: 1, type: 'check' })
                    }
                    props.getLocalTravellerInfo(localID)
                  }}
                  localIDValid={!localIDValidator(item.localID)}
                  changeReduxFormField={(newItem) =>
                    changeReduxFormField('localTravellerIDForm', 'localIDs', update(index, newItem, localIDs))
                  }
                  disabledWhileFetching={disabledWhileFetching}
                  handleDisabling={disablingCallback}
                />
              </UILayout>
              <UILayout center j-flex-start height="44px" margin="16px 0 0 0" display-if={item.status === 'exist'}>
                <UIText color="#7ed321" size="18px" width="300px" align="left">
                  {editMode
                    ? item.promotionDescription
                      ? `${item.promotionDescription}`
                      : `${item.localID} - ${localIDFoundTitle}`
                    : `${item.localID} - ${localIDFoundTitle}`}
                </UIText>
                <UILayout start j-flex-start margin="0 10px" padding="0 0 0 8px" maxWidth="110px">
                  <UILayout
                    margin="0 auto 0 auto"
                    width="auto"
                    cursor="pointer"
                    onClick={() => {
                      if (editMode) {
                        const ticket = getCorrectTicket(ticketsFormValues, item)
                        const count = propOr(0, 'count', ticket)
                        checkPersonalIdAction({ ...ticket, count: count - 1, type: 'confirm' })
                        changeReduxFormField('localTravellerIDForm', 'localIDs', newLocals(localIDs, item.localID))
                      } else {
                        checkPersonalIdAction({ localID: item.localID, index, count: 0 })
                      }
                    }}
                  >
                    <UIIcon type="iconClose" fill="#b42a2a" width="17px" height="17px" />
                  </UILayout>
                </UILayout>
              </UILayout>
            </UILayout>
            <LocalDiscountCheckBox
              idCode={item.localID}
              enabled={item.status === 'exist'}
              alternativeResidentPriceCategory={alternativeResidentPriceCategory}
              description={alternativeResidentPriceCategoryDesc}
              display-if={alternativeResidentPriceCategory && shouldShowLocalDiscountCheckbox}
              handleCheck={dispatchResidentDiscountPromotion}
              editMode={editMode}
              isResidentInCurrentReservation={editMode && isResidentInCurrentReservation}
              initialValue={localDiscountCheckBoxInitialValue}
            />
          </div>
        )
      })}
    </UILayout>
  )
}

LocalTravellerIDForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  changeReduxFormField: PropTypes.func.isRequired,
  checkPersonalIdAction: PropTypes.func.isRequired,
  promoteResidentDiscount: PropTypes.func.isRequired,
  localTravellerInfo: PropTypes.object.isRequired,
  residentTitles: PropTypes.object.isRequired,
  currentReservationItems: PropTypes.array,
  alternativeResidentPriceCategory: PropTypes.string,
  alternativeResidentPriceCategoryDesc: PropTypes.string,
}

const createEmptyLocalID = () => ({ localID: '', status: 'not-checked' })
const getInitialValuesForReadMode = (currentLocals, total) => {
  const localIDs = currentLocals.slice(0, total)
  const current = localIDs.length
  const amount = current && current <= total ? total - current : total
  return {
    localIDs: [...localIDs, ...Array(amount).fill().map(createEmptyLocalID)],
  }
}

function mapStateToProps(state, { editMode }) {
  const editReservation = selectors.getEditReservation(state)
  const selectedRoute = getSelectedRoute(state)
  const alternativeResidentPriceCategory = getAlternativeResidentsPriceCategory(selectedRoute, false)(state)
  const alternativeResidentPriceCategoryDesc = getDescriptionAttributeValueByCodeAndLocale(
    alternativeResidentPriceCategory,
    getLocale(state)
  )(state)

  const ticketsFormValues = pathOr({}, ['form', 'ticketsForm', 'values'])(state)
  const initial = []
  if (editMode) {
    Object.keys(ticketsFormValues).forEach((key) => {
      if (ticketsFormValues[key].resident && ticketsFormValues[key].ownerSeqNs) {
        ticketsFormValues[key].ownerSeqNs.forEach((ownerSeqN) => {
          const guest = editReservation.guests.find((guest) => guest.seqN === ownerSeqN)
          const item = editReservation.items.find((item) => item.ownerSeqNs.includes(ownerSeqN)) || {}
          const promotionItem = editReservation.items.find(
            (it) => it.promotion && it.priceCategory === item.priceCategory
          )
          if (guest) {
            initial.push({
              localID: guest.personalIdentificationNumber || '',
              status: 'exist',
              priceCategory: item.priceCategory,
              reservationSeqNum: guest.seqN,
              promotionDescription: promotionItem ? promotionItem.priceCategoryTranslation : undefined,
            })
          }
        })
      }
    })
  }

  const currentReservation = selectors.getCurrentReservation(state)
  const currentLocals = currentReservation ? getLocalsFromCurrentReservation(currentReservation) : []
  const countLocalIDs = pathOr(0, ['LOCAL_TRAVELLER', 'count'], ticketsFormValues)
  const sailDate = selectors.getSailDate(state)
  const tripDate = new Date(sailDate)

  const shouldShowLocalDiscountCheckbox = !(
    tripDate < Number(new Date('2021/06/01')) && selectedRoute.includes('SVI-ROH')
  )

  return {
    residentTitles: selectors.getResidentPriceCategoryTitles(state),
    shouldShowLocalDiscountCheckbox,
    formValues: selectors.getLocalTravellers(state),
    localTravellerInfo: pathOr({}, ['user', 'localTraveller'])(state),
    currentReservationItems: selectors.getItems(state),
    guests: getGuests(state),
    ticketsFormValues,
    alternativeResidentPriceCategory,
    alternativeResidentPriceCategoryDesc,
    initialValues: editMode
      ? ticketsFormValues.LOCAL_TRAVELLER
        ? initial.length === 0
          ? { localIDs: [...initial, createEmptyLocalID()] }
          : { localIDs: initial }
        : { localIDs: initial }
      : getInitialValuesForReadMode(currentLocals, countLocalIDs),
  }
}

const mapDispatchToProps = {
  changeReduxFormField: change,
  checkPersonalIdAction: changeItemQtty,
  getLocalTravellerInfo,
  promoteResidentDiscount,
}

const reduxFormConfig = {
  form: 'localTravellerIDForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
}

export default compose(connect(mapStateToProps, mapDispatchToProps), reduxForm(reduxFormConfig))(LocalTravellerIDForm)
