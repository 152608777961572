import React from 'react'
import PropTypes from 'prop-types'
import { UILayout } from 'bora-material-ui'

import LoginSectionTitle from '../LoginSectionTitle'
import { FacebookButton } from '../Buttons'
import messages from '../../../../consts/messages'

const FBMethod = ({ handleAuth, locale }) => (
  <UILayout data-testid="login-right-fb" column>
    <LoginSectionTitle title="Facebook" />
    <FacebookButton
      maxWidth="246px"
      margin={locale === 'ar' ? '0' : '0 20px 0 0'}
      translate={messages.signupFB}
      onClick={() => handleAuth({ social: 'facebook' })}
    />
  </UILayout>
)

FBMethod.propTypes = {
  handleAuth: PropTypes.func.isRequired,
}

export default FBMethod
