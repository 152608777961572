/* eslint-disable react/style-prop-object */
import React, { Component } from 'react'
import { captureException } from '@sentry/browser'
import PropTypes from 'prop-types'
import { pathOr, propOr, isEmpty, filter, head, propEq, compose, endsWith, flip, contains } from 'ramda'
import { connect } from 'react-redux'
import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'
import { withRouter } from 'react-router-dom'
import { getFormValues } from 'redux-form'

import { getSortedVehiclesOnly } from '../../../../../../utils/ramda-extend'
import Payment from './Payment'

import { CustomerInfo, TicketsInfo } from './ConfirmationInfo'

import UIElements from '../../../../../../consts/UIElements'
import messages from '../../../../../../consts/messages'

import { actions, selectors } from '../../../../provider'
import { showModal } from '../../../../../../actions'
import { getSelectedSailPackageFirstRoute } from '../../../../../../services/user-selections'
import { getBookingConditionsForRoute } from '../../../../../../services/reservation/selectors'
import { isMaritime, isBluewave } from '../../../../../../utils/maritimeStyleUtils'
import { isLiinilaevad } from '../../../../../../utils/liinilaevadThemeUtils'

const payableReservationStatuses = ['BOOKED', 'OFFER']
const creditCards = ['MAESTRO', 'MASTERCARD', 'VISA']
export const isCreditCard = flip(contains)(creditCards)

export const ReservationContext = React.createContext('reservation')

const xsStyles = {
  paymentWrap: { padding: '0 0 25px 0' },
  ticketsWrap: { padding: '0' },
  wrap: { column: true, border: '0', padding: '0' },
  divider: { margin: '10px 0px 0px 0px', padding: '10px 0px 0px 0px', borderTop: 'solid 1px #bfc3c7' },
}

const getBorderStyle = () => {
  if (isBluewave) {
    return '1px solid #54BCF0'
  } else if (isMaritime) {
    return '1px solid #C1CADE'
  } else if (isLiinilaevad) {
    return 'none'
  } else {
    return 'solid 1px #bfc3c7'
  }
}

class ConfirmPay extends Component {
  componentDidMount() {
    // note for payments:
    // here we need to load the reservation by the id provided (done)
    // now we need to now the transaction id which can be stored in the reservation ? (ask Igor)
    // apparently we need to ask (every n interval, may be use rxjs lite (?))
    // the transaction status endpoint for any good or bad status
    // to fully render the ticket or in bad case redirect to confirm
    // with the provided reservation id param
    const { manageUrlReservation, location, isLoggedIn } = this.props

    if (isLoggedIn) {
      this.props.fetchUserCustomerInfo()
    }

    manageUrlReservation({
      urlSearchParams: location.search,
      reservationId: selectors.getReservationIdFromUrl(this.props),
    })
  }

  tripEditHandler = (trip) => {
    this.props.startEditTrip(trip)
  }

  tripRemoveHandler = (trip) => {
    const { reservationId } = this.props.currentReservation
    this.props.removeTripPackage({ ...trip, reservationId })
  }

  handlePayment = (paymentCode) => {
    const {
      reservationId,
      token,
      paymentInfo,
      reservationOwner: { fullName, email },
      status = '',
    } = this.props.currentReservation
    const { locale, creditCardPaymentComplete } = this.props

    if (!payableReservationStatuses.includes(status)) {
      captureException(`Reservation ${reservationId} is in status ${status} and cannot be payed`)
      return
    }

    if (status === 'TEMP') {
      console.warn(`Reservation ${reservationId} is in the wrong status ${status} to pay`)
      return
    }

    if (isCreditCard(paymentCode)) {
      const {
        totalPrice: { currency, amount },
      } = paymentInfo

      window.creditCardCallback = function creditCardCallback(data) {
        creditCardPaymentComplete({ ...data, reservationId })
      }

      this.props.prepareForPayment({
        reservationToken: token,
        paymentMethod: paymentCode,
        creditCardPayment: true,
        ccDialogData: {
          amount,
          currency,
          email,
          clientName: fullName,
          locale,
          name: window.brandProps.official.title,
          description: `Reservation ${reservationId}`, // todo: add translation messages.checkinReservationId
          completed: 'creditCardCallback',
        },
      })

      return
    }

    if (paymentCode !== 'INVOICE') {
      this.props.prepareForPayment({
        reservationToken: token,
        paymentMethod: paymentCode,
      })
    } else {
      this.props.pay({
        reservationToken: token,
        paymentMethod: 'CREDIT',
        amount: paymentInfo.totalPrice.amount,
      })
    }
  }

  render() {
    const {
      currentReservation,
      prices,
      trips,
      sailPackageCode,
      firstVehicle,
      firstTrailer,
      vehicleSimplified,
      trailerSimplified,
      isLaaksaare = false,
      outOfBookingBoundary = false,
    } = this.props
    const { muiTheme } = this.context
    const {
      items = [],
      reservationOwner = {},
      paymentInfo = {},
    } = currentReservation || {}
    const { amount } = paymentInfo.totalPrice || {}

    const vehiclesItems = getSortedVehiclesOnly(items)

    const isLocalVehicleItem = compose(endsWith('-R'), propOr('', 'priceCategory'))
    const firstVehicleItem = compose(head, filter(propEq('priceCategorySubType', 'VEHICLE')))(vehiclesItems)
    const firstTrailerItem = compose(head, filter(propEq('priceCategorySubType', 'TRAILER')))(vehiclesItems)
    const showVehicle =
      ((currentReservation && currentReservation.vehicles && currentReservation.vehicles.length === 1) ||
        !isMaritime) &&
      !isEmpty(firstVehicle) &&
      !isLocalVehicleItem(firstVehicleItem)
    const showTrailer = !isEmpty(firstTrailer) && !isLocalVehicleItem(firstTrailerItem)

    const customerInfo = {
      ...reservationOwner,
      showVehicle,
      vehicleNumber: propOr('', 'licencePlate')(firstVehicle),
      carWidth: propOr('', 'widthInCm')(firstVehicle),
      carHeight: propOr('', 'heightInCm')(firstVehicle),
      carLength: propOr('', 'lengthInCm')(firstVehicle),
      carWeight: propOr('', 'weightInKg')(firstVehicle),
      vehicleFromMnt: Boolean(vehicleSimplified),
      trailer: {
        showTrailer,
        trailerNumber: propOr('', 'licencePlate')(firstTrailer),
        trailerWidth: propOr('', 'widthInCm')(firstTrailer),
        trailerHeight: propOr('', 'heightInCm')(firstTrailer),
        trailerLength: propOr('', 'lengthInCm')(firstTrailer),
        trailerWeight: propOr('', 'weightInKg')(firstTrailer),
        trailerFromMnt: Boolean(trailerSimplified),
      },
    }

    return (
      <ReservationContext.Provider value={this.props.currentReservation}>
        <UILayout j-flex-center>
          <UILayout padding="20px 20px" column flex-start width={UIElements.CONTENT_WIDTH}>
            <UILayout
              column
              padding="0 20px 20px 20px"
              border={getBorderStyle()}
              borderRadius="4px"
              sm={xsStyles.wrap}
            >
              <UILayout minHeight="300px" column>
                <CustomerInfo {...customerInfo} />
                <UILayout column sm={xsStyles.ticketsWrap}>
                  <TicketsInfo
                    trips={trips}
                    prices={prices}
                    reservation={currentReservation}
                    onEdit={this.tripEditHandler}
                    onDelete={this.tripRemoveHandler}
                    isLaaksaare={isLaaksaare}
                    outOfBookingBoundary={outOfBookingBoundary}
                  />
                </UILayout>
                <UILayout column j-flex-space-between sm={xsStyles.divider} />
              </UILayout>
            </UILayout>

            <UILayout padding="25px 0px" sm={xsStyles.paymentWrap}>
              <Payment
                onPaymentClick={this.handlePayment}
                sailPackageCode={sailPackageCode}
                amount={amount}
                isCreditClient={Boolean(reservationOwner.companyId)}
              />
            </UILayout>

            <UILayout display-if={false} column center j-flex-center>
              <UIButton type="circle" width="50px" height="50px">
                <UIIcon type="add" />
              </UIButton>
              <UIText
                margin="10px 0px 0px 0px"
                textTransform="uppercase"
                size="18px"
                color="#79909b"
                font={muiTheme.secondFontFamily}
                translate={messages.addMoreTickets}
              />
            </UILayout>
          </UILayout>
        </UILayout>
      </ReservationContext.Provider>
    )
  }
}

ConfirmPay.contextTypes = {
  muiTheme: PropTypes.object,
}

ConfirmPay.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  firstVehicle: PropTypes.object,
  firstTrailer: PropTypes.object,
  vehicleSimplified: PropTypes.string,
  trailerSimplified: PropTypes.string,
  sailPackageCode: PropTypes.string.isRequired,
  prices: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentReservation: PropTypes.object.isRequired,
  pay: PropTypes.func.isRequired,
  prepareForPayment: PropTypes.func.isRequired,
  trips: PropTypes.array.isRequired,
  paymentError: PropTypes.string.isRequired,
  reservationId: PropTypes.string,
  startEditTrip: PropTypes.func.isRequired,
  manageUrlReservation: PropTypes.func.isRequired,
  fetchUserCustomerInfo: PropTypes.func.isRequired,
  removeTripPackage: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
}

ConfirmPay.defaultProps = {
  reservationId: '',
  firstVehicle: {},
  firstTrailer: {},
  vehicleSimplified: '',
  trailerSimplified: '',
}

export default connect(
  (state, { paymentError }) => {
    const currentReservation = selectors.getCurrentReservation(state)

    const trips = selectors.tripsFromReservation(state)(currentReservation)
    const firstTrip = pathOr({}, [[0]], trips)
    const sailPackageCode = pathOr('', ['sailRefs', [0], 'route'], firstTrip)
    const fields = getFormValues('ticketsForm')(state) || {}
    const { vehicleSimplified } = getFormValues('vehiclesForm')(state) || {}
    const { vehicleSimplified: trailerSimplified } = getFormValues('trailersForm')(state) || {}
    const isLaaksaare = getSelectedSailPackageFirstRoute(state).startsWith('LAA-')

    return {
      trips,
      paymentError,
      currentReservation,
      prices: selectors.getPrices(state),
      sailPackageCode,
      firstVehicle: selectors.getFirstVehicleFromReservation(currentReservation),
      firstTrailer: selectors.getFirstTrailerFromReservation(currentReservation),
      isLoggedIn: selectors.isLoggedInSelector(state),
      fields,
      vehicleSimplified,
      trailerSimplified,
      locale: state.locale,
      isLaaksaare,
      outOfBookingBoundary: getBookingConditionsForRoute(state),
    }
  },
  { ...actions, showModal }
)(withRouter(ConfirmPay))
