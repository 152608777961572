import { UIButton, UIIcon, UILayout } from 'bora-material-ui'
import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import { getCurrentReservation, getCurrentReservationId } from '../../../../../../../services/reservation/selectors'
import { PAYMENT_PAY_FAILED } from '../../../../../../../services/payment/consts/payment'
import { pathOr, propOr } from 'ramda'
import { fetchPaymentPayload } from '../../../../../../../services/reservation/api'
import axios from 'axios'
import { translate } from '../../../../../../Common/Translator'
import messages from '../../../../../../../consts/messages'
import { getLocale } from '../../../../../../../services/user-selections'
import { usePaymentButtonCooldown } from './usePaymentButtonCooldown'

const { mpayBaseUrl } = window.brandProps

const APPLE_PAY_MAGIC_STRING = 'ADM Ferry Collection'
export const BUTTON_BLOCK_TIMESTAMP_KEY = 'applePayClickStamp'

const ApplePayMethod = ({
  reservationOwner,
  disabled,
  isLoading,
  paymentMethod,
  reservationToken,
  reservationId,
  moduleStyles,
  paymentPayFailed,
  locale = 'en',
}) => {
  const [payload, setPayload] = useState(null)
  const [preparing, setPreparing] = useState(false)

  useEffect(() => {
    if (reservationToken && paymentMethod) {
      console.log(`starting fetchPaymentPayload, reservationId = ${reservationToken}, paymentMethod = ${paymentMethod}`)
      fetchPaymentPayload(reservationToken, paymentMethod)
        .then(({ paymentPayload }) => setPayload(paymentPayload))
        .catch(() => setPayload(null))
    }
  }, [reservationToken, paymentMethod, setPayload])

  const payButtonBlocked = usePaymentButtonCooldown({ reservationToken, locale })

  console.log('ApplePayMethod blocked = ', payButtonBlocked)

  useEffect(() => {
    if (!payload) {
      console.error('Payload is not yet ready, ... wait')
      return
    }

    if (
      window.applePay &&
      window.applePay.buttonRenderingProcess &&
      typeof window.applePay.buttonRenderingProcess === 'function'
    ) {
      window.applePay.mPayAPIURL = mpayBaseUrl

      const url = `${mpayBaseUrl}PaymentAPI/MerchantIdentifier?CollectionType=${APPLE_PAY_MAGIC_STRING}`
      axios
        .post(url)
        .then((data) => {
          const { MerchantIdentifier, StoreName, CountryCode, CurrencyCode } = data

          window.applePay.merchantIdentifier = MerchantIdentifier
          window.applePay.storeName = StoreName
          window.applePay.countryCode = CountryCode
          window.applePay.currencyCode = CurrencyCode

          setTimeout(() => {
            if (window.applePay.supportedByDevice()) {
              window.applePay.buttonRenderingProcess()
            }
          }, 1000)
        })
        .catch((error) => {
          window.applePay.LogApiCall('MerchantIdentifier error', JSON.stringify(error))
        })
    } else {
      console.error('window.applePay is not in global or applePay.LoadApplePay is undefined')
    }
  }, [payload])

  const handleApplePayClick = () => {
    if (!reservationOwner) {
      return
    }

    const {
      email,
      firstName,
      lastName,
      phones: [{ intlCode = '', phoneNumber = '' }],
    } = reservationOwner

    const getOwnerData = (type = '') => {
      switch (type) {
        case 'BillingDetails':
          return {
            Email: email,
            FirstName: firstName,
            LastName: lastName,
            MobileNo: intlCode + phoneNumber,
          }
        case 'MetaData':
          return {
            Email: email,
            FirstName: firstName,
            LastName: lastName,
            MobileNumber: intlCode + phoneNumber,
          }
        default:
          return {}
      }
    }

    const ownerDataFromPayload = pathOr({}, ['MetaData', 0, 'Data', 'ADMFerry', 'TariffLine', 0])(payload)
    const invoiceNumberFromPayload = pathOr('', ['MetaData', 0, 'InvoiceNumber'])(payload)
    const billingDetailsFromPayload = propOr({}, 'BillingDetails')(payload)

    const paymentPayload = {
      ...(payload || {}),
      BillingDetails: {
        ...billingDetailsFromPayload,
        Address: 'UAE',
        Country: 'ae',
        State: 'Abu Dhabi',
        City: 'Abu Dhabi',
        ZipCode: '0000',
        ...getOwnerData('BillingDetails'),
      },
      MetaData: [
        {
          Data: {
            ADMFerry: {
              TariffLine: [
                {
                  ...ownerDataFromPayload,
                  ...getOwnerData('MetaData'),
                },
              ],
            },
          },
          InvoiceNumber: invoiceNumberFromPayload,
        },
      ],
    }

    setPreparing(true)

    window.boraPayment = {
      reservationId,
      reservationToken,
      paymentMethod,
      paymentPayFailedHandler: paymentPayFailed,
      expireMessage: translate(messages.paymentExpiredPopUpMessage),
      locale,
    }

    window.applePay.BeginApplePayPayment(paymentPayload)

    // const { error } = await preparePaymentRequester({
    //   reservationId,
    //   paymentMethod,
    //   creditCard: false,
    // })

    // if (error) {
    //   paymentPayFailed(error)
    // }

    setPreparing(false)
  }

  return (
    <UILayout
      display-if={payload}
      margin="3px"
      width="auto"
      blink={disabled && isLoading}
      className={cn(moduleStyles.formDirection, 'apple-pay')}
      id="apple-pay-button"
    >
      <UIButton
        className={moduleStyles.endMargin10px}
        background="black"
        width="300px"
        borderRadius="6px"
        data-testid="payment-method"
        disabled={disabled || preparing || payButtonBlocked}
        height="58px"
        click={handleApplePayClick}
      >
        <UILayout j-flex-center center height="100%">
          <UIIcon type="iconApplePay" fill="white" />
        </UILayout>
      </UIButton>
    </UILayout>
  )
}

const mapStateToProps = (state) => {
  const { reservationOwner, token } = getCurrentReservation(state)
  const reservationId = getCurrentReservationId(state)

  return {
    reservationOwner,
    reservationId,
    reservationToken: token,
    locale: getLocale(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  paymentPayFailed: (error) =>
    dispatch({
      type: PAYMENT_PAY_FAILED,
      payload: error,
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplePayMethod)
