import React from 'react'
import { map } from 'ramda'
import PropTypes from 'prop-types'

import { UIIcon, UILayout, UIText } from 'bora-material-ui'

const RoleItem = ({ role, name, registrationNumber, isSelected, onClick }, { muiTheme }) => (
  <UILayout
    cursor="pointer"
    onClick={onClick}
    hoverStyles={{ opacity: '0.5' }}
    data-testid={registrationNumber ? 'select-role-company' : 'select-role-person'}
  >
    <UILayout
      center
      column
      shrink="0"
      opacity="1"
      width="20px"
      height="20px"
      j-flex-center
      position="relative"
      margin="0 20px 0 0"
      xs={{ top: '0', width: '36px', height: '36px' }}
    >
      <UIIcon type="check" fill="white" display-if={isSelected} />{' '}
    </UILayout>
    <UILayout center xs={{ column: true }} j-flex-space-between>
      <UIText
        font={muiTheme.secondFontFamilyDem}
        letterSpacing="1px"
        size="23px"
        lineHeight="1"
        textTransform="uppercase"
        align="left"
        xs={{ align: 'center' }}
      >
        {name}
      </UIText>
      <UIText width="120px" align="left" size="12px" shrink="0" xs={{ align: 'center' }}>
        {role}
      </UIText>
    </UILayout>
  </UILayout>
)

RoleItem.contextTypes = {
  muiTheme: PropTypes.object,
}

RoleItem.propTypes = {
  role: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
}

const Roles = ({ customerRole = {}, onSelect, roles = [] }) => (
  <UILayout column childrenStyle={{ margin: '0 0 28px 0' }} padding="20px 10px 0 10px" xs={{ padding: '16px 0 0 0' }}>
    {map(
      (role) => (
        <RoleItem
          key={role.code}
          onClick={() => onSelect(role)}
          isSelected={role.code === customerRole.code}
          {...role}
        />
      ),
      roles
    )}
  </UILayout>
)

Roles.propTypes = {
  roles: PropTypes.array.isRequired,
  customerRole: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default Roles
