import React from 'react'
import PropTypes from 'prop-types'

import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'

const UAEPassButton = ({ click, translate, margin, xs, icon, disabled, locale }, { muiTheme }) => {
  const isArabic = locale === 'ar'

  return (
    <UIButton
      width="340px"
      height="64px"
      click={click}
      borderRadius="20px"
      disabled={disabled}
      xs={{ width: '100%', ...xs }}
      background="#000000"
      margin={margin}
    >
      <UILayout column j-flex-center lineHeight="30px" height="100%" style={{ flexBasis: '0' }}>
        <UIText align="left" size="24px">
          <UILayout row center j-flex-center>
            <UIIcon fill="white" type={icon} />
            <UIText
              align="center"
              width="auto"
              margin="3px 0 0 0"
              size={isArabic ? '16px' : '18px'}
              xs={{
                size: '14px',
                align: 'center',
                padding: isArabic ? '4px 0 0 10px' : '4px 20px 0 0',
              }}
              lineHeight="1"
              letterSpacing="0.8px"
              weight="bold"
              translate={translate}
              font={muiTheme.secondFontFamilyDem}
            />
          </UILayout>
        </UIText>
      </UILayout>
    </UIButton>
  )
}

UAEPassButton.defaultProps = {
  margin: '0 auto',
  xs: {},
  icon: '',
  buttonColor: '',
  disabled: false,
}

UAEPassButton.propTypes = {
  click: PropTypes.func.isRequired,
  translate: PropTypes.object.isRequired,
  margin: PropTypes.string,
  xs: PropTypes.object,
  icon: PropTypes.string,
  buttonColor: PropTypes.string,
  disabled: PropTypes.bool,
}

UAEPassButton.contextTypes = {
  muiTheme: PropTypes.object,
}

export default UAEPassButton
