import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { isLiinilaevad } from '../../../../../../../utils/liinilaevadThemeUtils'
import { isMaritime } from '../../../../../../../utils/maritimeStyleUtils'
import { LoopCircleLoading } from 'react-loadingg'
import moduleStyles from '../../../../../../Modal/Modals.module.scss'
import ApplePayMethod from './ApplePayMethodFresh'
import { EcommerceButton } from './EcommerceButton'

const spinnerPosition = {
  top: '0%',
  right: '48%',
}

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;

  ${breakpoint('sms')`
    ${
      isMaritime
        ? `
          justify-content: center;
          padding-top: 0;
          `
        : `
          justify-content: initial;
          padding-top: 0;
          `
    }
  `}
`

export const ListItem = styled.div`
  cursor: pointer;
  margin-bottom: 25px;
  margin-right: 20px;
  width: auto;

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    filter: grayscale(100%);
  `}

  ${breakpoint('sms')`
    margin-right: 60px;
    ${(props) =>
      props.liinilaevad &&
      `
      margin-right: 20px;
      margin-left: 20px;
    `}
  `}
`

export const Logo = styled('div')
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => prop !== 'backgroundImage' && defaultValidatorFn(prop),
  })
  .attrs({ className: 'payment-logo' })`
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: contain;
  height: 35px;
  width: 90px;

  ${breakpoint('sms')`
    width: 100px;
  `}
`

class PaymentMethods extends PureComponent {
  static defaultProps = {
    paymentMethods: [],
  }

  render() {
    const { disabled, isLoading, locale } = this.props

    if (disabled && isLoading && isMaritime) {
      return (
        <UILayout position="relative" margin="20px 0 40px 0">
          <LoopCircleLoading color={window.brandProps.muiTheme.menuItem.color} style={spinnerPosition} />
        </UILayout>
      )
    }

    return (
      <List>
        {this.getPaymentMethods()
          .filter(this.isMethodEnabled)
          .filter(this.isNotInvoiceMethod)
          .map((paymentMethod) => {
            if (isMaritime) {
              if (paymentMethod.code === 'APPLEPAY') {
                return (
                  <ApplePayMethod
                    paymentMethod={paymentMethod.code}
                    disabled={disabled}
                    isLoading={isLoading}
                    moduleStyles={moduleStyles}
                  />
                )
              }

              if (paymentMethod.code === 'E-COMMERCE') {
                return (
                  <EcommerceButton
                    disabled={disabled}
                    isLoading={isLoading}
                    code={paymentMethod.code}
                    locale={locale}
                    handlePaymentClick={this.handlePaymentClick}
                  />
                )
              }

              return null
            }

            return (
              <ListItem
                liinilaevad={isLiinilaevad}
                key={paymentMethod.code}
                data-testid="payment-method"
                disabled={disabled}
                onClick={this.handlePaymentClick(paymentMethod.code)}
              >
                <Logo
                  aria-label={`payment-logo-${paymentMethod.code}`}
                  data-testid={`payment-logo-${paymentMethod.code}`}
                  backgroundImage={paymentMethod.logoUrl}
                />
              </ListItem>
            )
          })}
      </List>
    )
  }

  handlePaymentClick = (paymentMethodCode) => {
    const { disabled, onPaymentClick } = this.props

    return () => {
      if (!disabled) {
        onPaymentClick(paymentMethodCode)
      }
    }
  }

  getPaymentMethods() {
    const { isCreditClient, paymentMethods } = this.props

    if (isCreditClient) {
      return paymentMethods.filter((paymentMethod) => paymentMethod.creditClient)
    }

    return paymentMethods.filter((paymentMethod) => paymentMethod.privateClient)
  }

  isMethodEnabled(paymentMethod) {
    return paymentMethod.enabled
  }

  isNotInvoiceMethod(paymentMethod) {
    return paymentMethod.code !== 'INVOICE'
  }
}

export { PaymentMethods }
