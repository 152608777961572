import { UIButton, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import messages from '../../../../../consts/messages'

class VehicleConfirmButtonMaritime extends React.Component {
  render() {
    const { onClick, enabled, isLoading } = this.props

    return (
      <UILayout blink={isLoading} width="100%" j-flex-center margin="30px 0 0 0">
        <UIButton width="200px" height="50px" borderRadius="6px" click={onClick} disabled={!enabled}>
          <UILayout height="100%" center j-flex-center>
            <UIText
              font="ADPortsGroupBold, sans-serif"
              textTransform="uppercase"
              size="18px"
              translate={messages.doneButtonLabel}
            />
          </UILayout>
        </UIButton>
      </UILayout>
    )
  }
}

export default VehicleConfirmButtonMaritime
