/* eslint-disable max-statements */

import { pathOr, propOr, reject } from 'ramda'
import { connect } from 'react-redux'
import { showModal } from '../../../../actions'
import { getSelectedTicketsValues } from '../../../../services/pricing/reducers/selectors'
import { setReservationFromReserve } from '../../../../services/reservation/actions/reservation'
import {
  getBookingConditionsForRoute,
  getModifying,
  getPriceReadidenss,
  getReservationFromReserve,
  isCurrentResLaaksaareRoute,
  isEditResLaaksaareRoute,
} from '../../../../services/reservation/selectors'
import { getRouteAllowsResidentsPurchase } from '../../../../services/routes/routes/reducers'
import { isLaaksaareInSelectedSails } from '../../../../services/schedule/reducers'
import { changeItemQtty, getSelectedRoute, isLaaksaareRoute } from '../../../../services/user-selections'
import { getReservation } from '../../../Ticket/EditTicket/editTicketSelectors'
import { CATEGORY_CODE, RESIDENT_POSTFIX } from '../../forms/TicketsForm/const'
import { getTheSailRefIdFromReservationByIndex } from '../../forms/TicketsForm/TicketsFormContainer'
import { COMPANY_NUMBER_STATUS } from '../../forms/VehiclesForm/tabs/consts'
import { getSelectedSailRefIdWhileEdit, selectors } from '../../provider'

const countOriginalTickets = (state, sailRefId, invClass) =>
  propOr([], ['items'], selectors.getEditReservation(state))
    .filter((item) => item.inventoryClass === invClass && sailRefId in item.sailRefIdToPricePerSail)
    .reduce((count, item) => count + item.quantity, 0)

const makeMapStateToProps = () => {
  const selectedSailPackageCode = selectors.getSelectedPackageCode()
  const selectedSailRefId = selectors.getSelectedSailRefId()
  const selectedSailRefIdWhileEdit = getSelectedSailRefIdWhileEdit()
  const getTicketsParams = selectors.ticketsIncDecSelectorParameters()

  // eslint-disable-next-line complexity
  const mapStateToProps = (state, props) => {
    const getAvailableInvClasses = selectors.availableInventoriesClassesBySailPackageCodeAndSailRefId()
    const currentReservation = selectors.getCurrentReservation(state)
    const code = selectedSailPackageCode(state, props)
    const editedReservation = getReservation(state)
    const { selectedSailPackageIndexToEdit = 0 } = state
    const sailRefIdFromSelectedSails = selectedSailRefId(state, props) || selectedSailRefIdWhileEdit(state, props)

    const sailRefId = getTheSailRefIdFromReservationByIndex(
      selectedSailPackageIndexToEdit,
      sailRefIdFromSelectedSails
    )(editedReservation)

    const availableInvClasses = getAvailableInvClasses(state, { code, sailRefId })
    const ticketsParams = getTicketsParams(state, { ...props, availableInvClasses })

    const routeAddons = selectors.getRouteAddonsParameters(state)
    let routeAddonsAvailableForSelection = []
    let selectedAddonTickets = []
    let shouldShowAddon = true
    if (props.isAddon) {
      const currentRouteAddon =
        routeAddons && routeAddons.find((routeAddon) => routeAddon.priceCategory === props.addonData.code)
      const { mandatoryParameter } = currentRouteAddon || {}
      shouldShowAddon = !(
        mandatoryParameter &&
        mandatoryParameter.type === 'STRING' &&
        currentRouteAddon.priceCategory === 'TRANSFER'
      )
      if (currentRouteAddon) {
        routeAddonsAvailableForSelection =
          currentRouteAddon.mandatoryParameter && currentRouteAddon.mandatoryParameter.values
            ? currentRouteAddon.mandatoryParameter.values.map((value) => {
                const unifyTitles = (obj) => {
                  return Object.keys(obj).reduce((result, key) => {
                    result[key] = value
                    return result
                  }, {})
                }
                return {
                  ...props.addonData,
                  title: value,
                  titles: unifyTitles(props.addonData.titles),
                  attributeCode: currentRouteAddon.mandatoryParameter.parameter,
                }
              })
            : [{ ...props.addonData, mandatoryParameter: false }]
      } else {
        routeAddonsAvailableForSelection = [{ ...props.addonData, mandatoryParameter: false }]
      }
      selectedAddonTickets = Object.values(getSelectedTicketsValues(state)).filter(
        (item) => item.isAddon && item.attributeCode
      )
    }

    const isLaaksaare = isLaaksaareRoute(state)

    const routeCode = getSelectedRoute(state)
    const allowResidentsPurchase = getRouteAllowsResidentsPurchase(routeCode)(state)
    const { maxValues: ticketsMaxValues, selectedTicketsList, ticketsCount } = ticketsParams
    let { categoriesAvailableForSelection } = {
      ...ticketsParams,
      categoriesAvailableForSelection: reject(({ code }) =>
        !allowResidentsPurchase ? code === CATEGORY_CODE.LOCAL_TRAVELLER : false
      )(ticketsParams.categoriesAvailableForSelection),
    }

    const icons = { iconBike: 'BICYCLE', iconPassenger: 'DECK' }
    const invClass = icons[props.icon] || 'DECK'
    const originalTicketsCount = props.editMode ? countOriginalTickets(state, sailRefId, invClass) : 0

    const hideZeroPrice = props.editMode
      ? isEditResLaaksaareRoute(state) && !getBookingConditionsForRoute(state)
      : (isLaaksaareInSelectedSails(state) && !getBookingConditionsForRoute(state)) ||
        (isCurrentResLaaksaareRoute(state) && !getBookingConditionsForRoute(state))

    const maxValues =
      originalTicketsCount && invClass in ticketsMaxValues
        ? {
            ...ticketsMaxValues,
            [invClass]: ticketsMaxValues[invClass] + originalTicketsCount,
          }
        : ticketsMaxValues

    const driverForLocalVehicleFromAttribute = ticketsParams.categoriesAvailableForSelection.find((category) => {
      return (
        category.attributes &&
        category.attributes.find(
          (item) => item.attribute.code === 'SHOW_ADULT_TICKET_LOCAL_INVENTORY_USAGE' && item.structure.value.value
        )
      )
    })

    let driverConfirmationShouldBeShown = false
    let localCompanyVehicleHasBeenAdded = false

    // eslint-disable-next-line DECK_reserves
    if ('DECK' in maxValues && (maxValues.DECK <= 0 || ticketsCount >= maxValues.DECK)) {
      if (maxValues.DECK_reserves > 0) {
        if (props.areVehiclesAvailable) {
          categoriesAvailableForSelection = categoriesAvailableForSelection.filter(
            (category) =>
              category.code === CATEGORY_CODE.LOCAL_TRAVELLER ||
              (driverForLocalVehicleFromAttribute && category.code === driverForLocalVehicleFromAttribute.code)
          )
          driverConfirmationShouldBeShown = true
          localCompanyVehicleHasBeenAdded =
            currentReservation &&
            currentReservation.vehicles &&
            currentReservation.vehicles.some((vehicle) => vehicle.companyRegistrationNumber)
        } else {
          categoriesAvailableForSelection = categoriesAvailableForSelection.filter(
            (category) => category.code === CATEGORY_CODE.LOCAL_TRAVELLER
          )
        }
      } else {
        categoriesAvailableForSelection = []
      }
    }
    /* if ('DECK' in maxValues && (maxValues['DECK'] <= 0 || ticketsCount >= maxValues['DECK'])) {
      categoriesAvailableForSelection = categoriesAvailableForSelection.filter(
        (category) => category.code === 'LOCAL_TRAVELLER',
      )
    }*/
    if (isLaaksaare && !getBookingConditionsForRoute(state)) {
      categoriesAvailableForSelection = categoriesAvailableForSelection.filter(
        (category) => category.code !== CATEGORY_CODE.LOCAL_TRAVELLER
      )
    }
    if (props.editMode) {
      const ticketsFormValues = pathOr({}, ['form', 'ticketsForm', 'values'])(state)
      const vehiclesFormValues = pathOr({}, ['form', 'vehiclesForm', 'values'])(state)
      const localIDsFormValues = pathOr({}, ['form', 'localTravellerIDForm', 'values'])(state)
      const residentKey = Object.keys(ticketsFormValues).find(
        (key) => ticketsFormValues[key].resident && ticketsFormValues[key].inventoryClass === 'DECK'
      )
      if (residentKey) {
        const localCategoryKey = selectedTicketsList.findIndex(
          (ticket) => ticket.code === CATEGORY_CODE.LOCAL_TRAVELLER
        )
        let count = 0
        if (localIDsFormValues.localIDs && localIDsFormValues.localIDs.length) {
          count = localIDsFormValues.localIDs.length
        } else {
          selectedTicketsList.forEach((ticket) => {
            if (ticket.resident) {
              count += ticket.count
            }
          })
        }
        if (localCategoryKey === -1) {
          const localTravellerCategory = categoriesAvailableForSelection.find(
            (category) => category.code === CATEGORY_CODE.LOCAL_TRAVELLER
          )
          if (localTravellerCategory) {
            selectedTicketsList.push({ ...localTravellerCategory, count })
          }
        } else {
          selectedTicketsList[localCategoryKey].count = count
        }
        categoriesAvailableForSelection = categoriesAvailableForSelection.filter(
          (category) => category.code !== CATEGORY_CODE.LOCAL_TRAVELLER
        )
      }
      localCompanyVehicleHasBeenAdded =
        vehiclesFormValues &&
        vehiclesFormValues.companyRegistrationNumber &&
        vehiclesFormValues.companyRegistrationNumber.status === COMPANY_NUMBER_STATUS.VERIFIED &&
        vehiclesFormValues.vehicleTicket &&
        vehiclesFormValues.vehicleTicket.priceCategory &&
        vehiclesFormValues.vehicleTicket.priceCategory.includes(RESIDENT_POSTFIX)
    }

    // Used to disable UI in case of async queries and avoiding race condition
    // TODO: replace with queue
    const fetchingModify = getModifying(state)
    const priceReady = getPriceReadidenss(state)

    return {
      maxValues,
      shouldShowAddon,
      ticketsCount,
      categoriesAvailableForSelection,
      routeAddonsAvailableForSelection,
      selectedTicketsList,
      selectedAddonTickets,
      fetchingModify,
      priceReady,
      hideZeroPrice,
      driverFromReservesConfiguration: {
        driverConfirmationShouldBeShown,
        driverForLocalVehicleFromAttribute,
        localCompanyVehicleHasBeenAdded,
      },
      isReservationForDriverFromReserve: getReservationFromReserve(state),
    }
  }
  return mapStateToProps
}

const MaritimeOptionContainer = connect(makeMapStateToProps(), {
  changeItemQtty,
  showModal,
  setReservationFromReserve,
})

export default MaritimeOptionContainer
