import React from 'react'
import { translate } from '../../../Common/Translator'
import messages from '../../../../consts/messages'
import PropTypes from 'prop-types'

import cn from 'classnames'
import style from './VerificationCode.module.scss'

export const VerificationCode = ({ code = '' }) => {
  return (
    <div className={cn(style.wrapper)} display-if={code}>
      <div className={cn(style.content)} data-testid="verification-code-text">
        {translate(messages.verificationCode)}
        <span data-testid="verification-code-code" className={cn(style.code)}>
          {code}
        </span>
      </div>
    </div>
  )
}

VerificationCode.propTypes = {
  code: PropTypes.string,
}
