export const FORM_ID = 'bw-driver-details'

export const DRIVER_FIRST_NAME = 'bw-driver-first-name'
export const DRIVER_LAST_NAME = 'bw-driver-last-name'
export const DRIVER_PLACE_OF_BIRTH = 'bw-driver-place-of-birth'
export const DRIVER_DATE_OF_BIRTH = 'bw-driver-dob'
export const DRIVER_COUNTRY_OF_ISSUE = 'bw-driver-country-of-issue'
export const DRIVER_PASSPORT_NUMBER = 'bw-driver-passport-number'
export const DRIVER_GENDER = 'bw-driver-gender'

export const validate = (values) => {
  const errors = {}

  if (Object.keys(values).length < 7) {
    if (!Object.keys(values).includes(DRIVER_FIRST_NAME)) {
      errors[DRIVER_FIRST_NAME] = 'common-fields.required-error'
    }
    if (!Object.keys(values).includes(DRIVER_LAST_NAME)) {
      errors[DRIVER_LAST_NAME] = 'common-fields.required-error'
    }
    if (!Object.keys(values).includes(DRIVER_COUNTRY_OF_ISSUE)) {
      errors[DRIVER_COUNTRY_OF_ISSUE] = 'common-fields.required-error'
    }
    if (!Object.keys(values).includes(DRIVER_PLACE_OF_BIRTH)) {
      errors[DRIVER_PLACE_OF_BIRTH] = 'common-fields.required-error'
    }
    if (!Object.keys(values).includes(DRIVER_PASSPORT_NUMBER)) {
      errors[DRIVER_PASSPORT_NUMBER] = 'common-fields.required-error'
    }
    if (!Object.keys(values).includes(DRIVER_DATE_OF_BIRTH)) {
      errors[DRIVER_DATE_OF_BIRTH] = 'common-fields.required-error'
    }
  }

  return errors
}
