import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import style from './AuthSideMenu.module.scss'
import moduleStyles from './LoginMenuItem.module.scss'

import { UILayout, UIText } from 'bora-material-ui'

import LoginMenuItem from '../components/LoginMenuItem'
import {
  EmailMethod,
  IDKaartMethod,
  MobilIDMethod,
  SmartIDMethod,
  FBMethod,
  UAEPassMethod,
  GoogleMethod,
} from '../components/AuthMethods'

import messages from '../../../consts/messages'
import { isMaritime } from '../../../utils/maritimeStyleUtils'
import { pathOr } from 'ramda'
import { translate } from '../../Common/Translator'

export const UAE_PASS_TITLE = 'UAEPass'

const AUTH_METHODS = isMaritime
  ? [
      {
        icon: 'iconAtSign',
        iconActive: 'iconAtSignActive',
        text: 'E-mail/Mobile',
        id: 'mail',
        translationKey: 'authMethodEmailMobile',
      },
      {
        icon: 'iconFBAuth_admaritime',
        iconActive: 'iconFBActive_admaritime',
        text: 'FACEBOOK',
        id: 'FB',
        translationKey: 'authMethodFacebook',
      },
      {
        icon: 'iconGoogleAuth',
        iconActive: 'iconGoogleActive',
        text: 'Google',
        id: 'Google',
        translationKey: 'authMethodGoogle',
      },
    ]
  : [
      { icon: 'iconEmailAuth', iconActive: 'iconEmailAuthActive', text: 'E-mail', id: 'mail' },
      { icon: 'iconSmartIdAuth', iconActive: 'iconSmartIdAuthActive', text: 'ID-CARD', id: 'idCard' },
      { icon: 'iconSmartIdAuth', iconActive: 'iconSmartIdAuthActive', text: 'MOBILE-ID', id: 'mobilID' },
      { icon: 'smartId', iconActive: 'smartIdActive', text: 'SMART-ID', id: 'smartID' },
      { icon: 'iconFBAuth', iconActive: 'iconFBActive', text: 'FACEBOOK', id: 'FB' },
      { icon: 'iconGoogleAuth', iconActive: 'iconGoogleActive', text: 'Google', id: 'Google' },
    ]

export const AuthTypeContext = React.createContext('authType')

class SideMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openedTab: props.initTab,
    }
  }

  goToRecover = () => {
    this.props.goToPage('/auth/recovery')
  }

  render() {
    const { intl } = this.context
    const locale = pathOr('en', ['locale'])(intl)
    const { openedTab } = this.state
    const { allowedMethods } = this.props
    const loginMenuItems = AUTH_METHODS.filter(({ id }) => allowedMethods.includes(id))

    if (isMaritime) {
      return (
        <AuthTypeContext.Provider value={this.props.authType}>
          <UILayout column padding="30px">
            <UILayout margin="0 0 25px 0">
              <EmailMethod
                goToRecover={this.goToRecover}
                handleAuth={this.props.handleAuth}
                display-if={openedTab === 'mail'}
              />
              <FBMethod locale={locale} handleAuth={this.props.handleAuth} display-if={openedTab === 'FB'} />
              <GoogleMethod locale={locale} handleAuth={this.props.handleAuth} display-if={openedTab === 'Google'} />
            </UILayout>

            <UILayout padding="30px 0px" borderTop="1px solid #D4D4D4" j-flex-center>
              <UIText color="#6E6E6E" size="16px" translate={messages.chooseMethod} />
            </UILayout>

            <UILayout
              display-if={loginMenuItems && loginMenuItems.length > 1}
              justify="space-around"
              xs={{ wrap: true }}
              className={moduleStyles.wrapper}
            >
              {loginMenuItems.map((item) => (
                <LoginMenuItem
                  key={item.id}
                  icon={item.icon}
                  iconActive={item.iconActive}
                  text={item.translationKey ? translate(messages[item.translationKey]) : item.text}
                  onClick={() => this.setState({ openedTab: item.id })}
                  isNowOpened={openedTab === item.id}
                />
              ))}
            </UILayout>

            <div className={style.alwaysVisibleUAE}>
              <div className={style.divider}>
                <UIText size="14px" color="#6E6E6E" className={style.dividerText} translate={messages.authOrLabel} />
              </div>
              <UAEPassMethod locale={locale} handleAuth={this.props.handleAuth} display-if={isMaritime} />
            </div>
          </UILayout>
        </AuthTypeContext.Provider>
      )
    }

    return (
      <AuthTypeContext.Provider value={this.props.authType}>
        <Fragment>
          <div
            data-testid="login-left-part"
            className={style.authMethodsList}
            display-if={loginMenuItems && loginMenuItems.length > 1}
          >
            {loginMenuItems.map((item) => (
              <LoginMenuItem
                key={item.id}
                icon={item.icon}
                iconActive={item.iconActive}
                text={item.text}
                onClick={() => this.setState({ openedTab: item.id })}
                isNowOpened={openedTab === item.id}
              />
            ))}
          </div>

          <UILayout column center data-testid="login-right-part" width="365px" maxWidth="100%" shrink="0">
            <UILayout
              data-testid="login-right-start"
              display-if={!openedTab}
              maxWidth="296px"
              j-flex-center
              center
              height="100%"
            >
              <UIText color="#bfc3c7" size="18px" translate={messages.chooseMethod} />
            </UILayout>

            <EmailMethod
              goToRecover={this.goToRecover}
              handleAuth={this.props.handleAuth}
              display-if={openedTab === 'mail'}
            />
            <IDKaartMethod handleAuth={this.props.handleAuth} display-if={openedTab === 'idCard'} />
            <MobilIDMethod handleAuth={this.props.handleAuth} display-if={openedTab === 'mobilID'} />
            <SmartIDMethod handleAuth={this.props.handleAuth} display-if={openedTab === 'smartID'} />
            <FBMethod handleAuth={this.props.handleAuth} display-if={openedTab === 'FB'} />
            <GoogleMethod handleAuth={this.props.handleAuth} display-if={openedTab === 'Google'} />
          </UILayout>
        </Fragment>
      </AuthTypeContext.Provider>
    )
  }
}

SideMenu.defaultProps = {
  initTab: 'mail',
  allowedMethods: window.brandProps.authMethods,
}

SideMenu.contextTypes = {
  intl: PropTypes.object,
}

SideMenu.propTypes = {
  authType: PropTypes.string.isRequired,
  handleAuth: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  allowedMethods: PropTypes.array,
  initTab: PropTypes.string,
}

export default SideMenu
