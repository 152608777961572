import React from 'react'
import PropTypes from 'prop-types'

import { InputField } from '../Fields'
import { getMaritimeStyle } from '../../../../utils/maritimeStyleUtils'

const fieldStyles = { height: '44px', boxShadow: 'none' }
const hintStyle = {
  color: getMaritimeStyle('#737F9A', '#bfc3c7'),
  fontSize: getMaritimeStyle('14px'),
  paddingLeft: '0',
}

const InputFieldWithIcon = (props) => {
  const { additionalWrapperInputStyles, additionalWrapperStyles, disabled } = props

  const wrapperInputStyles = {
    height: 'auto',
    bgColor: 'white',
    borderRadius: '4px',
    boxShadow: getMaritimeStyle('none', '0 24px 24px 0 rgba(0, 0, 0, 0.06), 0 0 24px 0 rgba(0, 0, 0, 0.22)'),
    position: 'static',
    margin: getMaritimeStyle('0', '0 10px 0 0'),
    style: {
      paddingBlock: 0,
      paddingInline: '1rem',
    },
    ...additionalWrapperInputStyles,
  }
  const wrapperStyles = {
    borderRadius: getMaritimeStyle('4px'),
    border: getMaritimeStyle('1px solid #D4D4D4'),
    position: 'relative',
    padding: getMaritimeStyle('0', '16px 0 0 0'),
    margin: getMaritimeStyle('0 0 20px 0', '0 10px'),
    ...additionalWrapperStyles,
  }

  return (
    <InputField
      {...props}
      key={props.name}
      wrapperInputStyles={wrapperInputStyles}
      fieldStyles={fieldStyles}
      hintStyle={hintStyle}
      hideLabel
      hideStar
      underlineShow={false}
      wrapperStyles={wrapperStyles}
      disabled={disabled}
    />
  )
}

InputFieldWithIcon.propTypes = {
  name: PropTypes.any.isRequired,
  icon: PropTypes.any,
  type: PropTypes.string,
  required: PropTypes.bool,
  additionalWrapperInputStyles: PropTypes.any,
  additionalWrapperStyles: PropTypes.any,
  disabled: PropTypes.bool,
}

InputFieldWithIcon.defaultProps = {
  icon: false,
  type: 'text',
  required: false,
  additionalWrapperInputStyles: {},
  additionalWrapperStyles: {},
}

export default InputFieldWithIcon
