import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { pathOr } from 'ramda'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'

import { translate } from '../../../Common/Translator'
import { selectors, actions } from '../../provider'

import messages from '../../../../consts/messages'

import UIElements from '../../../../consts/UIElements'
import { Panel } from '../../../../components/Panel'
import { isMaritime } from '../../../../utils/maritimeStyleUtils'

const styles = {
  colorBlack: { color: '#000000' },
}

const xsStyles = {
  column: { column: true },
  marginTop10: { margin: '10px 0 0 0' },
  goBackButton: { padding: '' },
  margin0: { margin: '0' },
  binIcon: { margin: '0 0 10px 0' },
  prevTitleBlock: { margin: '32px 0', style: { order: 3 } },
  textAlignCenter: { textAlign: 'center' },
  content: { width: '100%', maxWidth: '100%', padding: '0 16px 10px 16px' },
}

class RefundEdit extends PureComponent {
  render() {
    const { reservation, token, goToPage } = this.props
    const { muiTheme } = this.context

    const toRefundAmountFormatted = pathOr(0, ['paymentInfo', 'toRefundAmountFormatted'], reservation)

    return (
      <UILayout
        column
        center
        bgColor="white"
        padding="33px 20px 55px 20px"
        margin="40px 0 0 0"
        {...muiTheme.ids.refundScreen}
      >
        <UILayout {...muiTheme.ids.refundScreenTitle} />
        <UILayout margin="21px 0 31px 0" row center j-flex-center>
          <UILayout xs={xsStyles.column} center j-flex-center>
            <UIIcon type="paperBin" minWidth="29px" margin="0 15px 0 0" xs={xsStyles.binIcon} />
            <UILayout width="auto" xs={xsStyles.textAlignCenter}>
              <UIText
                size="24px"
                width="auto"
                color="#79909b"
                letterSpacing="1px"
                formattedMessage={translate(messages.ticketWasRefundAfterEdit)}
                reservationId={reservation.reservationId}
                font={muiTheme.secondFontFamilyDem}
                selectionStyles={styles.colorBlack}
              />
            </UILayout>
          </UILayout>
        </UILayout>

        <UILayout width={UIElements.CONTENT_WIDTH} column xs={xsStyles.content}>
          <div>
            <Panel>
              <UILayout textAlign="center" maxWidth="450px" margin="0 auto">
                <UIText
                  size="16px"
                  color="#79909b"
                  letterSpacing="1px"
                  formattedMessage={translate(messages[`ticketWasRefundTipAfterEdit${isMaritime ? 'Maritime' : ''}`])}
                  refundAmount={toRefundAmountFormatted}
                  selectionStyles={styles.colorBlack}
                />
              </UILayout>
            </Panel>
          </div>

          <UILayout display-if={!token} j-flex-center xs={xsStyles.column} margin="44px 0 0 0">
            <UIText translate={messages.mayClose} margin="5px 0 0 0" align="center" size="16px" color="#79909b" />
          </UILayout>

          <UILayout
            onClick={() => goToPage('/profile/tickets')}
            display-if={token}
            margin="44px 0 0 0"
            j-flex-center
            width="auto"
            data-testid="go-to-prev"
            xs={xsStyles.prevTitleBlock}
          >
            <UILayout cursor="pointer" center j-flex-center xs={xsStyles.margin0}>
              <UIIcon fill="#79909B" height="29px" type="iconGoBack" />
              <UIText
                whiteSpace="nowrap"
                margin="0 0 0 20px"
                letterSpacing="0.5px"
                color="#79909b"
                size="18px"
                textTransform="uppercase"
                font="GinesoNorm"
                xs={xsStyles.prevTitleText}
                translate={messages.backToTickets}
              />
            </UILayout>
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

RefundEdit.propTypes = {
  goToPage: PropTypes.func.isRequired,
  reservation: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
}

RefundEdit.contextTypes = {
  intl: PropTypes.object,
  muiTheme: PropTypes.object,
}

export default connect(
  (state) => ({
    token: selectors.getToken(state),
    // reservation: selectors.getRefundReservation(state)
    reservation: pathOr({}, ['editTicket', 'reservation'])(state),
  }),
  {
    goToPage: actions.goToPage,
    goBack: actions.backToPreviousPage,
  }
)(RefundEdit)
