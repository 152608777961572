import React from 'react'
import PropTypes from 'prop-types'
import { UITicketCard } from 'bora-material-ui'
import { any, equals, pathOr } from 'ramda'
import { findByType } from '../AvailableTickets.util'

import messages from '../../../../../consts/messages'
import { translate } from '../../../../Common/Translator'
import { convertCurrentInventoryForVoyages } from '../../../../../services/inventory/utils'

class Voyages extends React.PureComponent {
  static propTypes = {
    voyages: PropTypes.array.isRequired,
    selectedSail: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    onEditMode: PropTypes.bool,
    setEditedReservationData: PropTypes.func,
    route: PropTypes.string,
    inventoryTaken: PropTypes.object,
    hasOnlyPassengers: PropTypes.bool,
    hasOnlySeatsAndCars: PropTypes.bool,
  }

  static defaultProps = {
    onEditMode: false,
    inventoryTaken: {},
    setEditedReservationData: () => ({}),
  }

  onSelect = (sail) => {
    const { onEditMode } = this.props
    if (onEditMode) {
      this.props.setEditedReservationData({ field: 'time', param: sail.departure.time })
    }

    const { sailPackage, sailRefId } = sail
    this.props.onSelect({
      sailPackageCode: sailPackage,
      selectedSailId: sailRefId,
    })
  }

  render() {
    const {
      voyages,
      selectedSail,
      inventoryTaken,
      hasOnlyPassengers = false,
      hasOnlySeatsAndCars,
      onEditMode,
      locale,
      supportCars,
      supportPassengers,
      supportTrucks,
      supportBicycles,
    } = this.props
    const currentInventory = convertCurrentInventoryForVoyages(inventoryTaken)
    let showTicketsNote = false

    return voyages.map((ticket) => {
      const passengerTickets = findByType('Passenger')(ticket.availabilityTickets)
      const bikeTickets = findByType('Bike')(ticket.availabilityTickets)
      const carTickets = findByType('Car')(ticket.availabilityTickets)
      const passengerDoesNotFit = currentInventory.Passenger > passengerTickets.availability
      const bikeDoesNotFit = currentInventory.Bike > bikeTickets.availability
      const vehicleDoesNotFit = currentInventory.Car > carTickets.availability
      const voyageCapacityError = any(equals(true))([passengerDoesNotFit, bikeDoesNotFit, vehicleDoesNotFit])

      if (!showTicketsNote && !(passengerTickets.availability && bikeTickets.availability && carTickets.availability)) {
        showTicketsNote = true
      }
      const ticketSelected = !voyageCapacityError && selectedSail && ticket.sail.sailRefId === selectedSail.sailRefId
      const header = `${ticket.sail.departure.time} ${translate(messages.sailTime)} - ${
        ticket.sail.arrival.time
      } ${translate(messages.arrivalTime)}`
      const onSelect = ticket.inventoryEnabled ? () => this.onSelect(ticket.sail) : () => {}

      const status = pathOr('', ['sail', 'status'])(ticket)
      const vesselType = pathOr('', ['sail', 'vesselType'])(ticket)
      const vesselFeatures = pathOr({}, ['sail', 'vesselFeatures'])(ticket)
      const vesselAttributesHidePaxString = pathOr('false', ['sail', 'vesselAttributes', 'HIDE_PAX_IN_ONLINE'])(ticket)

      const isNextDay = pathOr(false, ['sail', 'isNextDay'])(ticket)
      const hidePassengersCapacity = JSON.parse(vesselAttributesHidePaxString) || false

      return (
        <UITicketCard
          key={header}
          header={header}
          onSelect={onSelect}
          arrival={ticket.sail.arrival}
          departure={ticket.sail.departure}
          ticketSelected={ticketSelected}
          availabilityTickets={ticket.availabilityTickets}
          soldOutLabel={translate(messages.soldOut)}
          isClosed={status === 'CLOSED'}
          isCancelled={status === 'CANCELLED'}
          isDangerous={ticket.sail.dangerousGoods}
          isRestrictedPrices={ticket.sail.restrictedPrices}
          route={this.props.route}
          voyageCapacityError={voyageCapacityError}
          isHovercraft={vesselType === 'AIR_CUSHION_VESSEL'}
          vesselFeatures={vesselFeatures}
          hasOnlyPassengers={hasOnlyPassengers}
          hasOnlySeatsAndCars={hasOnlySeatsAndCars}
          onEditMode={onEditMode}
          locale={locale}
          supportCars={supportCars}
          supportPassengers={supportPassengers}
          supportTrucks={supportTrucks}
          supportBicycles={supportBicycles}
          isNextDay={isNextDay}
          hidePaxPassengersCapacity={hidePassengersCapacity}
        />
      )
    })
  }
}

export default Voyages
