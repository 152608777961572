import { useEffect, useState } from 'react'
import { BUTTON_BLOCK_TIMESTAMP_KEY } from './ApplePayMethodFresh'

export const usePaymentButtonCooldown = ({ reservationId, locale }) => {
  const [payButtonBlocked, setPayButtonBlocked] = useState(false)

  useEffect(() => {
    if (window.location.href.endsWith('error=PAYMENT_STATUS_FAILURE')) {
      const blockTimeStamp = localStorage.getItem(`${BUTTON_BLOCK_TIMESTAMP_KEY}-${reservationId}`)
      if (!blockTimeStamp) {
        localStorage.setItem(`${BUTTON_BLOCK_TIMESTAMP_KEY}-${reservationId}`, `${Date.now()}`)
      }
    }
  }, [reservationId])

  useEffect(() => {
    const i = setInterval(() => {
      const coolDownTime = 60000 * 10
      const now = Date.now()
      const blockTimeStamp = localStorage.getItem(`${BUTTON_BLOCK_TIMESTAMP_KEY}-${reservationId}`)
      if (blockTimeStamp) {
        const blockTime = parseFloat(blockTimeStamp)
        if (now > blockTime + coolDownTime) {
          setPayButtonBlocked(false)
          localStorage.removeItem(`${BUTTON_BLOCK_TIMESTAMP_KEY}-${reservationId}`)
          window.location.assign(`${window.origin}/#/${locale}/main-inside/confirm`)
        } else {
          setPayButtonBlocked(true)
        }
      }
    }, 1000)

    return () => clearInterval(i)
  }, [setPayButtonBlocked, reservationId, locale])

  return payButtonBlocked
}
