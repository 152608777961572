import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import { getMaritimeStyle, isMaritime } from '../../../utils/maritimeStyleUtils'
import moduleStyles from './LoginMenuItem.module.scss'

const loginMenuStyle = {
  height: '40px',
  center: true,
  cursor: 'pointer',
}

const LoginMenuItem = ({ icon, iconActive, text, onClick, isNowOpened }, { muiTheme }) => (
  <UILayout
    data-testid="auth-option-item"
    className={moduleStyles.item}
    width={getMaritimeStyle('auto')}
    margin={getMaritimeStyle('0 0 20px 0')}
    column={isMaritime}
    {...loginMenuStyle}
    onClick={onClick}
  >
    <UILayout width="50px" center j-flex-center>
      <UIIcon
        height={getMaritimeStyle('25px')}
        type={isNowOpened ? iconActive : icon}
        fill={!isNowOpened ? '#94a8b2' : ''}
      />
    </UILayout>
    <UIText
      color={!isNowOpened ? getMaritimeStyle('#737F9A', '#94a8b2') : getMaritimeStyle('#2D3955', '#4266bd')}
      size="16px"
      textTransform="uppercase"
      font={getMaritimeStyle('ADPortsGroupBold, sans-serif', muiTheme.secondFontFamilyDem)}
    >
      {text}
    </UIText>
  </UILayout>
)

LoginMenuItem.contextTypes = {
  muiTheme: PropTypes.object,
}

LoginMenuItem.propTypes = {
  icon: PropTypes.any.isRequired,
  text: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  isNowOpened: PropTypes.any.isRequired,
  iconActive: PropTypes.any.isRequired,
}

export default LoginMenuItem
