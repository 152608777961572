import React from 'react'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'
import { UIIncDecButton, UILayout, UIFormattedNumber, UIText } from 'bora-material-ui'
import { THEME } from 'bora-material-ui/themes'

import { TicketTypeIconTitle } from './TicketTypeRow'
import messages from '../../../../../consts/messages'
import { olderBrowser } from '../../../../../utils/browserUtils'
import { getMaritimeStyle } from '../../../../../utils/maritimeStyleUtils'

class SingleItem extends React.PureComponent {
  static contextTypes = {
    muiTheme: PropTypes.any,
    intl: PropTypes.object,
  }

  static propTypes = {
    maxValue: PropTypes.number,
    ticket: PropTypes.object.isRequired,
    icon: PropTypes.string.isRequired,
    changeItemQtty: PropTypes.func.isRequired,
    isAddon: PropTypes.bool,
    disabled: PropTypes.bool,
    editMode: PropTypes.bool,
  }

  render() {
    const { muiTheme, intl } = this.context
    const { ticket, maxValue, icon, changeItemQtty, disabled, editMode = false, isAddon = false } = this.props
    const getSize = (size, defaultSize) => (muiTheme.name === THEME.LIINILAEVAD ? size : defaultSize)

    const singleItemStyle = {
      ...(olderBrowser && { width: '450px' }),
      ...(editMode && olderBrowser && { width: '330px' }),
    }

    return (
      <UILayout
        data-testid="single-time-ticket-row"
        center
        height="44px"
        margin={getMaritimeStyle('0', '20px 20px 0 0')}
        padding={getMaritimeStyle('0', '0 0 0 14px')}
        style={singleItemStyle}
        sml={{ row: true, margin: '10px 0', 'flex-center': true, padding: '0 0 0 10px' }}
      >
        <UILayout shrink="1" center>
          <TicketTypeIconTitle title={ticket.title} icon={icon} />
          <UILayout margin="0px 5px" display-if={ticket.price}>
            <UIText
              size={getSize('18px', '16px')}
              color="#000000"
              margin={olderBrowser ? '0 auto' : '0'}
              {...muiTheme.ids.TicketTypeTitle}
            >
              (
              <UIFormattedNumber value={ticket.price} style="currency" currency={pathOr('eur', ['currency'])(ticket)} />
              )
            </UIText>
          </UILayout>
        </UILayout>

        <UILayout sml={{ margin: '0 10px' }} shrink="10">
          <UIIncDecButton
            disabled={disabled}
            select={(count) => changeItemQtty({ ...ticket, count, isAddon })}
            initValue={ticket.count || 0}
            ticketsCount={ticket.count || 0}
            maxValue={maxValue}
            maxReservesValue={0}
            testPrefix={icon}
            maxAvailableMessage={intl.formatMessage(messages.maxAvailable)}
            disabledInput={muiTheme.ids.IncDecButton && muiTheme.ids.IncDecButton.disabledInput}
          />
        </UILayout>
      </UILayout>
    )
  }
}

export default SingleItem
