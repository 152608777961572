/* eslint-disable max-lines-per-function */
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { any, compose, defaultTo, find, isEmpty, pathOr, prop, propEq, propOr, sum, takeLast, last } from 'ramda'
import { UIIcon, UILayout, UIText } from 'bora-material-ui'

import { CUSTOM_WEIGHT_FILL_COLOR, EditWeightPencil, RowInfo, TableRow } from './Layout'
import { getSortedVehiclesOnly, sortAllItems } from '../../../../../../../utils/ramda-extend'

import { setModalData, showModal } from '../../../../../../../actions/index'
import { selectors } from '../../../../../provider'
import messages from '../../../../../../../consts/messages'
import { addHotelsToTransferAddons } from '../../../../../../../utils/addonUtils'

import EditModeButton from './EditModeButton'
import VehicleData from './VehicleData'
import {
  getAttributeFromRoute,
  vehiclesWeightEditAllowed,
} from '../../../../../../../services/pricing/reducers/selectors'
import { THEME } from 'bora-material-ui/themes'
import { getStyle, isLiinilaevad } from '../../../../../../../utils/liinilaevadThemeUtils'

import { LLDiscountSummary } from '../../../../../forms/TicketsForm/components/LLTicketPromotionLine'
import {
  getBookingConditionsForRoute,
  isCurrentResLaaksaareRoute,
} from '../../../../../../../services/reservation/selectors'
import { getMaritimeStyle, isMaritime, isBluewave } from '../../../../../../../utils/maritimeStyleUtils'
import { splitTitle } from '../../../../../../../utils/strings'
import {
  getFirstSelectedSailPackage,
  getSelectedRoute,
  isRoundTrip as getIsRoundTrip,
} from '../../../../../../../services/user-selections'
import cn from 'classnames'
import moduleStyles from './BaseModule.module.scss'
import { getShowProductsAttributeValue } from '../../../../../../../utils/attributesUtils'
import TicketRowInfo from './TicketRowInfo'
import { makeLabelsForTicketRow } from './utils'

const xsStyleTickets = { width: '100%', padding: '10px 0', borderBottom: '1px solid #bfc3c7', marginBottom: '15px' }
const xsStyleTicketsHeader = window.brandProps.theme === THEME.SAAREMAA ? { column: true } : {}
const xsStyleWay = { size: '18px' }

const styles = {
  marginBottom15: { margin: '0 0 15px 0' },
  flexEndMarginBottom15: { 'j-flex-end': 'true', margin: '0 0 20px 0' },
}

const xsStyles = {
  padding0: { padding: '0' },
  wrap: { whiteSpace: 'wrap' },
  paddingVertical15: { padding: '15px 0px' },
}

const getColorText = () => {
  if (isBluewave) {
    return '#0168A3'
  }

  return '#2D3955'
}

export const promoFilter = (item) => {
  if (window.brandProps.theme !== THEME.LIINILAEVAD) return true
  return !item.promotion
}

export const discountReducer = (acc, item) => {
  if (item.promotion) {
    const discount = item.price
    const promoKey = item.promotion

    const accDiscount = acc[promoKey] || 0
    return {
      [promoKey]: sum([accDiscount, discount]),
    }
  }

  return acc
}

export const findWeight = (allVehicles) => (seqN, sailRefId) => {
  const getDefaultWeight = compose(prop('weightInKg'), find(propEq('seqN', seqN)))

  const app = compose(
    defaultTo(0),
    prop('weightInKg'),
    find(propEq('sailRefId', sailRefId)),
    propOr([], 'weightOnSails'),
    find(propEq('seqN', seqN))
  )

  return {
    custom: app(allVehicles),
    former: getDefaultWeight(allVehicles),
  }
}

export const CustomWeightTip = ({ shouldShow = false }) => (
  <UILayout data-testid="custom-weight-tip" display-if={shouldShow}>
    <UIText
      ellipsis={false}
      whiteSpace="normal"
      align="left"
      color={CUSTOM_WEIGHT_FILL_COLOR}
      size="14px"
      translate={messages.howToEditVehicleWeight1}
    />
    <EditWeightPencil margin="0 5px" />
    <UIText
      ellipsis={false}
      whiteSpace="normal"
      align="left"
      color={CUSTOM_WEIGHT_FILL_COLOR}
      size="14px"
      translate={messages.howToEditVehicleWeight2}
    />
  </UILayout>
)

// eslint-disable-next-line complexity
const TripTicket = (props, { intl = false, muiTheme }) => {
  const {
    trip,
    reservation,
    dataTest,
    onEdit,
    onDelete,
    vehicles,
    width,
    isTwoWayTrip,
    editMode,
    userNeedToPayAfterEdit,
    localTravellerInfo,
    showModal,
    setModalData,
    customWeightMap = {},
    shouldHideActions = false,
    isLaaksaare = false,
    outOfBookingBoundary = true,
    showBorderBottomForMobile,
    isReallyTaxi,
    isFullDay,
    hideEditButton,
    locale,
  } = props
  const { leg, sailRefs, items: itemsFromTrip, guests, title: tripTitle, titles: tripTitles } = trip
  const translatedTripTitle = tripTitles && tripTitles[locale] ? tripTitles[locale] : tripTitle
  const spltedTitleFromLeg = leg ? splitTitle(leg.titles && leg.titles[locale] ? leg.titles[locale] : leg.title) : []
  const [departure = '', destination = ''] = isReallyTaxi ? splitTitle(translatedTripTitle) : spltedTitleFromLeg
  const { departureAt } = sailRefs[0] || {}
  const items = addHotelsToTransferAddons(itemsFromTrip, reservation)
  const sortedItems = sortAllItems(items)
  const personalIdentificationNumber = selectors.findIdNums(guests)
  const vehiclesItems = getSortedVehiclesOnly(items)
  const localVehicles = vehiclesItems.filter((veh) => takeLast(2, veh.priceCategory) === '-R')
  const getArrivalTerminal = compose(pathOr(destination, ['portTo', 'titles', locale]), last)

  const arrivalPoint = getArrivalTerminal(sailRefs)

  const discountSummaryObj = window.brandProps.theme !== THEME.LIINILAEVAD ? null : items.reduce(discountReducer, {})

  const showPencilTip = any(({ priceCategory = '' }) => customWeightMap[priceCategory])(vehiclesItems)

  const showLocalIncentives = !isEmpty(personalIdentificationNumber) || !isEmpty(localVehicles)

  const editTicketHandler = () => onEdit({ ...trip, localIDs: personalIdentificationNumber, vehicles })
  const deleteTicketHandler = () => onDelete({ trip })

  const ticketTableHeaders = isMaritime
    ? [
        intl.formatMessage(messages.ticketType),
        intl.formatMessage(messages.ticketQtty),
        intl.formatMessage(messages.ticketPrice),
        intl.formatMessage(messages.ticketVat),
        intl.formatMessage(messages.ticketSubtotal),
      ]
    : [
        intl.formatMessage(messages.ticketType),
        intl.formatMessage(messages.ticketQtty),
        intl.formatMessage(messages.ticketPrice),
        intl.formatMessage(messages.ticketSubtotal),
      ]

  const lockTickets = editMode === 'datetime'

  if (isMaritime) {
    return (
      <UILayout
        data-testid={dataTest}
        sm={{ ...xsStyleTickets, ...(showBorderBottomForMobile ? {} : { borderBottom: 'none' }) }}
        column
        className={cn(moduleStyles.formDirection)}
      >
        <UILayout>
          <UILayout
            j-flex-end
            padding="0 15px 7px 0"
            xs={{ padding: '0 15px 15px 0' }}
            sm={xsStyles.padding0}
            data-testid="edit-remove-block"
            display-if={!editMode && !shouldHideActions}
          >
            <UILayout
              display-if={!hideEditButton && !isBluewave}
              width="auto"
              center
              j-flex-end
              cursor="pointer"
              onClick={editTicketHandler}
            >
              <UIIcon
                type="iconPencil"
                width="14px"
                height="14px"
                fill={
                  muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.edit
                    ? muiTheme.ids.Confirmation.edit.color
                    : '#79909b'
                }
                margin="0 4px 4px 0"
              />
              <UIText
                color={
                  muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.edit
                    ? muiTheme.ids.Confirmation.edit.color
                    : '#79909b'
                }
                size="16px"
                font={muiTheme.secondFontFamilyDem}
                textTransform="uppercase"
                translate={messages.editTrip}
                xs={0}
                {...(muiTheme.ids.Confirmation &&
                  muiTheme.ids.Confirmation.rowText && { ...muiTheme.ids.Confirmation.rowText })}
              />
            </UILayout>
            <UILayout
              padding={locale === 'ar' ? '0 20px 0 0' : '0 0 0 20px'}
              width="auto"
              j-flex-end
              center
              cursor="pointer"
              display-if={isTwoWayTrip}
              onClick={deleteTicketHandler}
            >
              <UIIcon
                type="iconCross"
                width="14px"
                height="14px"
                fill={
                  muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.remove
                    ? muiTheme.ids.Confirmation.remove.color
                    : '#d0021b'
                }
                margin="0 4px 4px 0"
              />
              <UIText
                color={
                  muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.remove
                    ? muiTheme.ids.Confirmation.remove.color
                    : '#79909b'
                }
                size="16px"
                font={muiTheme.secondFontFamilyDem}
                textTransform="uppercase"
                translate={messages.removeTrip}
                sm={0}
                {...(muiTheme.ids.Confirmation &&
                  muiTheme.ids.Confirmation.rowText && { ...muiTheme.ids.Confirmation.rowText })}
              />
            </UILayout>
          </UILayout>
        </UILayout>
        <UILayout
          data-testid="confirmation-trip-data"
          className={cn(moduleStyles.formDirection, moduleStyles.withColumnGap)}
          sm={getMaritimeStyle({ flexDirection: 'column' })}
        >
          <UILayout
            j-flex-space-between
            margin="0 0 12px 0"
            data-testid="ticket-info-header"
            position="relative"
            style={{
              borderBottom: editMode ? '1px solid #bfc3c7' : '0',
              paddingBottom: editMode ? '18px' : '0',
            }}
            smm={xsStyleTicketsHeader}
            width="50%"
            sm={getMaritimeStyle({ width: '100%' })}
          >
            <UILayout>
              <UILayout column width="60%">
                <UIText
                  size="23px"
                  color={getColorText}
                  font={muiTheme.secondFontFamily}
                  align={locale === 'ar' ? 'right' : 'left'}
                  sm={xsStyleWay}
                  {...(muiTheme.ids.Confirmation &&
                    muiTheme.ids.Confirmation.tripText && { ...muiTheme.ids.Confirmation.tripText })}
                >
                  {isFullDay ? translatedTripTitle : departure}
                </UIText>
                <UIIcon
                  display-if={!isFullDay}
                  type="iconArrowDown_admaritime"
                  height="30px"
                  minWidth="20px"
                  margin="10px 0"
                  fill={
                    muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.tripText
                      ? muiTheme.ids.Confirmation.tripText.color
                      : '#2c4684'
                  }
                />
                <UIText
                  display-if={!isFullDay}
                  size="23px"
                  color={
                    muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.tripText
                      ? muiTheme.ids.Confirmation.tripText.color
                      : getColorText()
                  }
                  font={muiTheme.secondFontFamily}
                  align={locale === 'ar' ? 'right' : 'left'}
                  sm={xsStyleWay}
                  {...(muiTheme.ids.Confirmation &&
                    muiTheme.ids.Confirmation.tripText && { ...muiTheme.ids.Confirmation.tripText })}
                >
                  {arrivalPoint}
                </UIText>

                <UIIcon type="iconLock" margin="0 0 0 10px" display-if={editMode} />
              </UILayout>
              <UIText
                size="23px"
                color={
                  muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.tripText
                    ? muiTheme.ids.Confirmation.tripText.color
                    : getColorText()
                }
                align={locale === 'ar' ? 'right' : 'left'}
                font={muiTheme.secondFontFamily}
                textTransform="uppercase"
                sm={xsStyleWay}
              >
                {moment(departureAt)
                  .locale(intl.locale)
                  .format(`MMMM D, YYYY ${isMaritime ? 'LT' : 'HH:mm'}`)}
              </UIText>
            </UILayout>

            <EditModeButton display={editMode && !userNeedToPayAfterEdit} onEdit={() => onEdit('datetime')} />
          </UILayout>

          <EditModeButton
            display={editMode && !userNeedToPayAfterEdit}
            lock={lockTickets}
            position="static"
            additionalStyles={styles.flexEndMarginBottom15}
            onEdit={() => onEdit('tickets')}
          />

          <UILayout column width="50%" sm={getMaritimeStyle({ width: '100%' })}>
            <UILayout column>
              <TicketRowInfo isHeader items={ticketTableHeaders} />
              {sortedItems.map((item) => {
                const rowLabels = makeLabelsForTicketRow(item)
                return <TicketRowInfo key={item.priceCategory} items={rowLabels} />
              })}
            </UILayout>

            <UILayout
              padding="15px 25px 15px 0"
              xs={xsStyles.paddingVertical15}
              borderTop="solid 1px #bfc3c7"
              display-if={showLocalIncentives && !editMode}
              column
            >
              <UIText
                whiteSpace="nowrap"
                xs={xsStyles.wrap}
                color="#bfc3c7"
                size="16px"
                align={locale === 'ar' ? 'right' : 'left'}
                margin="0 0 10px 0"
                translate={messages.localIncentivesApplied}
              />
              <UILayout column>
                {localVehicles.length > 0 && (
                  <UIText color="#000" size="16px" align={locale === 'ar' ? 'right' : 'left'} margin="12px 0px">
                    Vehicle:{' '}
                  </UIText>
                )}
                {localVehicles.map((veh) => {
                  const seqN = pathOr('', ['ownerSeqNs', [0]])(veh)
                  const [localVehInfo] = vehicles.filter((item) => item.seqN === seqN)

                  return (
                    <VehicleData
                      vehicleNumber={localVehInfo.licencePlate}
                      carWidth={`${localVehInfo.widthInCm / 100} m`}
                      carHeight={`${localVehInfo.heightInCm / 100} m`}
                      carWeight={`${localVehInfo.weightInKg / 1000} t`}
                      carLength={`${localVehInfo.lengthInCm / 100} m`}
                      isLocal
                      vehicleType={veh.priceCategorySubType === 'TRAILER' ? 'trailer' : 'car'}
                    />
                  )
                })}
                {!isEmpty(personalIdentificationNumber) && (
                  <UIText color="#000" size="16px" align="left" margin="12px 0px 0 0">
                    ID:{' '}
                  </UIText>
                )}
                {!isEmpty(personalIdentificationNumber) &&
                  personalIdentificationNumber.map((num) => (
                    <UIText color="#000" size="16px" align="left" margin="10px 0px">
                      {num}
                    </UIText>
                  ))}
              </UILayout>
            </UILayout>
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }

  return (
    <UILayout data-testid={dataTest} width={width} column sm={xsStyleTickets}>
      <UILayout
        j-flex-space-between
        margin="0 0 12px 0"
        position="relative"
        style={{
          borderBottom: editMode ? '1px solid #bfc3c7' : '0',
          paddingBottom: editMode ? '18px' : '0',
        }}
        smm={xsStyleTicketsHeader}
      >
        <UILayout column>
          <UILayout center>
            <UIText
              size={getStyle('20px', '23px')}
              color={getStyle('#0000A0', '#2c4684')}
              font={getStyle(muiTheme.secondFontFamily, muiTheme.secondFontFamilyDem)}
              textTransform={getStyle(undefined, 'uppercase')}
              width={getStyle('min-content')}
              whiteSpace={getStyle('nowrap')}
              sm={xsStyleWay}
              {...(muiTheme.ids.Confirmation &&
                muiTheme.ids.Confirmation.tripText && { ...muiTheme.ids.Confirmation.tripText })}
            >
              {departure}
            </UIText>
            <UIIcon
              type="iconChevron"
              height="30px"
              minWidth="20px"
              margin="0 10px"
              fill={getStyle(
                '#0000A0',
                muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.tripText
                  ? muiTheme.ids.Confirmation.tripText.color
                  : '#2c4684'
              )}
            />
            <UIText
              size={getStyle('20px', '23px')}
              color={getStyle(
                '#0000A0',
                muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.tripText
                  ? muiTheme.ids.Confirmation.tripText.color
                  : '#2c4684'
              )}
              width={getStyle('min-content')}
              whiteSpace={getStyle('nowrap')}
              font={getStyle(muiTheme.secondFontFamily, muiTheme.secondFontFamilyDem)}
              textTransform={getStyle(undefined, 'uppercase')}
              sm={xsStyleWay}
              {...(muiTheme.ids.Confirmation &&
                muiTheme.ids.Confirmation.tripText && { ...muiTheme.ids.Confirmation.tripText })}
            >
              {destination}
            </UIText>

            <UIIcon type="iconLock" margin="0 0 0 10px" display-if={editMode} />
          </UILayout>
          <UIText
            size={getStyle('20px', '23px')}
            color={getStyle(
              '#0000A0',
              muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.tripText
                ? muiTheme.ids.Confirmation.tripText.color
                : '#2c4684'
            )}
            align="left"
            font={getStyle(muiTheme.secondFontFamily, muiTheme.secondFontFamilyDem)}
            textTransform="uppercase"
            sm={xsStyleWay}
          >
            {moment(departureAt)
              .locale(intl.locale)
              .format(`MMMM D, YYYY ${isMaritime ? 'LT' : 'HH:mm'}`)}
          </UIText>
        </UILayout>

        <EditModeButton display={editMode && !userNeedToPayAfterEdit} onEdit={() => onEdit('datetime')} />

        <UILayout
          column
          j-flex-space-between
          padding="0 20px 0 0"
          sm={xsStyles.padding0}
          data-testid="edit-remove-block"
          display-if={!editMode && !shouldHideActions}
        >
          <UILayout
            center
            j-flex-end
            cursor="pointer"
            onClick={editTicketHandler}
            display-if={!isTwoWayTrip || !isLiinilaevad}
          >
            <UIIcon
              type="iconPencil"
              width="14px"
              height="14px"
              fill={getStyle(
                '#3387CC',
                muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.edit
                  ? muiTheme.ids.Confirmation.edit.color
                  : '#79909b'
              )}
              margin="0 4px 0 0"
            />
            <UIText
              color={getStyle(
                '#3387CC',
                muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.edit
                  ? muiTheme.ids.Confirmation.edit.color
                  : '#79909b'
              )}
              size="18px"
              font={getStyle('SourceSansPro, Sans-Serif', muiTheme.secondFontFamilyDem)}
              weight={getStyle('bold')}
              textTransform="uppercase"
              translate={messages.editTrip}
              xs={0}
              {...(muiTheme.ids.Confirmation &&
                muiTheme.ids.Confirmation.rowText && { ...muiTheme.ids.Confirmation.rowText })}
            />
          </UILayout>
          <UILayout
            j-flex-end
            center
            cursor="pointer"
            display-if={!isLiinilaevad && isTwoWayTrip}
            onClick={deleteTicketHandler}
          >
            <UIIcon
              type="iconCross"
              width="14px"
              height="14px"
              fill={getStyle(
                '#D11800',
                muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.remove
                  ? muiTheme.ids.Confirmation.remove.color
                  : '#d0021b'
              )}
              margin="0 4px 0 0"
            />
            <UIText
              color={getStyle(
                '#D11800',
                muiTheme.ids.Confirmation && muiTheme.ids.Confirmation.remove
                  ? muiTheme.ids.Confirmation.remove.color
                  : '#79909b'
              )}
              size="18px"
              font={getStyle('SourceSansPro, Sans-Serif', muiTheme.secondFontFamilyDem)}
              weight={getStyle('bold')}
              textTransform="uppercase"
              translate={messages.removeTrip}
              sm={0}
              {...(muiTheme.ids.Confirmation &&
                muiTheme.ids.Confirmation.rowText && { ...muiTheme.ids.Confirmation.rowText })}
            />
          </UILayout>
        </UILayout>
      </UILayout>

      <EditModeButton
        display={editMode && !userNeedToPayAfterEdit}
        lock={lockTickets}
        position="static"
        additionalStyles={styles.flexEndMarginBottom15}
        onEdit={() => onEdit('tickets')}
      />

      <RowInfo isHeader labels={ticketTableHeaders} locale={locale} />
      <UILayout column childrenStyle={styles.marginBottom15} data-testid="table-rows-wrap">
        {sortedItems.filter(promoFilter).map((item) => (
          <TableRow
            hideZeroPrice={isLaaksaare && !outOfBookingBoundary}
            showModal={showModal}
            setModalData={setModalData}
            key={item.priceCategory}
            item={item}
            localTravellerInfo={localTravellerInfo}
            findWeight={findWeight(vehicles)}
            customWeightMap={customWeightMap}
          />
        ))}
        <LLDiscountSummary ticketView shouldShow={discountSummaryObj} promoDiscountSummary={discountSummaryObj} />
        <CustomWeightTip shouldShow={showPencilTip} />
      </UILayout>
      <UILayout
        padding="15px 25px 15px 0"
        xs={xsStyles.paddingVertical15}
        borderTop="solid 1px #bfc3c7"
        display-if={showLocalIncentives && !editMode}
        column
      >
        <UIText
          whiteSpace="nowrap"
          xs={xsStyles.wrap}
          color="#bfc3c7"
          size="16px"
          align="left"
          margin="0 0 10px 0"
          translate={messages.localIncentivesApplied}
        />
        <UILayout column>
          {localVehicles.length > 0 && (
            <UIText color="#000" size="16px" align="left" margin="12px 0px">
              Vehicle:{' '}
            </UIText>
          )}
          {localVehicles.map((veh) => {
            const seqN = pathOr('', ['ownerSeqNs', [0]])(veh)
            const [localVehInfo] = vehicles.filter((item) => item.seqN === seqN)

            return (
              <VehicleData
                vehicleNumber={localVehInfo.licencePlate}
                carWidth={`${localVehInfo.widthInCm / 100} m`}
                carHeight={`${localVehInfo.heightInCm / 100} m`}
                carWeight={`${localVehInfo.weightInKg / 1000} t`}
                carLength={`${localVehInfo.lengthInCm / 100} m`}
                isLocal
                vehicleType={veh.priceCategorySubType === 'TRAILER' ? 'trailer' : 'car'}
              />
            )
          })}
          {!isEmpty(personalIdentificationNumber) && (
            <UIText color="#000" size="16px" align="left" margin="12px 0px 0 0">
              ID:{' '}
            </UIText>
          )}
          {!isEmpty(personalIdentificationNumber) &&
            personalIdentificationNumber.map((num) => (
              <UIText color="#000" size="16px" align="left" margin="10px 0px">
                {num}
              </UIText>
            ))}
        </UILayout>
      </UILayout>
    </UILayout>
  )
}

TripTicket.propTypes = {
  trip: PropTypes.object.isRequired,
  dataTest: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  vehicles: PropTypes.object,
  width: PropTypes.string,
  isTwoWayTrip: PropTypes.bool,
  editMode: PropTypes.bool,
  userNeedToPayAfterEdit: PropTypes.bool,
  localTravellerInfo: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  setModalData: PropTypes.func.isRequired,
  customWeightMap: PropTypes.object.isRequired,
  shouldHideActions: PropTypes.bool,
  isLaaksaare: PropTypes.bool,
  showBorderBottomForMobile: PropTypes.bool,
}

TripTicket.defaultProps = {
  width: '50%',
  isTwoWayTrip: false,
  editMode: false,
  isLaaksaare: false,
  vehicles: {},
  userNeedToPayAfterEdit: false,
  localTravellerInfo: {},
  shouldHideActions: false,
  showBorderBottomForMobile: true,
}

TripTicket.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
}

export default connect(
  (state) => {
    const { taxi, fullday, attributes } = getFirstSelectedSailPackage(state) || {}
    const isReallyTaxi = taxi && getShowProductsAttributeValue(attributes)
    const isFullDay = Boolean(fullday)

    const selectedRoute = getSelectedRoute(state)
    const isRoundTrip = getIsRoundTrip(state)
    const hideEditOnConfirmPageAttributeValue = getAttributeFromRoute(selectedRoute, 'HIDE_EDIT_ON_CONFIRM_PAGE')(state)
    const hideEditButton = hideEditOnConfirmPageAttributeValue && isRoundTrip

    return {
      isLaaksaare: isCurrentResLaaksaareRoute(state),
      localTravellerInfo: pathOr({}, ['user', 'localTraveller'])(state),
      customWeightMap: vehiclesWeightEditAllowed(state),
      outOfBookingBoundary: getBookingConditionsForRoute(state),
      isReallyTaxi,
      isFullDay,
      locale: state.locale,
      reservation: {
        ...(state.reservation.current || {}),
      },
      hideEditButton,
    }
  },
  { showModal, setModalData }
)(TripTicket)
