import React from 'react'
import PropTypes from 'prop-types'

import { UIText, UIButton, UILayout, UIIcon } from 'bora-material-ui'
import { getMaritimeStyle, isMaritime } from '../../../../utils/maritimeStyleUtils'

const FacebookButton = ({ onClick, translate, margin, xs }, { muiTheme }) => (
  <UIButton
    width="100%"
    height="64px"
    click={onClick}
    borderRadius={getMaritimeStyle('6px', '4px')}
    xs={{ width: '100%', ...xs }}
    background={getMaritimeStyle('#13C5BD', '#4568b2')}
    margin={margin}
  >
    <UILayout column j-flex-center lineHeight="30px" height="100%" style={{ flexBasis: '0' }}>
      <UIText align="left" size="24px">
        <UILayout row center>
          <UIIcon
            style={{ position: 'absolute' }}
            fill="white"
            type={isMaritime ? 'iconFB_admaritime' : 'iconFB'}
            margin="0 11px 0 11px"
          />
          <UIText
            align="center"
            width="100%"
            size="18px"
            xs={{ size: '20px' }}
            lineHeight="1"
            letterSpacing="0.8px"
            weight="bold"
            translate={translate}
            textTransform="uppercase"
            font={muiTheme.secondFontFamilyDem}
          />
        </UILayout>
      </UIText>
    </UILayout>
  </UIButton>
)

FacebookButton.contextTypes = {
  muiTheme: PropTypes.object,
}

FacebookButton.defaultProps = {
  margin: '0px',
  xs: {},
}

FacebookButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  translate: PropTypes.object.isRequired,
  margin: PropTypes.string,
  xs: PropTypes.any,
}

export default FacebookButton
