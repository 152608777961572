import React from 'react'
import { /* UIButton, UIIcon, */ UILayout } from 'bora-material-ui'
import messages from '../../../../consts/messages'

import styles from './VehicleFormTypeTabs.module.scss'

// const buttonXs = { width: '28px', height: '28px' }

export const VehicleFormTypeTabs = ({
  inputType,
  SIMPLE_TYPE,
  className,
  classNameActive,
  setInputType,
  isManuallyTicket,
  intl,
  // changeIsActive,
  ADVANCED_TYPE,
  isFormInappropriate,
  plateNumberNotFound,
  isSearchFailed,
}) => {
  const buttonBackgroundColor = window.brandProps.muiTheme.palette.selectedItem
  return (
    <div data-testid="form-tabs-wrapper" className={styles.wrapper}>
      <UILayout className="trailers-switcher">
        <button
          className={inputType !== SIMPLE_TYPE ? className : classNameActive}
          onClick={() => setInputType(SIMPLE_TYPE)}
          disabled={isManuallyTicket}
          style={{
            ...(buttonBackgroundColor && {
              'background-color': inputType === SIMPLE_TYPE ? buttonBackgroundColor : 'rgba(0,0,0,0)',
            }),
          }}
        >
          {intl.formatMessage(messages.siplifiedForm)}
        </button>
        <button
          className={inputType !== ADVANCED_TYPE ? className : classNameActive}
          onClick={() => setInputType(ADVANCED_TYPE)}
          disabled={!(isFormInappropriate || plateNumberNotFound) || !isSearchFailed}
          style={{
            ...(buttonBackgroundColor && {
              'background-color': inputType === ADVANCED_TYPE ? buttonBackgroundColor : 'rgba(0,0,0,0)',
            }),
          }}
        >
          {intl.formatMessage(messages.inputManually)}
        </button>
      </UILayout>
      {/* <UIButton
        className={styles.minusButton}
        type="circle"
        xs={buttonXs}
        click={() => changeIsActive(false)}
        background="#e5e5e5"
      >
        <UIIcon type="remove" fill="white" />
      </UIButton> */}
    </div>
  )
}
