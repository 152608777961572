import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { keys, prop } from 'ramda'
import { change, Field, getFormValues, reduxForm } from 'redux-form'
import moment from 'moment/moment'
import { UILayout } from 'bora-material-ui'

import getDepartureFields from '../components/DepartureFormFields'

import { DATE_FORMAT } from '../../../consts/stuff'

import { actions, selectors } from '../provider'
import { getMaritimeStyle, isMaritime } from '../../../utils/maritimeStyleUtils'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'
import { isMobile } from '../../Common/components/AppBar'
import { getSailDates } from '../../../services/user-selections'

const xsStyles = {
  column: { column: true, row: false, margin: getStyle('20px 0 0 0') },
}

const DepartureForm = (props) => {
  const {
    fields,
    sailDate,
    select,
    name,
    locale,
    shouldShow,
    toggleShouldShow,
    scrollToAvailableTickets,
    onlyAvailableBackDate,
  } = props

  moment.locale(locale)

  const mergedFields = fields.map((fld) => ({
    ...fld,
    name,
    value: sailDate,
    onlyAvailableBackDate,
    select: (date) => {
      select(moment(date).locale(locale).format(DATE_FORMAT))
      if (isMaritime) {
        toggleShouldShow()
      }
      if (isMaritime && isMobile) {
        scrollToAvailableTickets()
      }
    },
  }))

  return (
    <UILayout
      data-testid={`departure-form${name.toLowerCase().includes('back') ? '-back' : ''}`}
      style={getMaritimeStyle({
        position: 'absolute',
        visibility: !shouldShow && isMaritime && 'hidden',
        top: 90,
        left: 0,
        zIndex: 1000,
      })}
      column
      width={getMaritimeStyle('100%', 'auto')}
      xs={xsStyles.column}
    >
      {mergedFields.map((field) => (
        <Field key={field.name} {...field} showIcon={field.id === 'calendar'} />
      ))}
    </UILayout>
  )
}

DepartureForm.propTypes = {
  shouldShow: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  sailDate: PropTypes.string,
  select: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onEditMode: PropTypes.bool.isRequired,
  isTaxiRide: PropTypes.bool.isRequired,
}

export default connect(
  (state) => {
    return {
      locale: state.locale,
      mediaName: state.mediaName,
      availableSailsDates: selectors.getSailsAvailableDates(state),
      availableSailsDatesForEdit: selectors.selectScheduleState(state).availableDates,
      departureValues: getFormValues('departure')(state),
      sailDates: getSailDates(state),
    }
  },
  (dispatch) => bindActionCreators({ ...actions, change }, dispatch),
  (stateProps, dispatchProps, ownProps) => {
    const allProps = { ...stateProps, ...dispatchProps, ...ownProps }
    const { onEditMode = false, isTaxiRide } = ownProps

    const [firstSelectedDate] = stateProps.sailDates

    if (onEditMode) {
      const [sailCodeThere, sailCodeBack] = keys(allProps.availableSailsDatesForEdit)

      allProps.availableSailsDatesForEdit = {
        [sailCodeThere]: prop(sailCodeThere)(allProps.availableSailsDatesForEdit),
        [sailCodeBack]: prop(sailCodeBack)(allProps.availableSailsDatesForEdit),
      }
    }

    const fields = getDepartureFields(allProps)

    const initialValues = allProps.departureValues || {}

    if (allProps.name && allProps.sailDate) {
      initialValues[allProps.name] = moment(allProps.sailDate)
    }

    return { ...allProps, fields, initialValues, onlyAvailableBackDate: isMaritime && isTaxiRide && firstSelectedDate }
  }
)(
  reduxForm({
    form: 'departure',
    destroyOnUnmount: false,
  })(DepartureForm)
)
