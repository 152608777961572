import React from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIButton, UIText } from 'bora-material-ui'

import messages from '../../../../../consts/messages'
import { getStyle } from '../../../../../utils/liinilaevadThemeUtils'
import { getMaritimeStyle } from '../../../../../utils/maritimeStyleUtils'

const xsStyles = {
  button: { margin: '0px' },
}

const VehicleConfirmButton = ({ enabled, showDisabledMessage, onClick, disabledMessage, margin }, { muiTheme }) => (
  <UIButton
    onClick={onClick}
    disabled={!enabled}
    height="auto"
    width="100%"
    borderRadius="2px"
    margin={margin}
    background={getStyle('#7ed321')}
    xs={xsStyles.button}
  >
    <UILayout
      data-testid="vehicle-confirm-button"
      row
      j-flex-center
      minHeight="76px"
      height="100%"
      width="100%"
      bgColor="transparent"
      center
    >
      <UIText
        size={getMaritimeStyle('14px', '19px')}
        color="white"
        padding="13px 15px"
        textTransform="uppercase"
        letterSpacing="0.6px"
        lineHeight="1.16"
        translate={showDisabledMessage ? disabledMessage : messages.confirmDataForVehicle}
        font={muiTheme.secondFontFamilyDem}
      />
    </UILayout>
  </UIButton>
)

VehicleConfirmButton.contextTypes = {
  muiTheme: PropTypes.object,
}

VehicleConfirmButton.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabledMessage: PropTypes.any.isRequired,
  margin: PropTypes.any,
}

VehicleConfirmButton.defaultProps = {
  margin: '0',
}

export default VehicleConfirmButton
