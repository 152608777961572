import * as R from 'ramda'

const valuePath = ['structure', 'value', 'value']

const getAttributeValue = (attributeName) =>
  R.compose(
    R.pathOr(false, valuePath),
    R.head,
    R.filter((a) => a.attribute.code === attributeName),
    R.propOr([], ['attributes'])
  )

export const getHiddenCategoryAttributeValue = getAttributeValue('HIDDEN_IN_ONLINE')
export const getDriverCategoryAttributeValue = getAttributeValue('DRIVER')

export const isNotHiddenCategory = R.compose(R.not, getHiddenCategoryAttributeValue)

export const getPriceAttributes = R.compose(
  R.map((a) => ({
    code: a.attribute.code,
    value: R.pathOr(null, valuePath),
  })),
  R.propOr([], ['attributes'])
)
