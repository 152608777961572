import React from 'react'
import PropTypes from 'prop-types'

import { compose, isNil, not, prop } from 'ramda'
import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import { THEME } from 'bora-material-ui/themes'
import { getStyle } from '../../../../../utils/liinilaevadThemeUtils'
import { isGavdos } from '../../../../../utils/gavdosStyleUtils'
import { isMaritime, isBluewave } from '../../../../../utils/maritimeStyleUtils'
import moduleStyles from '../../MaritimeDepartureForm/MaritimeDepartureForm.module.scss'
import cn from 'classnames'

const isNotNull = compose(not, isNil)

const xsStyles = {
  time: { width: '70px', padding: '0 1rem 0 5px !important' },
  width75: { width: '75px' },
  width85: { width: '85px' },
  size16: { size: '16px' },
}

class TicketRowHeader extends React.PureComponent {
  // eslint-disable-next-line complexity
  render() {
    const { muiTheme } = this.context
    const {
      availability,
      hasOnlySeatsAndBikes,
      hasOnlySeatsAndCars,
      hasOnlyPassengers = false,
      supportCars,
      supportPassengers,
      supportTrucks,
      supportBicycles,
    } = this.props

    const passengerAvailability = prop('Passenger', availability)
    const bikeAvailability = prop('Bike', availability)
    const carAvailability = prop('Car', availability)
    const totalReserves = prop('totalReserves', availability)
    return (
      <UILayout
        display-if={!isGavdos}
        width="100%"
        height="30px"
        data-testid="ticket-row-header"
        xs={{ 'j-flex-space-between': true }}
        className={moduleStyles.headRow}
      >
        <UILayout
          display-if={!isBluewave}
          data-testid="clock-icon"
          column
          flex-start
          j-flex-center
          shrink={0}
          padding={'0 1.5rem'}
          xs={
            hasOnlySeatsAndBikes || hasOnlyPassengers || hasOnlySeatsAndCars
              ? { ...xsStyles.time, ...xsStyles.width85 }
              : xsStyles.time
          }
          style={{ ...muiTheme.ids.ticketRow, ...muiTheme.ids.timePosition }}
          className={moduleStyles.headRowItem}
        >
          <UIIcon
            type="iconTime"
            fill={getStyle(
              '#0000D5',
              muiTheme.ids.tripHeaderDoubleRow && muiTheme.ids.tripHeaderDoubleRow.separator
                ? muiTheme.ids.tripHeaderDoubleRow.separator.color
                : '#79909B'
            )}
            className={moduleStyles.largeMarginStart}
            minWidth="33px"
            height="20px"
            xs={{ margin: 0 }}
          />
        </UILayout>
        <UILayout
          display-if={supportPassengers}
          column
          center
          j-flex-center
          width="110px"
          xs={xsStyles.width75}
          style={{ ...muiTheme.ids.ticketRow }}
          className={cn(moduleStyles.headRowItem, moduleStyles.passengers)}
        >
          <UILayout
            row
            center
            j-flex-center
            width="100%"
            style={{
              ...muiTheme.ids.ticketRow,
              ...muiTheme.ids.passengerPosition,
              paddingLeft: muiTheme.name === THEME.KIHNU ? '7px' : '0',
            }}
            data-testid="inventory-passenger-icon"
          >
            <UIIcon
              className={cn(moduleStyles.rtlIcon)}
              type={muiTheme.iconPassenger || 'iconPassenger'}
              height="30px"
              fill={getStyle(
                '#0000D5',
                muiTheme.ids.tripHeaderDoubleRow && muiTheme.ids.tripHeaderDoubleRow.separator
                  ? muiTheme.ids.tripHeaderDoubleRow.separator.color
                  : ''
              )}
              xs={{ margin: 0 }}
            />
            <UIText
              display-if={
                muiTheme.name !== THEME.SAAREMAA &&
                muiTheme.name !== THEME.KIHNU &&
                muiTheme.name !== THEME.GAVDOS &&
                !isMaritime &&
                muiTheme.name !== THEME.LIINILAEVAD
              }
              size="18px"
              lineHeight="1"
              align="left"
              color="#9b9b9b"
              margin="0 0 0 4px"
              font={muiTheme.secondFontFamily}
              xs={xsStyles.size16}
            >
              {`${passengerAvailability || 0}${window.isNaN(totalReserves) ? '' : `(${totalReserves})`}`}
            </UIText>
          </UILayout>
        </UILayout>
        <UILayout
          data-testid="bicycle-icon-header"
          display-if={
            !muiTheme.disableBicycles &&
            isNotNull(bikeAvailability) &&
            !hasOnlyPassengers &&
            !hasOnlySeatsAndCars &&
            supportBicycles
          }
          column
          center
          j-flex-center
          width="110px"
          className={moduleStyles.headRowItem}
          xs={xsStyles.width75}
          style={{ ...muiTheme.ids.ticketRow }}
          {...(hasOnlySeatsAndBikes && { style: { marginRight: '20%' } })}
        >
          <UILayout row center j-flex-start width="100%" data-testid="inventory-bicycle-icon">
            <UIIcon
              className={moduleStyles.rtlIcon}
              type={muiTheme.iconBicycle || 'iconBike'}
              style={{ marginInlineStart: '3px' }}
              width="35px"
              height="22px"
              fill={getStyle(
                '#0000D5',
                muiTheme.ids.tripHeaderDoubleRow && muiTheme.ids.tripHeaderDoubleRow.separator
                  ? muiTheme.ids.tripHeaderDoubleRow.separator.color
                  : ''
              )}
            />
            <UIText
              display-if={
                muiTheme.name !== THEME.SAAREMAA &&
                muiTheme.name !== THEME.KIHNU &&
                muiTheme.name !== THEME.LIINILAEVAD &&
                !isMaritime
              }
              size="18px"
              lineHeight="1"
              align="left"
              color="#9b9b9b"
              margin="0 0 0 4px"
              font={muiTheme.secondFontFamily}
              xs={xsStyles.size16}
            >
              {bikeAvailability || 0}
            </UIText>
          </UILayout>
        </UILayout>
        <UILayout
          display-if={
            ((isNotNull(carAvailability) && !hasOnlyPassengers && hasOnlySeatsAndCars) ||
              (isNotNull(carAvailability) && !muiTheme.disableCars && !hasOnlySeatsAndBikes && !hasOnlyPassengers)) &&
            (supportCars || supportTrucks)
          }
          className={cn(moduleStyles.headRowItem, moduleStyles.passengers)}
          column
          center
          j-flex-center
          width={!supportPassengers && supportCars && supportTrucks ? '210px' : '110px'}
          xs={xsStyles.width85}
          style={{ ...muiTheme.ids.ticketRow }}
          {...(hasOnlySeatsAndCars && { style: { marginRight: '0' } })}
        >
          <UILayout
            data-testid="inventory-vehicle-icon"
            row
            center
            j-flex-center
            j-flex-start={!isMaritime || !(supportCars && supportTrucks)}
            j-flex-space-between={false}
            width="100%"
          >
            <UIIcon
              display-if={supportCars}
              className={cn(moduleStyles.rtlIcon, moduleStyles.smallMarginStart)}
              type={muiTheme.iconVehicle || 'iconCar'}
              width="36px"
              height="22px"
              fill={
                muiTheme.ids.tripHeaderDoubleRow && muiTheme.ids.tripHeaderDoubleRow.separator
                  ? muiTheme.ids.tripHeaderDoubleRow.separator.color
                  : ''
              }
            />
            <UIIcon
              display-if={supportTrucks}
              className={cn(moduleStyles.rtlIcon, moduleStyles.smallMarginStart)}
              type="iconTruck_admaritime"
              height="22px"
            />
            <UIText
              display-if={muiTheme.name !== THEME.SAAREMAA && muiTheme.name !== THEME.KIHNU && !isMaritime}
              size="18px"
              lineHeight="1"
              align="left"
              color="#9b9b9b"
              margin="0 0 0 4px"
              font={muiTheme.secondFontFamily}
              xs={xsStyles.size16}
            >
              {carAvailability || 0}
            </UIText>
          </UILayout>
        </UILayout>
        {/* Empty column space for selected tick */}
        <UILayout
          data-testid="inventory-empty-column"
          j-flex-center
          className={cn(moduleStyles.headRowItem, moduleStyles.empty)}
          xs={{ width: '35px' }}
          {...(muiTheme.name !== THEME.SAAREMAA &&
            !hasOnlyPassengers &&
            !hasOnlySeatsAndCars &&
            !hasOnlySeatsAndBikes &&
            !isNotNull(carAvailability) &&
            !isNotNull(bikeAvailability) && { style: { marginInlineEnd: '41%' } })}
        />
      </UILayout>
    )
  }
}

TicketRowHeader.contextTypes = {
  muiTheme: PropTypes.object,
}

TicketRowHeader.propTypes = {
  availability: PropTypes.array.isRequired,
  hasOnlySeatsAndBikes: PropTypes.bool,
  hasOnlyPassengers: PropTypes.bool,
  hasOnlySeatsAndCars: PropTypes.bool,
}

export default TicketRowHeader
