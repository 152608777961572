/* eslint react/jsx-closing-tag-location: off */
/* eslint react/no-multi-comp: off */

import IntlPolyfill from 'intl'
import moment from 'moment'
import { find, pathOr, last } from 'ramda'
import { injectIntl } from 'react-intl'

import { CalendarInput } from '../../../formComponents'

import { DATE_FORMAT } from '../../../consts/stuff'

require('intl/locale-data/jsonp/en')
require('intl/locale-data/jsonp/ru')
require('intl/locale-data/jsonp/et')
require('intl/locale-data/jsonp/el')

const departureFields = [
  {
    name: 'sailDate',
    label: 'Date',
    description: 'Departure date',
    required: true,
    id: 'calendar',
    formatDate: true,
    minDate: new Date(),
    value: new Date(),
    component: injectIntl(CalendarInput),
    labelContainerProps: { padding: '25px 0 15px 0' },
    padding: '10px 0 10px 14px',
    xs: { padding: '10px 0 10px 29px', width: '100%', labelContainerProps: { padding: '21px 0 5px 29px' } },
  },
]

const getDepartureFields = (props) => {
  const {
    sailPackage,
    sailDate,
    fetchAvailableDates,
    locale,
    onEditMode = false,
    availableSailsDatesForEdit = {},
  } = props
  const commonDateTimeFormat = new IntlPolyfill.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format

  return departureFields.map((field) => {
    const availableSailsDates = pathOr(
      {},
      [sailPackage.code],
      onEditMode ? availableSailsDatesForEdit : props.availableSailsDates
    )
    const availableSailsDatesArray = Object.keys(availableSailsDates).sort()

    const departureField = { ...field }
    if (departureField.formatDate) {
      departureField.locale = locale
      departureField.formatDate = commonDateTimeFormat
    }

    const firstAvailableDate = find(
      (date) => moment(date).locale(locale).isSameOrAfter(moment()),
      availableSailsDatesArray
    )
    if (sailPackage.code && firstAvailableDate) {
      departureField.initialVisibleMonth = () => moment(sailDate || firstAvailableDate).locale(locale)
      const startDate = moment(firstAvailableDate).locale(locale).format(DATE_FORMAT)
      const lastAvailableDate = last(availableSailsDatesArray)
      const endDate = moment(lastAvailableDate).locale(locale).add(1, 'M').endOf('month').format(DATE_FORMAT)
      departureField.getMoreDates = () =>
        fetchAvailableDates({
          legCode: sailPackage.code,
          startDate,
          endDate,
        })
    }

    departureField.availableDates = availableSailsDates

    return departureField
  })
}

export default getDepartureFields
