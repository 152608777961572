import { connect } from 'react-redux'
import { reduxForm, getFormValues } from 'redux-form'
import {
  compose,
  propEq,
  pathOr,
  isEmpty,
  reduce,
  findIndex,
  isNil,
  prop,
  head,
  complement,
  converge,
  or,
  F,
} from 'ramda'

import { selectors } from '../../provider'
import { fetchCountries } from '../../../../services/user/actions'

import { FORM_MODE_COMPANY, FORM_MODE_PRIVATE } from './consts'
import { getPhoneCode } from '../../../../services/utils'
import { getCurrentReservationOrNull } from '../../../../services/reservation/selectors'
import { isMaritime } from '../../../../utils/maritimeStyleUtils'
import { getIsMobile, getIsTablet } from '../../../../reducers/screenWidth'

const FORM_NAME = 'contact'
const EE_INDEX = 0

const DEFAULT_PHONE_CODE = getPhoneCode(window.brandProps.theme)

export const selectCustomerByOneOf = (state) =>
  reduce((result, selector) => {
    if (result) {
      return result
    }
    const customer = selector(state)
    return !isEmpty(customer) && customer
  }, false)

export const selectFormMode = compose(prop('formMode'), getFormValues(FORM_NAME))

const isCompanyCustomer = compose(complement(converge(or, [isNil, isEmpty])), prop('companyInfo'))

export const getFormModeBy = (customer) => (isCompanyCustomer(customer) ? FORM_MODE_COMPANY : FORM_MODE_PRIVATE)

const getPhoneNumber = ({ phones = [], phone = {} }) => {
  const { intlCode: phoneCode = DEFAULT_PHONE_CODE, phoneNumber = '' } = isEmpty(phones) ? phone : head(phones)
  return { phoneCode, phoneNumber }
}

const extractCustomerInfo = (customer, allowedCountries) => {
  const { email, firstName, lastName } = customer

  const info = {
    email,
    firstName,
    lastName,
    country: EE_INDEX,
    ...getPhoneNumber(customer),
  }

  if (isCompanyCustomer(customer)) {
    const { companyInfo = {} } = customer
    const { country = '', address: addressLine = '' } = companyInfo
    const countryIndex = findIndex(propEq('code', country), allowedCountries)

    return {
      ...info,
      ...companyInfo,
      country: countryIndex > -1 ? countryIndex : info.country,
      addressLine,
    }
  }

  return info
}

export const getEditReservationOwner = pathOr({}, ['reservation', 'reservation', 'edit', 'reservationOwner'])
export const getCurrentReservationOwner = pathOr({}, ['reservation', 'current', 'reservationOwner'])

const mapStateToProps = (state, { editMode = false }) => {
  const reservation = getCurrentReservationOrNull(state) || {}
  const guests = reservation && reservation.guests

  const allowedCountries = state.user.countries
  const isLogged = selectors.getToken(state)

  const customer = selectCustomerByOneOf(state)([
    getCurrentReservationOwner,
    editMode ? getEditReservationOwner : F,
    isLogged ? selectors.customerSelector : F,
  ])

  return {
    isLogged,
    customer,
    guests,
    allowedCountries,
    isTablet: getIsTablet(state),
    isMobile: getIsMobile(state),
    formMode: selectFormMode(state) || getFormModeBy(customer),
    userDetails: (isLogged && !isMaritime && customer) || undefined,
    initialValues: customer
      ? extractCustomerInfo(customer, allowedCountries)
      : {
          formMode: FORM_MODE_PRIVATE,
          phoneCode: DEFAULT_PHONE_CODE,
          country: EE_INDEX,
        },
  }
}

const mapDispatchToProps = { fetchCountries }

const formSettings = {
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
}

export default compose(connect(mapStateToProps, mapDispatchToProps), reduxForm(formSettings))
