import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText } from 'bora-material-ui'

import { translate } from '../../Common/Translator'
import messages from '../../../consts/messages'
import { AuthTypeContext } from './AuthSideMenu'
import { getMaritimeStyle, isMaritime } from '../../../utils/maritimeStyleUtils'

class LoginSectionTitle extends React.PureComponent {
  static propTypes = {
    title: PropTypes.any,
  }

  static defaultProps = {
    title: 'Title',
  }

  render() {
    const { muiTheme } = this.context

    return (
      <AuthTypeContext.Consumer>
        {(authType) => (
          <UILayout center j-flex-center margin="10px 0 40px 0">
            <UILayout display-if={!isMaritime} height="2px" bgColor="#4266bd" />
            <UILayout row center j-flex-center margin={getMaritimeStyle('0', '0 10px')} shrink="0" width="auto">
              <UIText
                align="center"
                size={getMaritimeStyle('22px', '24px')}
                weight="bold"
                margin="0px"
                lineHeight="1"
                color={getMaritimeStyle('#2D3955', '#4266bd')}
                font={getMaritimeStyle('ADPortsGroupBold, sans-serif', muiTheme.secondFontFamilyDem)}
                textTransform="uppercase"
                xs={{ size: getMaritimeStyle('16px', '24px') }}
                // translate={messages.or}
              >
                {translate(messages[`${authType}With${this.props.title}`])}
              </UIText>
            </UILayout>
            <UILayout display-if={!isMaritime} height="2px" bgColor="#4266bd" />
          </UILayout>
        )}
      </AuthTypeContext.Consumer>
    )
  }
}

LoginSectionTitle.contextTypes = {
  muiTheme: PropTypes.object,
}

export default LoginSectionTitle
