import { UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import TicketRowInfoItemContainer from './TicketRowInfoItemContainer'
import { isBluewave } from '../../../../../../../utils/maritimeStyleUtils'

class TicketRowInfo extends React.Component {
  render() {
    const { isHeader = false, useInitialValues = false, items } = this.props

    const renderHeaderLabels = (headerLabel, index) => {
      const isFirst = index === 0
      return (
        <TicketRowInfoItemContainer key={headerLabel} isFirst={isFirst} dataTestId={headerLabel}>
          <UIText
            text={headerLabel}
            color={isBluewave ? '#54BCF0' : '#9DA7BF'}
            size="14px"
            align={isFirst ? 'start' : 'center'}
            xs={{ size: '12px' }}
          />
        </TicketRowInfoItemContainer>
      )
    }

    const renderRowLabelsWithInitialValues = (item, index) => {
      const isFirst = index === 0
      const { label, AfterLabelComponent } = item
      return (
        <TicketRowInfoItemContainer key={label} isFirst={isFirst} dataTestId={label}>
          <UIText text={label} color="black" size="15px" align={isFirst ? 'start' : 'center'} xs={{ size: '10px' }} />
          {AfterLabelComponent && <AfterLabelComponent />}
        </TicketRowInfoItemContainer>
      )
    }

    const renderRowLabels = (item, index) => {
      const isFirst = index === 0
      return (
        <TicketRowInfoItemContainer key={index} isFirst={isFirst} dataTestId={isFirst ? `ticket-info:${item}` : null}>
          <UIText text={item} color="black" size="15px" align={isFirst ? 'start' : 'center'} xs={{ size: '10px' }} />
        </TicketRowInfoItemContainer>
      )
    }

    const renderItems = () => {
      if (isHeader) return items.map(renderHeaderLabels)
      if (useInitialValues) return items.map(renderRowLabelsWithInitialValues)
      return items.map(renderRowLabels)
    }

    return <UILayout>{renderItems()}</UILayout>
  }
}

export default TicketRowInfo
