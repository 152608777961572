import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pick, isEmpty, map, curry, reduce, assoc, keys, compose } from 'ramda'
import { UILayout, UIText, UIToolTip } from 'bora-material-ui'
import { translate } from '../../../../Common/Translator'
import Roles from './Roles'

import messages from '../../../../../consts/messages'
import { selectors, actions } from '../../../provider'
import { olderBrowser } from '../../../../../utils/browserUtils'
import { getStyle } from '../../../../../utils/liinilaevadThemeUtils'

const companyToRoleMap = {
  companyId: 'code',
  legalName: 'name',
}

const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
)

const styles = {
  toolTipAddStyles: {
    top: '30px',
    minWidth: '565px',
    maxHeight: '400px',
    overflowY: 'auto',
    bgColor: '#79909b',
    xs: {
      maxHeight: '300px',
    },
    ...(olderBrowser && { left: '-300px' }),
  },
  toolTipChildrenStyles: { 'j-flex-center': false, 'j-flex-end': true },
  xs: {
    usingAsWrapper: {
      bgColor: getStyle('#7ed321', '#0eb1bc'),
      width: '100%',
      center: true,
      padding: '16px',
      borderRadius: '4px',
      margin: '20px 0 0 0',
    },
    usingAsLabel: {
      color: 'white',
      size: '18px',
      textDecoration: 'none',
      weight: 'normal',
      align: 'center',
      width: '100%',
    },
    marginTop20: {
      margin: '20px 0 0 0',
    },
    tooltip: {
      arrowIndent: '50%',
      childrenStyles: { 'j-flex-center': true, 'j-flex-end': false },
      additionalStyles: {
        top: '90px',
        minWidth: 'none',
      },
    },
  },
}

class CustomerRole extends React.PureComponent {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  onSelectCustomerRole = (role) => {
    this.myRef.current.handleCloseModal()
    this.props.selectCustomerRole(role)
  }

  render() {
    const { muiTheme } = this.context
    const { customerRole = {}, user, companies, goToPage } = this.props

    const isLoggedUser = !isEmpty(user)
    const userHasCompanies = !isEmpty(companies)

    const { firstName, lastName, userId } = user

    const userRole = isLoggedUser && {
      code: userId,
      name: [firstName, lastName].join(' '),
      role: translate(messages.contactFormPrivate),
    }

    const renameCompanyAndSetRole = compose(
      assoc('role', translate(messages.contactRepresenter)),
      renameKeys(companyToRoleMap)
    )
    const companyRoles = map(renameCompanyAndSetRole)(companies)

    const rolesArray = [userRole, ...companyRoles]

    const selectedUserName = customerRole.code ? customerRole.name : [firstName, lastName].join(' ')

    const customerRoleLabel = isLoggedUser
      ? [translate(messages.customerRoleUsingAs.id), selectedUserName].join(' ')
      : translate(messages.customerSignIn.id)

    if (!isLoggedUser) {
      return (
        <UILayout
          width="auto"
          cursor="pointer"
          onClick={() => goToPage('/auth/login')}
          xs={styles.xs.usingAsWrapper}
          data-testid="select-tickets-login"
          style={{ ...(muiTheme.ids.selectTicketsLogin && { ...muiTheme.ids.selectTicketsLogin.wrapper }) }}
        >
          <UIText
            size="14px"
            weight="bold"
            width="max-content"
            textDecoration={
              muiTheme.ids.selectTicketsLogin && muiTheme.ids.selectTicketsLogin.text
                ? muiTheme.ids.selectTicketsLogin.text.textDecoration
                : 'underline'
            }
            letterSpacing="0.5px"
            color={
              muiTheme.ids.selectTicketsLogin && muiTheme.ids.selectTicketsLogin.text
                ? muiTheme.ids.selectTicketsLogin.text.color
                : '#94a8b2'
            }
            align="right"
            font={muiTheme.secondFontFamily}
            text={customerRoleLabel}
            xs={styles.xs.usingAsLabel}
            style={{ ...(muiTheme.ids.selectTicketsLogin && { ...muiTheme.ids.selectTicketsLogin.text }) }}
          />
        </UILayout>
      )
    } else if (isLoggedUser && !userHasCompanies) {
      return (
        <UILayout width="auto" xs={styles.xs.usingAsWrapper} display-if={Boolean(selectedUserName.trim())}>
          <UIText
            size="14px"
            weight="bold"
            width="max-content"
            letterSpacing="0.5px"
            color="#94a8b2"
            align="right"
            font={muiTheme.secondFontFamily}
            text={customerRoleLabel}
            xs={styles.xs.usingAsLabel}
          />
        </UILayout>
      )
    }

    const RolesComponent = (
      <Roles
        roles={rolesArray}
        customerRole={isEmpty(customerRole) ? userRole : customerRole}
        onSelect={this.onSelectCustomerRole}
      />
    )

    return (
      <UIToolTip
        id="rolesToolTip"
        arrowIndent="85%"
        arrowPosition="top"
        ref={this.myRef}
        additionalStyles={styles.toolTipAddStyles}
        childrenStyles={styles.toolTipChildrenStyles}
        sm={styles.xs.tooltip}
        toolTipContent={RolesComponent}
      >
        <UILayout width="auto" cursor="pointer" xs={styles.xs.marginTop20} data-testid="select-tickets-using-as">
          <UIText
            size="14px"
            weight="bold"
            width="max-content"
            textDecoration="underline"
            letterSpacing="0.5px"
            color="#94a8b2"
            align="right"
            text={customerRoleLabel}
          />
        </UILayout>
      </UIToolTip>
    )
  }
}

CustomerRole.contextTypes = {
  muiTheme: PropTypes.any,
}

CustomerRole.propTypes = {
  customerRole: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  selectCustomerRole: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  customerRole: selectors.getCustomerRole(state),
  user: selectors.userDetailsSelector(state),
  companies: selectors.getCompaniesSelector(state),
})

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(pick(['selectCustomerRole', 'goToPage'], actions), dispatch)
)(CustomerRole)
