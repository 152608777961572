import React, { Fragment } from 'react'
import styled from 'styled-components'
import { allPropsFilled } from './PassengersHeap.util'
import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import { connect } from 'react-redux'
import { getSelectedPassengerSeqNum, getSelectedSailPackages } from '../../../../services/user-selections'
import { isMaritime } from '../../../../utils/maritimeStyleUtils'
import { getSelectedTicketsValues } from '../../../../services/pricing/reducers/selectors'
import { NextPassengerDataButton } from './NextPassengerDataButton'
import messages from '../../../../consts/messages'
import { translate } from '../../../Common/Translator'
import moduleStyles from './PassengersHeap.module.scss'

const defaultHeapWrapStyles = `
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: space-between;
`

const maritimeHeapWrapStyles = `
  display: flex;
  flex-direction: column;
`

export const HeapWrap = styled.div`
  ${isMaritime ? maritimeHeapWrapStyles : defaultHeapWrapStyles}
`

const defaultPassengerWrapStyles = `
  background: white;
  padding: 3px 10px;
  border-radius: 10px;
  border: 1px solid white;
  margin: 3px 5px;
  color: #8aa1ac;
  display: flex;
  height: 30px;
  align-items: center;
  cursor: pointer;
`

const maritimePassengerWrapStyles = `
  background: white;
  padding: 10px 0;
  width: 100%;
  display: flex;
  height: 30px;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid #C1CADE;
`

export const PassengerWrap = styled.div`
  ${isMaritime ? maritimePassengerWrapStyles : defaultPassengerWrapStyles}
  ${({ id }) => id === 0 && 'border-top: none;'}

  ${({ selected }) =>
    selected &&
    !isMaritime &&
    `
      border: 1px solid #8aa1ac;
      cursor: auto;
  `}
`

class _PassengersHeap extends React.Component {
  state = {
    initialNumOfTickets: 0,
  }

  componentWillUnmount() {
    const { changeItemQtty, selectedTicket, filteredLabels, modifiedSeqNums, isTaxi } = this.props
    const shouldRemoveNewTickets =
      !filteredLabels.every(({ type, seqN, ...guest }) => {
        return allPropsFilled(guest) && !modifiedSeqNums[seqN]
      }) && !isTaxi
    if (shouldRemoveNewTickets) {
      changeItemQtty({ ...selectedTicket, count: this.state.initialNumOfTickets, isAddon: selectedTicket.isAddon })
    }
  }

  componentDidMount() {
    this.setState({
      initialNumOfTickets: this.props.filteredLabels.length,
    })
  }

  render() {
    const {
      labels = [],
      formComponent,
      buttonAction,
      buttonEnabled,
      selectGuest,
      filteredLabels,
      selectedPassengerSeqNum,
      modifiedSeqNums = {},
    } = this.props

    if (isMaritime) {
      return (
        <Fragment>
          <HeapWrap data-testid="passenger-details-heap">
            {filteredLabels.map(({ type, seqN, ...guest }, index) => {
              const allFilled = allPropsFilled(guest) && !modifiedSeqNums[seqN]
              const selectedPerson = seqN === selectedPassengerSeqNum
              const redText = translate(messages.pleaseFillInPassengerData)
              const formProps = {
                saveDataCheckboxEnabled: buttonEnabled && !allFilled,
                seqN,
              }

              return (
                <React.Fragment>
                  <PassengerWrap
                    onClick={() => {
                      if (selectedPerson) {
                        selectGuest(0)
                        return
                      }
                      selectGuest(seqN)
                    }}
                    selected={selectedPerson}
                    id={`passenger-wrap-${index}`}
                    index={seqN}
                    key={seqN}
                    style={{ minHeight: redText.length > 40 ? 75 : 50 }}
                    className={moduleStyles.wrapper}
                  >
                    <UIIcon width="25px" align="right" display-if={allFilled} type="iconCircleOk_admaritime" />
                    <UIText
                      width="100%"
                      display-if={allFilled}
                      align="start"
                      font="ADPortsGroupBold, sans-serif"
                      margin="5px 0 0 5px"
                      size="18px"
                      color="#2D3955"
                      text={translate(messages.passengersFullNameLabel, {
                        firstName: guest.firstName,
                        lastName: guest.lastName,
                      })}
                    />
                    <UIText
                      width="100%"
                      display-if={!allFilled}
                      align="start"
                      font="ADPortsGroupBold, sans-serif"
                      margin="0 0 -5px 0"
                      size="18px"
                      color="#D11800"
                      text={redText}
                    />
                    <UIIcon type="iconChevronDown" flipV={selectedPerson} />
                  </PassengerWrap>
                  {selectedPerson && <UILayout>{formComponent(formProps)}</UILayout>}
                  {selectedPerson && (
                    <UILayout padding="0 0 20px 0">
                      <NextPassengerDataButton action={buttonAction} enabled={buttonEnabled && !allFilled} />
                    </UILayout>
                  )}
                </React.Fragment>
              )
            })}
          </HeapWrap>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <HeapWrap>
          {labels.map(({ type, seqN, ...guest }) => {
            const allFilled = allPropsFilled(guest) && !modifiedSeqNums[seqN]
            const selectedPerson = seqN === selectedPassengerSeqNum
            return (
              <PassengerWrap onClick={() => selectGuest(seqN)} selected={selectedPerson} index={seqN} key={seqN}>
                <UIText color="#8aa1ac">{type}</UIText>
                <UIIcon
                  width="20px"
                  margin="0 0 0 4px"
                  align="right"
                  display-if={!allFilled}
                  type="iconErrorFilled"
                  fill="#d0021a"
                />
                <UIIcon width="25px" align="right" display-if={allFilled} type="iconPiontOk_kihnu" fill="white" />
              </PassengerWrap>
            )
          })}
        </HeapWrap>
      </Fragment>
    )
  }
}

const mapStateToProps = (state, { ticketsType, labels }) => {
  const selectedPassengerSeqNum = getSelectedPassengerSeqNum(state)
  const ticketsFormValues = getSelectedTicketsValues(state)
  const passengerTickets = Object.keys(ticketsFormValues)
    .filter((key) => ticketsFormValues[key].inventoryClass === 'DECK')
    .map((key) => ticketsFormValues[key])
    .filter((ticket) => ticket.code === ticketsType && ticket.code !== 'CHARTER')
  const passengerTicketsTitles = passengerTickets.map((ticket) => Object.values(ticket.titles))
  const filteredLabels = labels.filter((label) =>
    passengerTicketsTitles.some((coupleOfTitles) => coupleOfTitles.includes(label.type))
  )
  const selectedSailPackages = getSelectedSailPackages(state)
  const [sailPackage = {}] = selectedSailPackages
  const { taxi } = sailPackage
  const isTaxi = Boolean(taxi)
  return {
    filteredLabels,
    selectedPassengerSeqNum,
    isTaxi,
  }
}

export const PassengersHeap = connect(mapStateToProps)(_PassengersHeap)
