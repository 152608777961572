/* eslint-disable no-implicit-coercion */
import {
  reduce,
  compose,
  split,
  trim,
  head,
  defaultTo,
  prop,
  propEq,
  find,
  pick,
  add,
  findIndex,
  equals,
  map,
  pathOr,
  length,
  allPass,
  not,
  ifElse,
  has,
  F,
  propOr,
  __,
  is,
  keys,
  fromPairs,
  always,
} from 'ramda'
import { filterFeeItems } from '../../../../services/reservation/utils'
import { isMaritime } from '../../../../utils/maritimeStyleUtils'

import { notEmpty } from '../../../../utils/ramda-extend'
import { correctSymbolsRegExp } from '../../../../utils/validate'
import { getDateISO } from '../../../MyTicket/components/Modals/EditPassenger/ModalEditPassenger'

const defaultData = {
  firstName: '',
  lastName: '',
  birthday: '',
  gender: 'M',
  citizenship: -1,
  optionalInfo: '',
}

const makeNumsMap = (type = 'TYPE') => reduce((acc, num) => ({ ...acc, [num]: type }), {})
const makeSeqNumToTypeMap = reduce(
  (acc, item) => ({
    ...acc,
    ...{ ...makeNumsMap(item.priceCategoryTranslation || item.type)(item.ownerSeqNs) },
  }),
  {}
)

const stripBrackets = compose(trim, head, split('('))

export const makeIndividualLabels = (items = [], guests = []) => {
  const itemsWithoutFee = filterFeeItems(items)
  const itemsMap = makeSeqNumToTypeMap(itemsWithoutFee)
  return guests.map((guest) => {
    const { personalIdentificationNumber } = guest
    let type = itemsMap[guest.seqN]

    if (personalIdentificationNumber) {
      type = `${stripBrackets(type)} ${personalIdentificationNumber}`
    }

    return { type, ...defaultData, ...guest }
  })
}

export const getNextGuestSeqNum = (currentSeqNum = 0, guests = []) => {
  const getNextIndex = compose(add(1), findIndex(equals(currentSeqNum)), map(prop('seqN')))
  const getLastIdx = compose(add(-1), length)
  let nextIdx = getNextIndex(guests)
  if (nextIdx > getLastIdx(guests)) nextIdx = 0

  return pathOr(0, [nextIdx, 'seqN'])(guests)
}

const isCitizenshipOk = isMaritime ? always(true) : compose(compose(not, equals(-1)), propOr(-1, 'citizenship'))

const hasAllFields = isMaritime
  ? allPass([has('birthday'), has('firstName'), has('gender'), has('lastName')])
  : allPass([has('birthday'), has('citizenship'), has('firstName'), has('gender'), has('lastName')])

const includeOnlyCorrectSymbols = (string = '') => {
  return correctSymbolsRegExp.test(string)
}

const checkString = allPass([notEmpty, includeOnlyCorrectSymbols])

const passengerDataValidate = ({ birthday = {}, citizenship = -1, firstName = '', lastName = '', gender = 'M' }) => {
  const birthdayHas = has(__, birthday)
  const bDayOk = is(String)(birthday) ? true : !!(birthdayHas('date') && birthdayHas('year') && birthdayHas('month'))
  const stringsOk = !!(checkString(firstName) && checkString(lastName) && notEmpty(gender))

  // if (!bDayOk) console.warn('Birthday not OK', { birthday })
  // if (!stringsOk) console.warn('String values are not OK', { firstName, lastName, gender })
  // if (!isCitizenshipOk({ citizenship })) console.warn('citizenship not OK', { citizenship })

  return bDayOk && stringsOk && isCitizenshipOk({ citizenship })
}

const checkCommonPassenger = allPass([
  isCitizenshipOk,
  compose(passengerDataValidate, pick(['ageInYears', 'birthday', 'citizenship', 'firstName', 'gender', 'lastName'])),
])

const isValidPassenger = ifElse(hasAllFields, checkCommonPassenger, F)

export const allPropsFilled = isMaritime
  ? isValidPassenger
  : ifElse(has('personalIdentificationNumber'), isCitizenshipOk, isValidPassenger)
export const getGuestBySeqNum = (seqNum) => find(propEq('seqN', seqNum))

const makeJsonFromObj = ({ firstName, lastName, birthday, gender, citizenship, comments = '' }) =>
  JSON.stringify({ firstName, lastName, birthday, gender, citizenship, comments })

export const getGuestSeqNumsWithModifiedData = (
  tempPassengers = {},
  guests = [],
  formPassenger,
  citizenshipList = []
) => {
  const transform = (seqNum) => {
    let guest = getGuestBySeqNum(parseFloat(seqNum))(guests)
    const { citizenship: guestCitizenship } = guest || {}
    if (!guestCitizenship && guest) {
      guest = { ...guest, citizenship: '' }
    }
    if (!guest) return [seqNum, false]
    const tempPassenger =
      formPassenger && formPassenger.seqN === parseFloat(seqNum) ? formPassenger : tempPassengers[seqNum]
    const { birthday, citizenship, optionalInfo = '' } = tempPassenger
    const birthdayStr = getDateISO(birthday)
    const citizenshipStr = pathOr('', [citizenship, 'code'])(citizenshipList)

    try {
      const guestJson = makeJsonFromObj(guest)
      const passengerJson = makeJsonFromObj({
        ...tempPassenger,
        citizenship: citizenshipStr,
        birthday: birthdayStr,
        comments: optionalInfo,
      })
      return [seqNum, guestJson !== passengerJson]
    } catch (e) {
      console.warn('Cannot compare guest with temp passenger', e)
      return [seqNum, false]
    }
  }

  const getModifiedSeqNums = compose(fromPairs, map(transform), keys)
  return getModifiedSeqNums(tempPassengers)
}

export const getFirstSeqNum = compose(defaultTo(0), prop('seqN'), head)
