import React from 'react'
import PropTypes from 'prop-types'

import { UIText, UIButton, UILayout } from 'bora-material-ui'

import UIElements from '../../../../consts/UIElements'

const SignInButton = ({ onClick, disabled, translate, margin, height, size }, { muiTheme }) => (
  <UIButton
    width="100%"
    height={height}
    click={onClick}
    disabled={disabled}
    border-radius="4px"
    background={UIElements.BUTTONS_COLOR}
    margin={margin}
    {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.nextButton })}
    borderRadius={
      muiTheme.ids.buttonActionStyles &&
      muiTheme.ids.buttonActionStyles.nextButton &&
      muiTheme.ids.buttonActionStyles.nextButton.borderRadius
        ? muiTheme.ids.buttonActionStyles.nextButton.borderRadius
        : 'inherit'
    }
    data-testid="email-login-button"
  >
    <UILayout
      data-testid="email-login"
      column
      j-flex-center
      margin="0 0px"
      lineHeight="30px"
      height="100%"
      style={{ flexBasis: '0' }}
    >
      <UIText align="left" size="24px">
        <UILayout row center j-flex-center>
          <UIText
            align="center"
            size={size}
            weight="bold"
            letterSpacing="0.8px"
            xs={{ size: '20px' }}
            lineHeight="1"
            translate={translate}
            textTransform="uppercase"
            font={
              muiTheme.ids.buttonActionStyles && muiTheme.ids.buttonActionStyles.titles
                ? muiTheme.ids.buttonActionStyles.titles.fontFamily
                : muiTheme.secondFontFamilyDem
            }
            {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.titles })}
          />
        </UILayout>
      </UIText>
    </UILayout>
  </UIButton>
)

SignInButton.contextTypes = {
  muiTheme: PropTypes.object,
}

SignInButton.defaultProps = {
  margin: '45px 0px 0px 0px',
  height: '56px',
  size: '16px',
}

SignInButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  translate: PropTypes.object.isRequired,
  margin: PropTypes.string,
  height: PropTypes.string,
  size: PropTypes.string,
}

export default SignInButton
