import { UILayout } from 'bora-material-ui'
import moduleStyles from './DriverPassengerDetails.module.scss'
import { DropDownField, InputField, OptionsRadioGroup } from '../../../../../formComponents'
import { translate } from '../../../../Common/Translator'
import messages from '../../../../../consts/messages'
import { includeOnlyCorrectSymbols } from '../../../../../utils/validate'
import React, { Fragment, useState } from 'react'
import { change, Field, Form, isValid, reduxForm } from 'redux-form'
import {
  DRIVER_COUNTRY_OF_ISSUE,
  DRIVER_DATE_OF_BIRTH,
  DRIVER_FIRST_NAME,
  DRIVER_GENDER,
  DRIVER_LAST_NAME,
  DRIVER_PASSPORT_NUMBER,
  DRIVER_PLACE_OF_BIRTH,
  FORM_ID,
  validate,
} from './DriverFromConstants'
import { compose } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { submitDriverDetails } from '../../../../../actions'
import { CountryItemTemplate, GENDER_BUTTONS } from '../PassengerEditForm'
import { getCitizenshipList } from '../../../../../services/user/selectors'
import { isMaritime } from '../../../../../utils/maritimeStyleUtils'

const style = getComputedStyle(document.body)

const fontSizeStrong = style.getPropertyValue('--fs-base-strong')
const borderColor = style.getPropertyValue('--input-border-color')
// const borderRadius = style.getPropertyValue('--input-border-radius')

const formStyles = {
  root: { boxShadow: '0', border: `1px solid ${borderColor}` },
  wrap: { padding: '0', column: false },
  error: { marginBlockStart: '0.5rem' },
  label: {
    fontSize: fontSizeStrong,
  },
  labelWrap: { width: '50%', 'flex-end': true, display: 'flex' },
  inputWrap: { padding: '0', width: '50%' },
}

const SillyDateInput = ({ input, type }) => {
  const { name, onChange, value, onFocus, onBlur } = input

  return (
    <input
      required
      className={moduleStyles.dateInput}
      type={type}
      id="dob-driver"
      min="1945-01-01"
      max="2005-12-31"
      {...{ onChange, onFocus, onBlur, value, name }}
    />
  )
}

export const DriverForm = ({ changeField, nationalities }) => {
  const [gender, setGender] = useState('M')
  const changeGender = (value) => {
    setGender(value)
    changeField(FORM_ID, DRIVER_GENDER, value)
  }
  const changeNationality = (value) => changeField(FORM_ID, DRIVER_COUNTRY_OF_ISSUE, value)
  // const nationalitiesList = [...take(10)(nationalities.items), { code: NOT_A_COUNTRY_CODE, title: 'Load all' }]
  const nationalitiesList = nationalities.items || []

  const hideBwFields = isMaritime

  return (
    <UILayout column>
      <Form>
        <UILayout className={moduleStyles.fieldWrapper} data-testid="driver-firstname">
          <InputField
            type="text"
            required
            hideStar
            name={DRIVER_FIRST_NAME}
            text={translate(messages.firstName)}
            validate={[includeOnlyCorrectSymbols]}
            wrapperStyles={formStyles.wrap}
            style={formStyles.root}
            wrapperLabelStyles={formStyles.labelWrap}
            wrapperInputStyles={formStyles.inputWrap}
            errorStyle={formStyles.error}
            labelStyles={formStyles.label}
            className={moduleStyles.input}
            data-testid="driver-firstname-input"
          />
        </UILayout>

        <UILayout className={moduleStyles.fieldWrapper} data-testid="driver-lastname">
          <InputField
            type="text"
            required
            hideStar
            name={DRIVER_LAST_NAME}
            text={translate(messages.lastName)}
            validate={[includeOnlyCorrectSymbols]}
            wrapperStyles={formStyles.wrap}
            style={formStyles.root}
            wrapperLabelStyles={formStyles.labelWrap}
            wrapperInputStyles={formStyles.inputWrap}
            errorStyle={formStyles.error}
            labelStyles={formStyles.label}
            className={moduleStyles.input}
            data-testid="driver-lastname-input"
          />
        </UILayout>
        {!hideBwFields && (
          <Fragment>
            <UILayout className={moduleStyles.fieldWrapper}>
              <UILayout className={moduleStyles.inputLabelWrapper}>
                <div className={moduleStyles.inputLabel}>{translate(messages.sex)}</div>
              </UILayout>
              <OptionsRadioGroup
                prohibitTextTransform
                hideLabel
                row
                name={DRIVER_GENDER}
                options={GENDER_BUTTONS}
                activeValue={gender}
                onChange={changeGender}
              />
            </UILayout>
            <UILayout className={moduleStyles.fieldWrapper}>
              <UILayout className={moduleStyles.inputLabelWrapper}>
                <div className={moduleStyles.inputLabel}>{translate(messages.dateOfBirth)}</div>
              </UILayout>
              <Field
                required
                name={DRIVER_DATE_OF_BIRTH}
                type="date"
                label={translate(messages.dateOfBirth)}
                component={SillyDateInput}
              />
            </UILayout>

            <UILayout className={moduleStyles.fieldWrapper}>
              <InputField
                required
                hideStar
                name={DRIVER_PLACE_OF_BIRTH}
                text={translate(messages.placeOfBirth)}
                validate={[includeOnlyCorrectSymbols]}
                wrapperStyles={formStyles.wrap}
                style={formStyles.root}
                wrapperLabelStyles={formStyles.labelWrap}
                wrapperInputStyles={formStyles.inputWrap}
                errorStyle={formStyles.error}
                labelStyles={formStyles.label}
                className={moduleStyles.input}
              />
            </UILayout>

            <UILayout className={moduleStyles.fieldWrapper}>
              <UILayout className={moduleStyles.inputLabelWrapper}>
                <div className={moduleStyles.inputLabel}>{translate(messages.nationality)}</div>
              </UILayout>
              <DropDownField
                name={`temp-${DRIVER_COUNTRY_OF_ISSUE}`}
                required
                hideStar
                onChange={(e, value) => {
                  changeNationality(nationalitiesList[value].code)
                }}
                styles={{ minWidth: '120px', padding: 0, width: '100%', ...formStyles.root }}
                labelContainerProps={{ display: 'none' }}
                inputContainerProps={{ padding: 0 }}
                wrapperStyles={formStyles.wrap}
                wrapperLabelStyles={formStyles.labelWrap}
                wrapperInputStyles={formStyles.inputWrap}
                errorStyle={formStyles.error}
                labelStyles={formStyles.label}
                itemsArr={nationalitiesList}
                selectionTemplate={CountryItemTemplate}
              />
            </UILayout>
            <UILayout className={moduleStyles.fieldWrapper}>
              <InputField
                required
                hideStar
                name={DRIVER_PASSPORT_NUMBER}
                text={translate(messages.passportNr)}
                wrapperStyles={formStyles.wrap}
                style={formStyles.root}
                wrapperLabelStyles={formStyles.labelWrap}
                wrapperInputStyles={formStyles.inputWrap}
                errorStyle={formStyles.error}
                labelStyles={formStyles.label}
                className={moduleStyles.input}
              />
            </UILayout>
          </Fragment>
        )}
      </Form>

      {/* <UILayout> */}
      {/*   <NextPassengerDataButton */}
      {/*     action={() => { */}
      {/*       saveDriverDetails(FORM_ID) */}
      {/*     }} */}
      {/*     enabled={isDriverFormValid} */}
      {/*   /> */}
      {/* </UILayout> */}
    </UILayout>
  )
}

const mapStateToProps = (state) => {
  return {
    nationalities: getCitizenshipList(state),
    isDriverFormValid: isValid(FORM_ID)(state),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDriverDetails: submitDriverDetails,
      changeField: change,
    },
    dispatch
  )

const enhance = compose(
  reduxForm({
    form: FORM_ID,
    destroyOnUnmount: true,
    initialValues: {
      [DRIVER_GENDER]: 'M',
    },
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(DriverForm)
